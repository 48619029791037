import { auth } from '../..';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

export default function SaveFiltersPopUp({filters,close,saveToast}) {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)

        
    useEffect(() => {
        if(user === null)
        {
            let user = JSON.parse(localStorage.getItem("user"))
            setUser(user)
        }
        
    },[]) // eslint-disable-line react-hooks/exhaustive-deps
    

    const [name, setName] = useState("")
    const [error, setError] = useState("Please enter name")

    
    const baseURL2 = process.env.REACT_APP_BASEURL2;

    
    const saveFilters = () => {

        return new Promise(async (resolve, reject) => {

            let id = Math.random().toString(16).slice(2)

            setLoading(true)

            axios.post(baseURL2+'addSavedFiltersToAccount',{
                id:id,
                uid:auth.currentUser.uid,
                filters:filters,
                name:name

            }).then( (response) => {
            
                const data = response.data;
                console.log("SDATA",data)
                addLocally(id)
                setLoading(false)
                saveToast("Filter Saved")
                close()
            });
        })
    }


    const addLocally = (id) => {
            let user = JSON.parse(localStorage.getItem("user"))
            user.savedFilters.push({name:name,filters:filters,id:id})
            localStorage.setItem("user",JSON.stringify(user))
    }

    const checkDisabled = () => {
        if(name === "")
        {
            if(error!=="Please enter name"){setError("Please enter name")}
            return true
        }
        else{

            var duplicate = false
            user.savedFilters.forEach(element => {
                if(element.name === name){duplicate = true}
            });

            if(duplicate)
            {
                if(error!=="Name already exists"){setError("Name already exists")}
                // setError("Name already exists")
                return true
            }
            else{
                // setError("")
                if(error!==""){setError("")}
                return false
            }
        }


    }


    return(

        <div className='tint'>
            <div className='popUp saveFilter'>
                <div className='sfpuTopRow'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                        <path d="M16.3337 2.70703V7.526C16.3337 8.17939 16.3337 8.50609 16.4608 8.75565C16.5727 8.97517 16.7511 9.15365 16.9707 9.2655C17.2202 9.39266 17.5469 9.39266 18.2003 9.39266H23.0193M18.667 21.6426L16.917 19.8926M16.3337 2.39258H10.267C8.30681 2.39258 7.32672 2.39258 6.57803 2.77406C5.91946 3.10961 5.38403 3.64504 5.04847 4.30361C4.66699 5.0523 4.66699 6.03239 4.66699 7.99258V20.1259C4.66699 22.0861 4.66699 23.0662 5.04847 23.8149C5.38403 24.4734 5.91946 25.0089 6.57803 25.3444C7.32672 25.7259 8.30681 25.7259 10.267 25.7259H17.7337C19.6938 25.7259 20.6739 25.7259 21.4226 25.3444C22.0812 25.0089 22.6166 24.4734 22.9522 23.8149C23.3337 23.0662 23.3337 22.0861 23.3337 20.1259V9.39258L16.3337 2.39258ZM18.0837 16.9759C18.0837 19.2311 16.2555 21.0592 14.0003 21.0592C11.7452 21.0592 9.91699 19.2311 9.91699 16.9759C9.91699 14.7207 11.7452 12.8926 14.0003 12.8926C16.2555 12.8926 18.0837 14.7207 18.0837 16.9759Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='sfpuMain'>
                    <div className='sfpuMain inner'>
                        <span className='text-lg semibold gray-900'>Save new search filter</span>
                        <span className='text-sm regular gray-600'>Track companies or perform new searches based on your saved filter criterias.</span>
                    </div>

                    <div className='sfpuMain inner'>
                        <span className='text-sm medium gray-700'>Custom search filter name</span>
                        <input className={`textInput text-md gray-900`} type="text" placeholder={"Enter custom search filter name"} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>

                
                {!loading && <div className='sfpuButtons'>
                    <button className='secondaryButton' onClick={close}>Cancel</button>
                    <div className="tooltip">
                    <button className='primaryButton' onClick={saveFilters} disabled={checkDisabled()}>Save Filter</button>
                        {error!=="" && <span className="tooltiptext button text-sm regular">{error}</span>}
                    </div>
                    
                </div>}


                {loading && <div className='loader2'>
                    <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    </div>
                }


            </div>            
        </div>

    )

}