import { signOut } from 'firebase/auth';
import { auth } from '../..';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from "react-router-dom";

export default function HeaderPopUp({user,close}) {

   
    
    
    const [initials,setInitials] = useState("")
    const [needsVerifying,setNeedsVerifying] = useState(false)


    
    const navigate = useNavigate();
    const location = useLocation();


    


    useEffect(() => {

        setNeedsVerifying(location.pathname === "/verify-email")
        
        console.log("PATHNAME",location.pathname)
        
    },[location.pathname])


    useEffect(() => {

        if(user !== null)
        {
            let name = user.name
    
            let initialsArray = []
    
            let split = name.split(' ')
            initialsArray.push(split[0][0])
            if(split.length>1){initialsArray.push(split[1][0])}
            
            setInitials(initialsArray.join(''))
        }
        else{
            setInitials("")
        }
    
    },[user])
  


    // const accountClicked = () => {
    //     close()
    // }

    const billingClicked = () => {
        
        close()
        navigate(`../billing`, { replace: true});
    }
    
    const resetPasswordClicked = () => {
        close()
        navigate(`../passwordReset`, { replace: true});
    }

    const logout = () => {
        
        close()

        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            navigate(`../login`, { replace: true});
            
            }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
            });
    }
    


    

    return(

        <div className='popUp header'>
            <div className='width100 row padding20 gap12 borderBox'>
                <div className='headerName'>{initials}</div>
                <div className='column ai-start'>
                    <span className='text-sm semibold gray-700'>{user.name}</span>
                    <span className='text-sm regular gray-600 breakword'>{user.email}</span>
                </div>
            </div>
            <div className='greyLine'/>
            <div className='column ai-start padding10 width100'>
                {/* <div className='row jc-start padding10 gap12 width100 hover pointer'  onClick={accountClicked}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M13.3327 14C13.3327 13.0696 13.3327 12.6044 13.2179 12.2259C12.9593 11.3736 12.2924 10.7067 11.4401 10.4482C11.0616 10.3333 10.5964 10.3333 9.66601 10.3333H6.33268C5.40231 10.3333 4.93712 10.3333 4.55859 10.4482C3.70632 10.7067 3.03938 11.3736 2.78084 12.2259C2.66602 12.6044 2.66602 13.0696 2.66602 14M10.9993 5C10.9993 6.65685 9.6562 8 7.99935 8C6.34249 8 4.99935 6.65685 4.99935 5C4.99935 3.34315 6.34249 2 7.99935 2C9.6562 2 10.9993 3.34315 10.9993 5Z" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-sm medium gray-700'>Account & Settings</span>
                </div> */}

                <div className={`row jc-start padding10 gap12 width100  ${needsVerifying?"":"hover pointer"}`}  onClick={needsVerifying?console.log("Need to verify email to click this button"):resetPasswordClicked}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.6347 8.59512C13.4495 10.3351 12.4636 11.9655 10.8323 12.9074C8.12196 14.4722 4.65628 13.5436 3.09147 10.8332L2.9248 10.5446M2.36344 7.40443C2.54864 5.6645 3.5345 4.03401 5.16582 3.09217C7.87615 1.52736 11.3418 2.45599 12.9066 5.16631L13.0733 5.45499M2.32812 12.0438L2.81616 10.2224L4 10.5M12 5.45499L13.1823 5.77713L13.6703 3.95576" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 7.5C6 6.39543 6.89543 5.5 8 5.5V5.5C9.10457 5.5 10 6.39543 10 7.5V8H6V7.5Z" stroke="#344054" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 8H10V8.4C10 8.96005 10 9.24008 9.89101 9.45399C9.79513 9.64215 9.64215 9.79513 9.45399 9.89101C9.24008 10 8.96005 10 8.4 10H7.6C7.03995 10 6.75992 10 6.54601 9.89101C6.35785 9.79513 6.20487 9.64215 6.10899 9.45399C6 9.24008 6 8.96005 6 8.4V8Z" fill="#344054" stroke="#344054" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className={`text-sm medium ${needsVerifying?"gray-400":"gray-700"}`}>Reset Password</span>
                </div>

                <div className={`row jc-start padding10 gap12 width100 ${needsVerifying?"":"hover pointer"}`}  onClick={needsVerifying?console.log("Need to verify email to click this button"):billingClicked}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M11 9.33333H11.0067M2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V6C14 5.26362 13.403 4.66667 12.6667 4.66667L3.33333 4.66667C2.59695 4.66667 2 4.06971 2 3.33333ZM2 3.33333C2 2.59695 2.59695 2 3.33333 2H11.3333M11.3333 9.33333C11.3333 9.51743 11.1841 9.66667 11 9.66667C10.8159 9.66667 10.6667 9.51743 10.6667 9.33333C10.6667 9.14924 10.8159 9 11 9C11.1841 9 11.3333 9.14924 11.3333 9.33333Z" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className={`text-sm medium ${needsVerifying?"gray-400":"gray-700"}`}>Billing</span>
                </div>
                
                <div className='row jc-start padding10 gap12 width100 hover pointer' onClick={logout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6M6 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.7157 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.07989 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H6" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='text-sm medium gray-700'>Log out</span>
                </div>
            </div>
            
        </div>

    )

}