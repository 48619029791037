
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';


export default function PricingAuthCardInput({backClicked}) {


    const [step, setStep] = useState(1)

        
    useEffect(() => {
        setStep(step)
    },[step])
        
    

    return(

        <div className='column ai-start'>
            <div className='pricingTile padding50'>
             <button className='secondaryButton' onClick={backClicked}>Go back</button>
             </div>
        </div>

    )

}