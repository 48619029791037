import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { ParentContext } from './components/constants/ParentContext';

const firebaseConfig = {
  apiKey: "AIzaSyDxBhq7tyB5fpdl-0EDWZyYkwFfuI3pICg",
  authDomain: "silent-matter-386414.firebaseapp.com",
  projectId: "silent-matter-386414",
  storageBucket: "silent-matter-386414.appspot.com",
  messagingSenderId: "819727864356",
  appId: "1:819727864356:web:237a8e514e5e086e4a3eb1"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
let params = new URL(document.location).searchParams;
export {auth}
export {analytics}

var parentURL = (window.location !== window.parent.location)? document.referrer: document.location.href;

// var isHarbour = parentURL.includes("https://vcml-7b6cd--bizcrunch-jv3mal71.web.app") || parentURL.includes("https://harbourclub.vip")
let isHarbour = params.get("via") === "harbour" ? true : false;
if (localStorage.getItem("isHarbourClub")) {
  isHarbour = localStorage.getItem("isHarbourClub") === "true" ? true : false;
}

// if(window.location.ancestorOrigins.length>0 && !isHarbour)
// {
//   if(window.location.ancestorOrigins[0].includes("https://vcml-7b6cd--bizcrunch-jv3mal71.web.app") || window.location.ancestorOrigins[0].includes("https://harbourclub.vip"))
//   {
//     isHarbour = true
//   }
// }



const isBiz = !isHarbour

console.log("Parent URL HERE",parentURL,isBiz)
console.log("Ancestor Origins",window.location.ancestorOrigins)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ParentContext.Provider value={isBiz}>
    <App />
    </ParentContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
