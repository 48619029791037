
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import PricingPlanSelect from './PricingPlanSelect';
import PricingAuthCardInput from './PricingAuthCardInput';


export default function PricingComponent({close}) {


    const [step, setStep] = useState(1)
    const [planChoice, setPlanChoice] = useState({option:2,frequency:"annually"})

        
    useEffect(() => {

    },[])
        

    const planSelected = (index,freq) => {
        setStep(2)
        setPlanChoice({option:index,frequency:freq})
    }
    

    return(

        <div className='column ai-centre gap64 width100 marginTop200'>
             <span className='display-xl bold'>Pricing</span>
             {step===1 && <PricingPlanSelect purchasePlan={planSelected} planChoice={planChoice} close={close}/>}
             {step===2 && <PricingAuthCardInput backClicked={()=>setStep(1)}/>}
        </div>

    )

}