import '../../styling/billing.css'
import React, {} from 'react';
import Skeleton from 'react-loading-skeleton';

export default function CurrentPlanTile({editPlan,loading,plan,user,addCredits}) {

// #region CONSTANTS & STATE VARIABLES
 

// #endregion




// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED

   
// #endregion


// #region OTHER

// #endregion    

    

    return(
        loading?
        <div className="currentPlanTile">
            <div className='cptTopRow'>
                <div className='cptTitleColumn'>
                    <div className='cptTitleRow'>
                        <Skeleton width={120} height={15}/>
                        <Skeleton width={80} height={25} borderRadius={16}/>
                    </div>
                    <Skeleton width={250} />
                </div>
                <div className='cptMonthlyPrice'>
                    <div className='cptMonthlyPriceLeft'>
                        <Skeleton width={20} height={30}/>
                        <Skeleton width={50} height={50}/>
                    </div>
                    <Skeleton width={80}/>
                </div>
            </div>

           <Skeleton width={550} height={100}/>

            <div className='cptTopRow centre'>
                <div className='cptTitleRow'>
                    <Skeleton height={20} width={20}/>
                    <Skeleton width={150}/>
                </div>
                <Skeleton height={30} width={80}/>
            </div>

        </div>
        :
        <div className="currentPlanTile">
            <div className='cptTopRow'>
                <div className='cptTitleColumn'>
                    <div className='cptTitleRow'>
                        <span className='text-lg medium gray-900'>{plan.id==="free"?"25":plan.Credits} credits </span>
                        <div className='cptPlanBubble text-sm medium primary-700'>{plan.id==="free"?"One-Off":plan.Frequency==="monthly"?"Monthly":"Yearly"}</div>
                    </div>
                   {/*  <span className='text-sm regular gray-600'>{plan.Description}</span>*/}
                </div>
                <div className='cptMonthlyPrice'>
                    <div className='cptMonthlyPriceLeft'>
                        <span className='display-md semibold gray-900'>£</span>
                        <span className='display-lg semibold gray-900'>{plan.Rate}</span>
                    </div>
                    <span className='text-md medium gray-600'>{`per ${plan.Frequency==="monthly"?"month":"year"}`}</span>        
                </div>
            </div>

            <div className='cptCreditSection'>
                <div className='cptTopRow'>
                    <span className='text-sm medium gray-900'>{`${user.credits - user.usedCredits} of ${user.credits} export credits remaining`}</span>
                    {/* <div className="tooltip">
                        <span className='text-sm semibold gray-300 pointer' //onClick={addCredits}
                        >Buy Credits</span>
                        <span className="tooltiptext button text-sm regular">Coming soon</span>
                    </div> */}
                </div>

                <progress className='cptProgress' value={user.usedCredits} max={user.credits} />

            </div>

            <div className='cptTopRow centre'>
                <div className="tooltip">
                    {/* <div className='cptTitleRow'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M1.33398 5.66602L6.77726 9.47631C7.21804 9.78486 7.43844 9.93913 7.67816 9.99889C7.88991 10.0517 8.11139 10.0517 8.32314 9.99889C8.56287 9.93913 8.78326 9.78486 9.22404 9.47631L14.6673 5.66602M4.53398 14.3327H11.4673C12.5874 14.3327 13.1475 14.3327 13.5753 14.1147C13.9516 13.9229 14.2576 13.617 14.4493 13.2407C14.6673 12.8128 14.6673 12.2528 14.6673 11.1327V6.86602C14.6673 5.74591 14.6673 5.18586 14.4493 4.75803C14.2576 4.38171 13.9516 4.07575 13.5753 3.884C13.1475 3.66602 12.5874 3.66602 11.4673 3.66602H4.53398C3.41388 3.66602 2.85383 3.66602 2.426 3.884C2.04968 4.07575 1.74372 4.38171 1.55197 4.75803C1.33398 5.18586 1.33398 5.74591 1.33398 6.86602V11.1327C1.33398 12.2528 1.33398 12.8128 1.55197 13.2407C1.74372 13.617 2.04968 13.9229 2.426 14.1147C2.85383 14.3327 3.41388 14.3327 4.53398 14.3327Z" stroke="#475467" strokeWidth="1.10833" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11 13.6C11 12.3399 11 11.7098 11.2308 11.2285C11.4338 10.8052 11.7578 10.461 12.1563 10.2452C12.6092 10 13.2022 10 14.3882 10H19.6118C20.7978 10 21.3908 10 21.8437 10.2452C22.2422 10.461 22.5662 10.8052 22.7692 11.2285C23 11.7098 23 12.3399 23 13.6V18.4C23 19.6601 23 20.2902 22.7692 20.7715C22.5662 21.1948 22.2422 21.5391 21.8437 21.7548C21.3908 22 20.7978 22 19.6118 22H14.3882C13.2022 22 12.6092 22 12.1563 21.7548C11.7578 21.5391 11.4338 21.1948 11.2308 20.7715C11 20.2902 11 19.6601 11 18.4V13.6Z" fill="#475467" stroke="#475467" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20.4994 19.5H19.05V17.2263C19.05 16.6841 19.0404 15.9862 18.2963 15.9862C17.5414 15.9862 17.4259 16.5769 17.4259 17.1868V19.4998H15.9765V14.8242H17.3679V15.4632H17.3874C17.5266 15.2247 17.7279 15.0285 17.9696 14.8955C18.2113 14.7625 18.4845 14.6977 18.7602 14.7079C20.2292 14.7079 20.5 15.6758 20.5 16.935L20.4994 19.5ZM14.3412 14.1851C14.1749 14.1851 14.0122 14.1357 13.8739 14.0432C13.7356 13.9506 13.6278 13.819 13.5641 13.6651C13.5004 13.5112 13.4837 13.3418 13.5161 13.1783C13.5486 13.0149 13.6286 12.8647 13.7462 12.7469C13.8638 12.629 14.0137 12.5488 14.1768 12.5162C14.34 12.4837 14.5091 12.5003 14.6628 12.5641C14.8165 12.6278 14.9479 12.7358 15.0403 12.8743C15.1327 13.0129 15.1821 13.1757 15.1821 13.3424C15.1822 13.453 15.1604 13.5626 15.1182 13.6648C15.0759 13.7671 15.014 13.8599 14.9359 13.9382C14.8578 14.0164 14.7651 14.0785 14.6631 14.1209C14.561 14.1632 14.4517 14.1851 14.3412 14.1851ZM15.0659 19.5H13.615V14.8242H15.0659V19.5Z" fill="white"/>
                        </svg>
                        <span className='text-sm semibold gray-600'>Get Contact Info Add-on</span>
                    </div> */}
                    <span className="tooltiptext button text-sm regular">Coming soon</span>
                </div>
                <button className='secondaryButton text-sm semibold' onClick={editPlan}>Change Plan</button>
            </div>

        </div>
    )

}