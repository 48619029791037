
import '../../styling/search.css'
import React, { useEffect, useState } from 'react';

import { SIC_CODES } from './Advanced/FilterTypes/siccodes';

export default function SearchResultBubble({company,PSCs,freeMode,plan}) {


// #region CONSTANTS & STATE VARIABLES
  
  const [people, setPeople] = useState([])

// #endregion



useEffect(() => {
    
    let peopleArr = []
    PSCs.forEach(element => {
        if(element.number === company.number)
        {
            peopleArr.push(element)
        }
    });
    
    setPeople(peopleArr)



  }, []); // eslint-disable-line react-hooks/exhaustive-deps



// #region SHOW COMPONENTS
const getAddressStr = (address) => {

    if(address)
    {
    let addressArray = []

    let split = address.split(",")

    var index = 0
    split.forEach(element => {
        
        if(index === split.length-1 && element.split(" ").length>1) ///POST CODE
        {
            var formatted = element
            if(element[0] !== " "){formatted=" "+formatted}
            addressArray.push(formatted.toUpperCase()+"")
        }
        else{
            var formatted2 = element.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
            if(index!==0 && formatted2[0] !== " "){formatted2=" "+formatted2}
            addressArray.push(formatted2)
        }

        index++
    });
    // element.registeredAddress.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})

    return addressArray.join(",")

}else{
    return ""
}

}

const formatFinancial = (number) => {

    if((number+"").includes('-')&& number>0)
    {
        let split = (number+"").split(' - ')
        return formatFinancial(split[0])+ " - " + formatFinancial(split[1])
    }
    else if(number>=1000000 || number<=-1000000)
    {
        if(number%1000000===0){return number/1000000 + "M"}
        else if(number%100000===0){return (number/1000000).toFixed(1) + "M"}
        else{return (number/1000000).toFixed(2) + "M"}
    }
    else if(number>1000 || number<-1000)
    {
        var thousands = (number/1000)
        if(thousands<10 && number%1000!==0){thousands = thousands.toFixed(1)}
        else{thousands = Math.floor(thousands)}

        return thousands + "K"
    }
    else{
        return number
    }

}

const getSICTitle = (code,sicJSON) => {

    var foundSIC = false

    for(const element of sicJSON)
    {
        if(element.sicCode === code)
        {
            foundSIC = true
            return {found:true,title:element.title}
        }
        else{
            if(element.subValues.length>0)
            {
                let res = getSICTitle(code,element.subValues)
                if(res.found){
                    foundSIC = true
                    return {found:true,title:res.title}
                }
            }
        }
        
    };

    if(!foundSIC)
    {
        return {found:false}
    }
    
}


const showShareholders = () => {

    let compArray = []
    var index = 0
    people.forEach(person => {
        compArray.push(
            <div className={`sbrTitleRow ${index===(people.length-1)?'noBorder':''}`} key={index}>
                <div className='sbrPerson'>
                    <div className='sbrPersonTopRow'>
                        <span className={`text-sm medium gray-900 ${freeMode?"blur":""} noselect`}>{person.firstName+" "+person.surname}</span>
                        {person.email!=="" && <img className='cdIcon' src={'/assets/mail-disable.png'} alt='mailDisabled'/>}
                        {/* {person.linkedIn!=="" && <img className='cdIcon' src={'/assets/linkedin_grey.png'}/>} */}
                    </div>
                    <span className='text-xs regular gray-600'>{showPersonStr(person)}</span>
                </div>
                <span className='text-sm medium gray-700'>{person.shareholderControl+"%"}</span>
            </div>
        )

        index++
    });

    return compArray

}

const showPersonStr = (person) => {

    let strArray = []

    let country = person.country
    if(country === "United Kingdom" || country === "Great Britain"){strArray.push("British")}
    else if(country!==""){strArray.push(country)}

    let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

    let dob = (person.monthDOB?`${months[person.monthDOB-1]} `:'')+person.yearDOB
    strArray.push("Born in "+dob)

    return strArray.join(' ∙ ')

}


const formatDate = (dateP) => {

    let months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    let date = new Date(dateP)

    return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
}



// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const openLink = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }
// #endregion


// #region OTHER
   
// #endregion

    



    return(
        <div className="searchBubble results">

            <div className='sbrRow top'>
                <div className= {`sbrLeft ${freeMode?"blur":""} noselect`}>
                    <span className='text-xl semibold gray-900'>{company.name}</span>
                    <span className='text-sm regular gray-600'>{company.number}</span>
                </div>

                <div className='sbrRight'>
                    <div className='sbrFinancialRow'>
                        <div className='sbrFinancialColumn'>
                            <span className='text-xs medium gray-500'>Revenue</span>
                            <span className='text-xl medium gray-900'>{company.turnoverStatus==='Estimated'?"~":""}£{formatFinancial(company.turnover)}</span>
                        </div>
                        <div className='sbrFinancialColumn'>
                            <span className='text-xs medium gray-500'>Net Profit</span>
                            <span className='text-xl medium gray-900'>{company.netProfitEstimate==='Estimated'?"~":""}{company.netProfit?"£"+formatFinancial(company.netProfit):"-"}</span>
                        </div>
                        <div className='sbrFinancialColumn'>
                            <span className='text-xs medium gray-500'>Total Assets</span>
                            <span className='text-xl medium gray-900'>£{formatFinancial(company.totalAssets)}</span>
                        </div>
                        <div className='sbrFinancialColumn'>
                            <span className='text-xs medium gray-500'>Debt ratio</span>
                            <span className='text-xl medium gray-900'>{(company.debtRatio/100).toFixed(2)||"-"}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='sbrRow'>
                <div className='sbrLeft companyData'>
                    <div className='sbrCompanyData'>
                        <img className='sbrCompanyIcon' src='/assets/calendar.png' alt='calendat'/>
                        <div className='sbrCompanyText'>
                            <span className='text-sm medium gray-900'>Incorporation date</span>
                            <span className='text-sm regular gray-600'>{formatDate(company.incorporation)}</span>
                        </div>
                    </div>
                    <div className='sbrCompanyData'>
                        <img className='sbrCompanyIcon' src='/assets/marker1.png' alt='marker'/>
                        <div className='sbrCompanyText'>
                            <span className='text-sm medium gray-900'>Registered Address</span>
                            <span className={`text-sm regular gray-600 ${freeMode?"blur":""} noselect`}>{getAddressStr(company.registeredAddress)}</span>
                        </div>
                    </div>
                    {company.tradingAddress  && <div className='sbrCompanyData'>
                        <img className='sbrCompanyIcon' src='/assets/marker2.png' alt='marker2'/>
                        <div className='sbrCompanyText'>
                            <span className='text-sm medium gray-900'>Trading Address</span>
                            <span className={`text-sm regular gray-600 ${freeMode?"blur":""} noselect`}>{company.tradingAddress}</span>
                        </div>
                    </div>}

                    {company.website  && <div className='sbrCompanyData'>
                        <img className='sbrCompanyIcon' src='/assets/link-02.png' alt='linkIcon'/>
                        <div className='sbrCompanyText'>
                            <span className='text-sm medium gray-900'>Website</span>
                            <span className={`text-sm regular gray-600 ${freeMode?"blur":""} noselect pointer underline`} onClick={()=>openLink(company.website)}>{company.website}</span>
                        </div>
                    </div>}
                    {company.numEmployees && <div className='sbrCompanyData'>
                        <img className='sbrCompanyIcon' src='/assets/users.png' alt='linkIcon'/>
                        <div className='sbrCompanyText'>
                            <span className='text-sm medium gray-900'>{company.numEmployees} Employees</span>
                            {/* <span className={`text-sm regular gray-600 ${freeMode?"blur":""} noselect`} >{company.numEmployees}</span> */}
                        </div>
                    </div>}
                </div>

                <div className='sbrRight'>
                    <div className='sbrShareholders'>
                        <div className='sbrTitleRow'>
                            <div className='sbrTitleRowLeft'>
                                <img className='sbrCompanyIcon' src='/assets/users.png' alt='users'/>
                                <span className='text-sm medium gray-900'>{people.length+ ` Shareholder${people.length===1?'':'s'}` }</span>
                            </div>
                            <span className='text-xs medium gray-600'>% of Shares</span>
                        </div>
                        <div className='sbrContent'>
                            {showShareholders()}
                        </div>
                    </div>

                    <div className='sbrShareholders'>
                        <div className='sbrTitleRow'>
                            <div className='sbrTitleRowLeft'>
                                <img className='sbrCompanyIcon' src='/assets/building.png' alt='building'/>
                                <span className='text-sm medium gray-900'>Industries</span>
                            </div>
                        </div>
                        <div className='sbrContent'>
                            <span className='text-sm regular gray-600'>{getSICTitle(company.sic,SIC_CODES).title + ` (${company.sic})`}</span>
                        </div>
                    </div>
                    
                </div>
            </div>

            
            
        </div>
    )

}