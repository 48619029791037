import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react';
import {onAuthStateChanged} from 'firebase/auth';
import { auth } from '.';
import Header from './components/Header';
import Payments from './screens/Payments';
import Login from './screens/Login';
import Account from './screens/Account';
import Search from './screens/Search';
import axios from "axios";
import SavedFilters from './screens/SavedFilters';
import Billing from './screens/Billing';
import SharedExport from './screens/SharedExport';
import SuccessToast from './components/popUps/SuccessToast';
import Admin from './screens/Admin';
import ForgotPassword from './screens/ForgotPassword';
import VerifyEmail from './screens/VerifyEmail.js'
import PricingComponent from './components/Pricing/PricingComponent';
import PricingIFrame from './components/Pricing/PricingIFrame';
import { themeSetter } from './localFunctions/themeSetter.js';
import { ParentContext } from './components/constants/ParentContext';
import VerifyEmailSuccess from './screens/VerifyEmailSuccess.js';
import PoweredByBC from './components/PoweredByBC.js';
// import DataScreen from './screens/Data';

function App() {

  const baseURL = 'https://us-central1-silent-matter-386414.cloudfunctions.net/'//process.env.REACT_APP_BASEURL;
  // const localURL = process.env.REACT_APP_LOCALURL;
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState(null)
  const [credits,setCredits] = useState(null)
  const [showSuccessToast,setShowSuccessToast] = useState(null)
  const [succesText,setSuccessText] = useState("")
  const [choosePlan, setChoosePlan] = useState(false)
  const [isBiz, setIsBiz] = useState(useContext(ParentContext))

  
  themeSetter(isBiz); // run themeSetter function to set primary colors


  useEffect(() => {
        
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setLoggedIn(true)
            let cr = localStorage.getItem("currentlyRegistering")
            if(cr !== null){
              localStorage.removeItem("currentlyRegistering")
              setUserData(null)
            }
            else{
              loadReducedUserFromDB(user.uid)
            }

            

        } else {
          setLoggedIn(false)
          setUserData(null)

          if(!isBiz &&  window.location.pathname !== '/login'){
            window.location.pathname = '/login'
          }
        }
      })
      return () => unsubscribe()
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const loadReducedUserFromDB = (uid) => {
        
    return new Promise(async (resolve, reject) => {
        
        axios.get(baseURL+'getBasicAccountDetails',{
            params:{
                uid: uid,
                isHarbourClub:!isBiz
            }
        }).then( (response) => {
        
            const data = response.data;
            if(!data.error===true)
            {
              console.log("DATA",data)
              console.log("NO ERROR",data.error)
              setUserData(data)
              localStorage.setItem("user",JSON.stringify(data))
              let credits = data.credits
              let creditsUsed = data.usedCredits
              setCredits(credits-creditsUsed)

              let stripeCustomerID = data.stripeCustomerID
              if(stripeCustomerID!==null && stripeCustomerID!==undefined)
              {
                getCustomerData(stripeCustomerID)
              }

              loadUserFromDB(uid)
            }
            

    
        });

    })

} 

  const loadUserFromDB = (uid) => {
        
      return new Promise(async (resolve, reject) => {
          
          axios.get(baseURL+'getAccountDetails',{
              params:{
                  uid: uid,
              }
          }).then( (response) => {
          
              const data = response.data;
              if(!data.error===true)
              {
                console.log("DATA",data)
                console.log("NO ERROR",data.error)
                setUserData(data)
                setIsBiz(!data.isHarbourClub);
                localStorage.setItem("user",JSON.stringify(data))
                let credits = data.credits
                let creditsUsed = data.usedCredits
                setCredits(credits-creditsUsed)

                let stripeCustomerID = data.stripeCustomerID
                if(stripeCustomerID!==null && stripeCustomerID!==undefined)
                {
                  getCustomerData(stripeCustomerID)
                }
              }
              
  
      
          });

      })

  } 
  const getCustomerData = (cusID) => {

    axios.get(baseURL2+'getCustomersStripeData',{
        params:{
            stripeID: cusID,
        }
    }).then( (response) => {
    
        localStorage.setItem("paymentMethod",JSON.stringify(response.data.paymentMethod))
    });
  }

  const successClicked = async(text) => {
      setSuccessText(text)
      setShowSuccessToast(true)
      await delay(5000);
      setShowSuccessToast(false)
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const showChoosePlan = (show) => {
    setChoosePlan(show)
  }
  
  return (
    <div className="App">
            {/* <button className="primaryButton aboveAll" onClick={successClicked}>Show Success</button> */}

      <Router>

        {window.location.pathname !== "/iframe" && <Header updateCredits={(x)=>setCredits(x)} loggedIn={loggedIn} user={userData} creditsP={credits} saveToast={successClicked} showChoosePlanP={choosePlan}/>}

        <Routes>
            <Route path='/' element={<Search user={userData} creditsP={credits} updateCredits={(x)=>setCredits(x)} saveToast={successClicked}/>}/>
            
            <Route path='/login' element={loggedIn?<Navigate to="/search"/>:<Login setUser={(x)=>setUserData(x)} setCredits={(x)=>setCredits(x)}/>}/>
            <Route path='/billing' element={<Billing updateCredits={(x)=>setCredits(x)} setUserP={(x)=>setUserData(x)} saveToast={successClicked} userP={userData}/>}/>
            <Route path='/payments' element={<Payments/>}/>
            {/* <Route path='/apis' element={<APITest/>}/> */}
            <Route path='/account' element={loggedIn?<Account/>:<Navigate to="/login"/>}/>
            <Route path='/search' element={<Search user={userData} creditsP={credits} updateCredits={(x)=>setCredits(x)} saveToast={successClicked}/>}/>
            <Route path='/search/newUser' element={<Search user={userData} creditsP={credits} updateCredits={(x)=>setCredits(x)} saveToast={successClicked}/>}/>
            {/* <Route path='/data' element={<DataScreen/>}/> */}
            <Route path='/savedFilters' element={<SavedFilters/>}/>
            <Route path='/exports' element={<SavedFilters isExports={true} updateCredits={(x)=>setCredits(x)} creditsP={credits}/>}/>
            <Route path='/sharedExport/:user/:exportID' element={<SharedExport/>}/>
            <Route path='/admin' element={<Admin/>}/>
            <Route path='/pricing' element={<PricingComponent/>}/>
            <Route path='/verify-email' element={<VerifyEmail showChoosePlan={showChoosePlan}/>} />
            <Route path='/verify-email-success' element={<VerifyEmailSuccess showChoosePlan={showChoosePlan}/>}/>
            <Route path='/passwordReset' element={<ForgotPassword/>}/>
            <Route path='/iframe' element={<PricingIFrame userP={userData}/>}/>
            {/* <Route path='/fundMyDeal' element={<FundMyDeal userP={userData}/>}/> */}


        </Routes>

      </Router>
      
      {showSuccessToast && <SuccessToast text={succesText}/>}

      {!isBiz && <PoweredByBC />}

      {window.location.pathname !== "/iframe" && <img className='bottomGraphic' src={isBiz?'/assets/bottomGraphic.png':'/assets/bottomGraphicHC.png'} alt='bottomGraphic'/>}
    </div>
  );
}

export default App;
