import '../styling/header.css'
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import HeaderPopUp from './popUps/HeaderPopUp';
import axios from "axios";
import { auth } from '..';
import { ParentContext } from './constants/ParentContext';
import PricingPlanSelect from './Pricing/PricingPlanSelect';
import ConfirmPlanPopUp from './Billing/ConfirmPlanPopUp';
import UpdatePaymentPopUp from './Billing/UpdatePaymentPopUp';

export default function Header({updateCredits, loggedIn, user, creditsP, saveToast, showChoosePlanP}) {

// #region CONSTANTS & STATE VARIABLES
    const navigate = useNavigate();
    const location = useLocation();

 
    const [initials,setInitials] = useState("")
    const [credits,setCredits] = useState(creditsP)

    const [userVar,setUserVar] = useState(user)


    const [selected,setSelected] = useState(null)

    const [showPopUp,setShowPopUp] = useState(false)
    const [hasAccess,setHasAccess] = useState(false) 

    const [endpoint,setEndpoint] = useState("") 

    const [showUpdatePayment, setShowUpdatePayment] = useState(false)
    const [showChoosePlan, setShowChoosePlan] = useState(false)
    const [showConfirmPlan, setShowConfirmPlan] = useState(false)
    const [showBuyCredits, setShowBuyCredits] = useState(false)
    const [addingCredit, setAddingCredit] = useState(null)

    // const [user, setUser] = useState(null)


    const [plan, setPlan] = useState(null)
    const [plans, setPlans] = useState(null)


    const [subscription, setSubscription] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)


    const [newPlan, setNewPlan] = useState(null)

    // const [user, setUser] = useState(null)

    
    const isBiz = useContext(ParentContext);
    const baseURL = process.env.REACT_APP_BASEURL;
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    
    // if(selected === null)
    // {
    //     switch (location.pathname) {
    //         case '/':
    //             setSelected('search')
    //             break;
    //         case '/exports':
    //             setSelected('exports')
    //             break;
    //         case '/savedFilters':
    //             setSelected('saved')
    //             break;
    //         case '/billing':
    //             setSelected('billing')
    //             break;
                
    //         default:
    //             break;
    //     }
    // }

    

// #endregion

useEffect(() => {
    setEndpoint(window.location.pathname)
},[window.location.pathname])// eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {

    if(user !== null)
    {
        console.log("set header user")
        let name = user.name

        let initialsArray = []

        let split = name.split(' ')
        initialsArray.push(split[0][0])
        if(split.length>1){initialsArray.push(split[1][0])}
        
        setInitials(initialsArray.join(''))
        setCredits(creditsP)
        setUserVar(user)
        let stripeCustomerID = user.stripeCustomerID
        getCustomerData(stripeCustomerID)
        getPlanData(user.plan,user.uid)
        // setUser(user)
    }
    else{
        setInitials("")
        setCredits("")
    }

},[user]) // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    setCredits(creditsP)
},[creditsP])

useEffect(() => {
    setShowChoosePlan(showChoosePlanP)
},[showChoosePlanP])

// useEffect(() => {
    
//     // let user = JSON.parse(localStorage.getItem("user"))
//     // console.log("user",user)

//     if(userP)
//     {
//         console.log("USER P",userP)
//         let user = userP
//         let stripeCustomerID = user.stripeCustomerID
//         getCustomerData(stripeCustomerID)
//         getPlanData(user.plan)
//         setUser(user)
//     }
//   }, [userP]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    if(loggedIn)
    {
        checkIfAdmin(auth.currentUser.uid)
    }
},[loggedIn])// eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    
    switch (location.pathname) {
        case '/':
            setSelected('search')
            break;
        case '/exports':
            setSelected('exports')
            break;
        case '/savedFilters':
            setSelected('saved')
            break;
        case '/billing':
            setSelected('billing')
            break;
            
        default:
            break;
    }
    
}, [location]);



// #region SHOW COMPONENTS
const planChosen = (plan) => {

    setNewPlan(plan)
    setShowChoosePlan(false)
    if(paymentMethod===null)
    {
        setShowUpdatePayment(true)
    }
    else{
        setShowChoosePlan(false)
        setShowConfirmPlan(true)
    }
}

const getCustomerData = (cusID) => {

    axios.get(baseURL2+'getCustomersStripeData',{
        params:{
            stripeID: cusID,
        }
    }).then( (response) => {
    

        setPaymentMethod(response.data.paymentMethod)
        localStorage.setItem("paymentMethod",JSON.stringify(response.data.paymentMethod))
        console.log("SAVING PM", response.data.paymentMethod)
        setSubscription(response.data.subscription || null)        


        let buyCredits = localStorage.getItem("buyCredits")

        if(buyCredits==="true") {
            setShowBuyCredits(true)
            localStorage.setItem("buyCredits",false)
        }
    });
}
const getPlanData = (plan,uid) => {


        axios.get(baseURL2+'getPlanDataV2',{
            params:{
                planID: plan,
                uid:uid//auth.currentUser.uid
            }
        }).then( (response) => {
        
            let plans = response.data.plan

            setPlans(plans)

            plans.forEach(planVar => {
                if(planVar.id === plan)
                {
                    setPlan(planVar)
                }
            });
        

            let upgradePlan = localStorage.getItem("upgradePlan")

            if(upgradePlan==="true") {
                setShowChoosePlan(true)
                localStorage.setItem("upgradePlan",false)
            }

        });
    
  }

  const newPlanAdded = (sub) => {
    console.log("SUBS",sub)
    setShowConfirmPlan(false)

    saveToast("Plan Updated")

    

    // if(!subscription)
    // {    
        setPlan(newPlan)

        user.usedCredits = 0
        user.credits = newPlan.Credits
        user.plan = newPlan.id
        localStorage.setItem("user",JSON.stringify(user))
        updateCredits(newPlan.Credits) 
    // }
    setNewPlan(null)
    setSubscription(sub)
  }

  const confirmEditPayment = () => {
    setShowConfirmPlan(false)
    setShowBuyCredits(false)
    setShowUpdatePayment(true)
  }

  const closePayment = () => {
    setNewPlan(null)
    setAddingCredit(false)
    setShowUpdatePayment(false)
  }

  const paymentCancel = () => {
    setShowUpdatePayment(false)

    if(paymentMethod===null)
    {
        setShowChoosePlan(true)
    }
    else if(newPlan !== null)
    {
        setShowConfirmPlan(true)
    }
    else if(addingCredit){
        setShowBuyCredits(true)
    }
  }

  useEffect(() => {
    
    if(showBuyCredits && !addingCredit)
    {
        setAddingCredit(true)
    }
    
  }, [showBuyCredits]);// eslint-disable-line react-hooks/exhaustive-deps
  
  const paymentMethodAdded = (pm) => {

    setPaymentMethod(pm)
    paymentCancel()
  
  }
// #endregion


// #region WEB REQUESTS

const checkIfAdmin = (uid) => {
        
    return new Promise(async (resolve, reject) => {

        axios.get(baseURL+'checkIfAdmin',{
            params:{
                uid: uid,
            }
        }).then( (response) => {
        
            
            const data = response.data;
            setHasAccess(data.isAdmin)
        });

    })

}
// #endregion


// #region BUTTONS CLICKED

    const goHome = () => {

        console.log("PATHNAME",window.location.pathname)

        if(window.location.pathname === '/login')
        {
            if(isBiz){
                let url = "https://www.bizcrunch.co"
                window.open(url, '_self');
            }
            
        
        }
        else{
            navigate(`../`, { replace: true});
            setSelected('search')
        }

        
    }

    const goExports = () => {
        navigate(`../exports`, { replace: true});
        setSelected('exports')
    }
    const goAdmin = () => {
        navigate(`../admin`, { replace: true});
        setSelected('admin')
    }

    const goSaved = () => {
        navigate(`../savedFilters`, { replace: true});
        setSelected('saved')
    }

    // const goBilling = () => {
    //     navigate(`../billing`, { replace: true});
    //     setSelected('billing')
    // }
    
    const login = () => {

        navigate(`../login`, { replace: true});

    }

    
    
// #endregion


// #region OTHER

// #endregion    

    

    return(
        <div className="header">

            <div className='headerLeftSection'>
                <img className='logoImg' src={isBiz?'/assets/logo.png':'/assets/logoHC.png'} onClick={goHome} alt='logo'/>
                {endpoint!=="/login" && <div className={`headerLink ${selected==="search"?"selected":""} text-sm semibold gray-900`} onClick={goHome}>
                    Search
                </div>}
                {loggedIn && userVar && <div className={`headerLink ${selected==="exports"?"selected":""} text-sm semibold gray-900`} onClick={goExports}>
                    Exports
                </div>}
                
                {loggedIn && <div className={`headerLink ${selected==="saved"?"selected":""} text-sm semibold gray-900`} onClick={goSaved}>
                    Saved filters
                </div>}   
                {loggedIn && hasAccess && <div className={`headerLink ${selected==="admin"?"selected":""} text-sm semibold gray-900`} onClick={goAdmin}>
                    Admin
                </div>}
                {/* {loggedIn && <div className={`headerLink ${selected==="billing"?"selected":""} text-sm semibold gray-900`} onClick={goBilling}>
                    Billing
                </div>}     */}
            </div>
            

            {!loggedIn && <button  className='secondaryButton' onClick={login}>Login</button>}
            {loggedIn && !userVar && <span className='text-sm'>Loading account...</span>}
            {loggedIn && userVar && <div className='column ai-start'>
    
                <div className='headerLoggedIn'>
                    <div className='headerCredits'>{credits} Credits</div>
                    {/* <img className='headerImage' src='/assets/headerFace.png'/> */}
                    <div className='headerName pointer' onClick={()=>setShowPopUp(!showPopUp)}>{initials}</div>
                    {/* <button className='secondaryButton' onClick={logout}>Logout</button> */}
                    
                    {showPopUp && <HeaderPopUp user={user} close={()=>setShowPopUp(false)}/>}
                    

                </div>
                <div className='headerLink text-xs primary-700 semibold pointer' onClick={() => setShowChoosePlan(true)}>Upgrade plan</div>

            </div>}

            {showUpdatePayment && <UpdatePaymentPopUp close={closePayment} setCard={(x)=>paymentMethodAdded(x)} cancel={paymentCancel}/>}
            {showChoosePlan && <PricingPlanSelect close={()=>setShowChoosePlan(false)} purchasePlan={(x)=>planChosen(x)} plans={plans} planChoice={plan}/>}
            {showConfirmPlan && 
            <ConfirmPlanPopUp 
                close={()=>setShowConfirmPlan(false)} 
                plan={newPlan} 
                dismissNewPlan={()=>setNewPlan(null)} 
                paymentMethod={paymentMethod} 
                editPayment={confirmEditPayment}
                currentSubscription={subscription}
                complete={newPlanAdded}
            />}
        </div>
    )

}