import '../../../../styling/filters.css'
import React, { useEffect, useState, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';
import * as constants from '../../../../constants';

export default function RangeFilterDropdown({filter,addedFilters,apply,hideApply,plan}) {


// #region CONSTANTS & STATE VARIABLES

  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [checkedEstimated, setCheckedEstimated] = useState(true)
  const isBiz = useContext(ParentContext);
// #endregion
var options = constants.FINANCIAL_OPTIONS2

if(filter.id === '3C2' || filter.id === '3A1'){options = constants.FINANCIAL_OPTIONS1}
else if(filter.id === '3A5'){options = constants.FINANCIAL_OPTIONS1}
else if(filter.id === '4A1'){options = constants.PSC_OPTIONS}
else if(filter.id === '4A4'){options = constants.AGE_OPTIONS}
else if(filter.id === '4C1'){options = constants.NUM_EMPLOYEE_OPTIONS}

useEffect(() => {

    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setMin(element.value.min)
            setMax(element.value.max)
            setCheckedEstimated(element.value.estimated)

        }

        // if(element.value.estimated){
        // }
    });  
    

    
},[]) // eslint-disable-line react-hooks/exhaustive-deps



// #region SHOW COMPONENTS

const addOptions = (type) => {

    

    let compArray = []
    options.forEach(element => {
        compArray.push(<option value={element.value} title={element.title}  disabled={isOptionDisabled(type,element.value)} key={element.title}>{element.title} </option>)
    });
    return compArray
}
    

const isOptionDisabled = (type,value) =>
{


    if(type==="max" && min!=="" && value<=+min)
    {
        console.log("TRUE")
        return true
    }
    else if(type==="min" && max!=="" && value>=+max)
    {
        return true
    }
    else{
        return false
    }
}

    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const applyFilter = () => {
    let value = {min:min,max:max,estimated:filter.hasEstimate?checkedEstimated:false}
    apply(filter,value)
   

  }

  const changeMin = (val) => {
    setMin(val)
    if(((val!==null && val!=="") || (max!==null && max !== "")) && hideApply)
    {
        // let value = {min:val,max:max,estimated:filter.hasEstimate?checkedEstimated:false}
        // apply(filter,value)
    }
}

const changeMax = (val) => {
    setMax(val)
    if(((min!==null && min!=="") || (val!==null && val !== "")) && hideApply)
    {
        // let value = {min:min,max:val,estimated:filter.hasEstimate?checkedEstimated:false}
        // apply(filter,value)
    }
}

  
  

  const isDisabled = () => {
    if(invalidPlan())
    {
        return true
    }
    else if((min===null || min==="") && (max===null || max === ""))
    {
        return true
    }
    else if(((min!==null && min!=="") && (max!==null && max !== "")) && +max<+min){return true}
    else{
        return false
    }
  }

  const invalidPlan = () => {
    if((filter.id === "4A1"||filter.id === "4A4") 
      && plan.includes("starter"))
    {
      return true
    }
    else{
      return false
    }
  }


// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className="rangeFilter">
            <span className='text-sm regular gray-600 text-left'>Select range</span>
            <div className='revenueInputRow'>
            <select className='textInput text-md regular gray-900 pointer' value={min} onChange={(e) => changeMin(e.target.value)}>
                <option value="" disabled hidden>Select Min</option>
                {addOptions('min')}
            </select>
                <span className='text-sm regular gray-500'>to</span>
                <select className='textInput text-md regular gray-900 pointer' value={max} onChange={(e) => changeMax(e.target.value)}>
                <option value="" disabled hidden>Select Max</option>
                {addOptions('max')}
            </select>
            </div>

            {filter.hasEstimate &&<div className='revenueInputRow'>
                <input id='checkbox_id'  className='input-md checkbox' type="checkbox" checked={checkedEstimated} onChange={()=>setCheckedEstimated(!checkedEstimated)}/>
                <label htmlFor="checkbox_id" className='checkboxLabel'/>
                {/* <img className='revEstIcon' src='/assets/profitable.png'/> */}
                <span className='text-sm medium gray-700'>~ Estimated Bizcrunch</span>
            </div>}
           
            <div className='row width100 jc-start'>
              <div className="tooltip">
                <button className='secondaryButton applyFilterBtn' disabled={isDisabled()} onClick={applyFilter}>
                    <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
                    <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
                </button>
                {invalidPlan() && <span className="tooltiptext button text-sm regular">You must upgrade to use this filter</span>}
              </div>
            </div>

            

        </div>
    )

}