import '../../styling/filters.css'
import React, { } from 'react';

export default function Tooltip({icon,text,side}) {

// #region CONSTANTS & STATE VARIABLES
 

// #endregion




// #region SHOW COMPONENTS

const getIcon = () => {

    if(icon==="normal"){return 'assets/info.png'}
    else if(icon==="dark"){return 'assets/info-dark.png'}
    else if(icon==="pink"){return 'assets/info-pink.png'}
    else if(icon==="question"){return 'assets/question-circle.png'}

}


// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED

   
// #endregion


// #region OTHER

// #endregion    

    

    return(
        <div className="tooltipInner">

        <img className='safInfo' src={getIcon()} alt='infoIcon'/>
        <span className={`tooltipInnertext ${side?"side":""} text-sm regular`}>{text}</span>


        </div>
    )

}