
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';


export default function SuccessToast({text}) {

    const [hidden, setHidden] = useState(false)

        
    useEffect(() => {
        setHidden(true)
    },[])
        
    

    return(

        <div className={`successToast ${hidden?"hidden":""}`}>
             <img className='successToastIcon' src='/assets/successToastIcon.png' alt='success'/>  
             <span className='text-sm semibold baseWhite'>{text}</span>
        </div>

    )

}