import * as XLSX from 'xlsx';

export const createExcel = (name,companyJSONList,contactJSONList,pscJSONList,financialJSONList) => {

    var keyDataSheetHeaders = {
        number:"Company Number",
        name:"Company Name",
        sic:"SIC Industry Code",
        sicName:"SIC Industry Name",
        // bcIndustry:"BC Industry",
        registeredAddress:"Registered Address",
        tradingAddress:"Trading Address",
        website:"Website",
        incorporation:"Incorporation Date",
        type:"Company Type",
        status:"Company Status",
        amountPSCs:"No. of PSCs",
        debtRatio:"Debt Ratio",
        netProfitPercentage:"Net Profit %",
        fanpRatio:"Fixed Asset : Net Profit Ratio",
        turnover:"Turnover",
        turnoverStatus:"Turnover Status",
        netProfit:"Net Profit",
        netProfitEstimate:"Net Profit Status",
        currentAssets:"Current Assets",
        fixedAssets:"Fixed Assets",
        totalAssets:"Total Assets",
        currentLiabilities:"Current Liabilities",
        longTermLiabilities:"Long-term Liabilities",
        netAssets:"Net Assets",
        bcTags:"Smart Acquire Filters",
        premium:"Company Type"        
    }

    var contactSheetHeaders = {
        number:"Company Number",
        name:"Company Name",
        sic:"SIC Code",
        sicName:"SIC Name",
        title:"Title",
        firstName:"First Name",
        surname:"Surname",
        email:"Business Email",
        emailStatus:"Email Confidence",
        linkedIn:"LinkedIn",
        shareholderType:"Shareholder Type",
        shareholderControl:"Shareholding %",
        age:"Shareholder Age"
    }

    var mailMergeSheetHeaders = {
        number:"Company Number",
        name:"Company Name",
        sic:"SIC Code",
        sicName:"SIC Name",
        bcIndustry:"BC Industry",
        title:"Title",
        firstName:"First Name",
        surname:"Surname",
        addressType:"Address Type",
        addressLine1:"Address Line 1",
        addressLine2:"Address Line 2",
        addressLine3:"Address Line 3",
        town:"Town",
        county:"County",
        postCode:"Postcode",
        country:"Country",
        email:"Email",
        emailStatus:"Email Confidence",
        shareholderType:"Shareholder Type",
        shareholderControl:"Shareholding %",
        age:"Shareholder Age"
    }


    var financialsSheetHeaders = {
        compNumber:"Company Number",
        compName:"Company Name",
        sic:"SIC Industry Code",
        sicName:"SIC Industry Name",
        // bcIndustry:"BC Industry",
        // registeredAddress:"Registered Address",
        // tradingAddress:"Trading Address",
        website:"Website",
        incorporation:"Incorporation Date",
        type:"Company Type",
        status:"Company Status",
        filingDate:"Filing Date",
        year:"Financial Year",
        debtRatio:"Debt Ratio",
        netProfitPercentage:"Net Profit %",
        fanpRatio:"Fixed Asset : Net Profit Ratio",
        turnover:"Turnover",
        turnoverStatus:"Turnover Status",
        netProfit:"Net Profit",
        netProfitEstimate:"Net Profit Status",
        currentAssets:"Current Assets",
        fixedAssets:"Fixed Assets",
        totalAssets:"Total Assets",
        currentLiabilities:"Current Liabilities",
        longTermLiabilities:"Long-term Liabilities",
        netAssets:"Net Assets",  
    }


    
    
   

    const workbook = XLSX.utils.book_new();

    if(companyJSONList!==null)
    {
        let keySheetData = []
        keySheetData.push(keyDataSheetHeaders)
        keySheetData.push(...companyJSONList)
        const worksheet = XLSX.utils.json_to_sheet(keySheetData,{ skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Key Data");
    }

    if(contactJSONList!==null)
    {
        let contactSheetData = []
        contactSheetData.push(contactSheetHeaders)
        contactSheetData.push(...contactJSONList)
        const worksheet2 = XLSX.utils.json_to_sheet(contactSheetData,{ skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Contact");
    }

    if(pscJSONList!==null)
    {
        let mailSheetData = []
        mailSheetData.push(mailMergeSheetHeaders)
        mailSheetData.push(...pscJSONList)
        const worksheet4 = XLSX.utils.json_to_sheet(mailSheetData,{ skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet4, "Mail Merge");
    }

    if(financialJSONList!==null)
    {
        console.log("Fin LIST", financialJSONList)

        let financialSheetData = []
        financialSheetData.push(financialsSheetHeaders)
        financialSheetData.push(...financialJSONList)
        const worksheet3 = XLSX.utils.json_to_sheet(financialSheetData,{ skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet3, "Financials");
    }
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, name+".xlsx");
    // OR you can save/write file locally.
}