
import '../../../../styling/search.css'
import React from 'react';



export default function TutorialBubble({step,next}) {


// #region CONSTANTS & STATE VARIABLES

  


// #endregion


// #region SHOW COMPONENTS

const getHeader = () => {
    switch(step)
    {
        case 'location':return 'Add Location';
        case 'industry':return 'Add Industry';
        case 'revenue':return 'Define Revenue';
        case 'saf':return 'Add smart acquire filters';
        case 'more':return 'Advanced filters';
        case 'resultRow':return 'See company details';
        case 'export':return 'Export company records';
        default:return '';
    }
}

const getContent = () => {
    switch(step)
    {
        case 'location':return 'Select a target location for your company search, or set a search radius from a set location.\n Tip: Radius searches tend to get more results!';
        case 'industry':return 'Select an industry sector or SIC code to pinpoint the ideal company.';
        case 'revenue':return 'Specify your desired revenue range to discover companies that perfectly match your ideal profile.';
        case 'saf':return 'Enhance your search by applying intelligent acquisition filters for pinpointing for the ideal company. You only get to pick one smart filter at a time on the free trial - so choose wisely! ';
        case 'more':return 'Customise your search using advanced filters. Get granular with debt ratios, incorporation dates, contact detail requirements and more!';
        case 'resultRow':return 'View company details by hovering over a company name and clicking “see more”. This only works for standard companies, though! Premium Companies (those with a gold background) are the top 5% of UK prospect SMEs and can only be accessed after export.';
        case 'export':return 'This is where most of the company data lives, so make sure you click that pink button! We provide a spreadsheet with multiple tabs to help you stay in control of your data for outreach. Try it now with your free credits.';
        default:return '';
    }
}


const getImage = () => {
    switch(step)
    {
        case 'location':return '/assets/tbImg1.png';
        case 'industry':return '/assets/tbImg2.png';
        case 'revenue':return '/assets/tbImg3.png';
        case 'saf':return '/assets/tbImg4.png';
        case 'more':return '/assets/tbImg5.png';
        case 'resultRow':return '/assets/tbImg6.png';
        case 'export':return '/assets/tbImg7.png';
        default:return '';
    }
}

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion

   


    return(
        <div className={`tutorialBubble ${step}`}>

        
            <img className={`tbImg ${step==="more"?"more":""}`} src={getImage()} alt='tutorialImg'/>                

            <div className='column ai-start gap8 paddingSides8'>
                <span className='text-md semibold'>{getHeader()}</span>
                <span className='text-xs medium alignLeft'>{getContent()}</span>
            </div>
                

            <div className='width100 row ai-centre jc-between paddingSides16 paddingBottom8'>
                {/* <span className='text-sm semibold pointer' onClick={close}>Skip tour</span> */}
                <button className='tbButton' onClick={next}>
                    <span className='text-sm semibold'>{step==="more"||step==="export"?"Finish":"Next"}</span>
                </button>
            </div>
                

            

        </div>
    )

}

