import '../../../../styling/filters.css'
import React, { useEffect, useState } from 'react';

import Tooltip from '../../../constants/Tooltip';
import ClearFiltersPopUp from '../../../popUps/ClearFiltersPopUp';

export default function MultipleFilter({filter,addedFilters,apply,savedFilters,collapsible,plan}) {


// #region CONSTANTS & STATE VARIABLES


  const [chosenOptions, setChosenOptions] = useState([])
  const [showClearPopUp, setShowClearPopUp] = useState(false)

  const [selectedOption, setSelectedeOption] = useState(null)
  const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');
// #endregion


useEffect(() => {

    setChosenOptions([])

    addedFilters.forEach(element => {
        let thisElement = {...element}
        if(thisElement.id === filter.id)
        {
            setChosenOptions(thisElement.value)
        }
    });
    
},[addedFilters.length]) // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    console.log("OPTIONS",filter.id,chosenOptions)
},[chosenOptions.length])// eslint-disable-line react-hooks/exhaustive-deps



// #region SHOW COMPONENTS
const showOptions = () => {

    let compArray = []
    var index = 0

    filter.values.forEach(element => {
        compArray.push(<div className={`collapsibleFilter ${chosenOptions.includes(element)||invalidPlan()?"selected":""}  ${index===filter.values.length-1?"noBorder":""}`} key={index}>

            <div className="tooltip width100">
                <div className='cfTitle' onClick={()=>addOption(element)}>
                    <div className='safLeft'>
                        <span className={`text-sm medium ${chosenOptions.includes(element)||invalidPlan()?"gray-300":"gray-700"}`}>{filter.subType==="savedFilter"?element.name:element}</span>
                        {filter.tooltips && <Tooltip icon={'normal'} text={filter.tooltips[index]}/>}
                    </div>
                    {(!chosenOptions.includes(element) && !invalidPlan()) && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.9987 3.33398V12.6673M3.33203 8.00065H12.6654" stroke={primary600} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                    {/* <img className='cfArrow' src={'/assets/plus.png'}/>} */}

                </div>
                {invalidPlan() && <span className="tooltiptext button text-sm regular">You must upgrade to use this filter</span>}
            </div>
        

        </div>)

        index++

    });

    return compArray

}
    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const addOption = (option) => {

    if(!invalidPlan())
    {

        if(filter.subType === "savedFilter")
        {
            if(addedFilters.length > 0){
                setShowClearPopUp(true)
                setSelectedeOption(option)
            }else{
                savedFilters(option.filters)    
            }
            
        }
        else{
            if(!chosenOptions.includes(option))
            {
                let COs = [...chosenOptions]
                COs.push(option)
                console.log("COs",COs)
                apply(filter,COs)
            }
            else{
                const index = chosenOptions.indexOf(option);
                chosenOptions.splice(index, 1);
                let COs = [...chosenOptions]
                apply(filter,COs)
            }
        }
    }

    
    
  }
// #endregion


// #region OTHER
   
  const invalidPlan = () => {
    if((filter.id === "4B") && plan.includes("starter")  && !plan.includes("Contact"))
    {
      return true
    }
    else{
      return false
    }
  }
// #endregion
    
    return(
        <div className={`multipleFilter ${collapsible?"collapsible":""}`}>

            {showOptions()}

            {showClearPopUp && <ClearFiltersPopUp close={()=>setShowClearPopUp(false)} confirm={()=>{savedFilters(selectedOption.filters)}}/>}

        </div>
    )

}