import { auth } from '..';
import '../styling/home.css'
import '../styling/constants/typography.css'
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import EditFiltersPopUp from '../components/popUps/EditFilterPopUp';
import { convertResponseReExport } from '../components/SavedFilterRow';

export default function SharedExport() {

    const { user,exportID } = useParams();
    const [showPopUp,setShowPopUp] = useState(false) 

    const [sharedExport,setSharedExport] = useState(null) 



    // const localURL2 = process.env.REACT_APP_LOCALURL2;
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    const primary700 = getComputedStyle(document.body).getPropertyValue('--primary-700');

    useEffect(() => {
        loadExportFromDB()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


// #region CONSTANTS & STATE VARIABLES

// #endregion


// #region SHOW COMPONENTS
const showFilters = () => {

    let compArray = []

    var index = 0

    sharedExport.filters.forEach(element => {

        if(index<4)
        {

            var icon
            var text

            var filter = element

            if(filter.type === 'location')
            {
                icon = '/assets/singleBuilding.png'
                text = filter.value.text
            }
            if(filter.type === 'range')
            {
                icon = '/assets/singleBuilding.png'
                text = getFinancialStrLocal(filter.value.min,"") + '-' + getFinancialStrLocal(filter.value.max,"")
            }
            else if(filter.type === 'multiple')
            {

                if(filter.title === "SIC Industry name")
                {
                    icon = '/assets/singleBuilding.png'
                    text = filter.value[0].title
                }
                else{
                    icon = '/assets/singleBuilding.png'
                    text = filter.value.join(', ')
                }
            }

            

            compArray.push(<div className='sfrFilters item pointer'>
                <img className='sfrItemIcon' src={icon} alt='icon'/>
                <span className='text-sm regular gray-600 sfrItemText'>{text}</span>
            </div>)

            


        }

        index++

    });

    return compArray

}

const getFinancialStrLocal = (turnover,turnoverStatus) => {

    var str = ""

    if(turnover!==null)
    {
        str+="£"

        if(turnover>=1000000 ||turnover<=-1000000)
        {
            let millions = (turnover/1000000)
            str+= millions+"M"
        }
        else{
            str += turnover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        if(turnoverStatus === "Estimated")
        {
            str= "~"+str
        }

        return str
    }
    else{
        return str
    }
}

// #endregion


// #region WEB REQUESTS
const loadExportFromDB = () => {
        
    return new Promise(async (resolve, reject) => {
        
        axios.get(baseURL2+'getSharedExport',{
            params:{
                user:user,
                exportID:exportID
            }
        }).then( (response) => {
        
            const data = response.data;
            setSharedExport(data.exportVar)
        });

    })

} 
// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion


//   const changeScreen = (route) => {
//     navigate(`../${route}`, { replace: true});
//   }

  const download = () => {
    if(auth.currentUser)
    {
        axios.post(baseURL2+'reExportSameBusinesses',{

            // uid:auth.currentUser.uid,
            companies:sharedExport.companies,
            timestamp:sharedExport.time
    
        }).then( (response) => {
        
            convertResponseReExport(response.data.companies,sharedExport.name)
        });
    }
    else{
        console.log("NO USER")
    }
  }

    

    return(
        <div className="sharedExportScreen">
            
            <span className='display-sm semibold gray-900'>Company records shared with you</span>
            {sharedExport && <div className='sharedExportTile'>
                <div className='column gap8 ai-start'>
                    <span className='text-lg semibold gray-900'>{sharedExport.name}</span>
                    <span className='text-sm regular gray-600'>{sharedExport.companies.length} Company Records</span>
                </div>

                <div className='column gap12 ai-start'>

                    <div className='sfrFilters'>
                        {showFilters()}
                    </div>

                    <div className='seeAllFilters pointer' onClick={()=>setShowPopUp(true)}>
                        <span className='text-sm medium gray-600'>See all filters</span>
                        <img className='safRightArrow' src='/assets/chevron-right.png' alt='rightArrow'/>
                    </div>
                </div>

                <div className="tooltip">
                    <div className='row ai-centre gap8 pointer'>
                        <span className='text-sm semibold primary-700' onClick={download}>Download</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M6.66699 10.5579L10.0003 13.8913M10.0003 13.8913L13.3337 10.5579M10.0003 13.8913V7.22461M18.3337 10.5579C18.3337 15.1603 14.6027 18.8913 10.0003 18.8913C5.39795 18.8913 1.66699 15.1603 1.66699 10.5579C1.66699 5.95557 5.39795 2.22461 10.0003 2.22461C14.6027 2.22461 18.3337 5.95557 18.3337 10.5579Z" stroke={primary700} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    {!auth.currentUser && <span className="tooltiptext button text-sm regular">Login first</span>}
                </div>

            </div>}

            {showPopUp && <EditFiltersPopUp savedFilter={sharedExport} close={()=>setShowPopUp(false)} isExports={true}/>}


        </div>
    )

}