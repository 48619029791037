
import '../../../../styling/search.css'
import React, {  } from 'react';

import SICFilter from '../../Advanced/FilterTypes/SICFilter';

export default function IndustrySearch({appliedFilters,applyFilter,remove,done}) {


// #region CONSTANTS & STATE VARIABLES
  
// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion

    const clear = () => {
        remove(filter)
    }

    let filter = {id:"2C",title:"SIC Industry name",type:"multiple",subType:"sic",placeholder:"Enter SIC Code"}
    

    return(
        <div className="searchBubble industry">
            
            <div className='searchBubbleTitleRow'>
                <span className='text-sm medium gray-700'>Industry</span>
                <span className='text-sm medium primary-700 pointer' onClick={clear}>Clear</span>
            </div>
            <SICFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} clear={clear}/>
            
            <div className='loginForgotRow margin24'><button onClick={done} className='text-sm medium gray-700'>Done</button></div>


        </div>
    )

}