import { auth } from "..";
import "../styling/login.css";
import "../styling/constants/button.css";
import "../styling/constants/inputs.css";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "@firebase/auth";
import { ParentContext } from "../components/constants/ParentContext";
import ReactPlayer from "react-player";

export default function Login({ setUser, setCredits, iframe }) {
  // #region CONSTANTS & STATE VARIABLES

  const baseURL = process.env.REACT_APP_BASEURL;
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const navigate = useNavigate();

  const [onLogin, setOnLogin] = useState(false);

  const [emailText, setEmailText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [nameText, setNameText] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0);
  const [muted, setMuted] = useState(true);
  const [startedWatching, setStartedWatching] = useState(false);
  const [watchedIntro, setWatchedIntro] = useState(
    localStorage.getItem("watchedIntro")
  );

  const isBiz = useContext(ParentContext);
  // const isHarbourClub = true;

  // #endregion className='testBtn'

  // #region SHOW COMPONENTS

  // #endregion

  // #region WEB REQUESTS
  const addUserToDB = (uid) => {
    axios
      .post(baseURL + "createUserAccount", {
        uid: uid,
        name: nameText,
        email: emailText,
        isHarbourClub: !isBiz,
        // referral: window.Rewardful.referral,
      })
      .then((response) => {
        let resVar = response.data;
        console.log("User", resVar);
        let stripeID = resVar.stripeID;

        let user = JSON.parse(localStorage.getItem("user"));
        user.stripeCustomerID = stripeID;
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        localStorage.setItem("isHarbourClub", !isBiz);
      });
  };

  const getUserFromDB = (uid) => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(baseURL + "getAccountDetails", {
          params: {
            uid: uid,
          },
        })
        .then((response) => {
          const data = response.data;
          resolve(data);
        });
    });
  };

  const sendVerifyEmail = (uid,email) => {

    console.log("Send Verify EMail",uid,email)

    return new Promise(async (resolve, reject) => {
      axios
        .get(baseURL2 + "sendVerifyEmail", {
          params: {
            uid: uid,
            email:email,
            isHC:!isBiz,
          },
        })
        .then((response) => {
          const data = response.data;
          resolve(data);
        });
    });
  };

  // #endregion

  // #region BUTTONS CLICKED
  const changeScreen = () => {
    setOnLogin(!onLogin);
    setNameText("");
    setEmailText("");
    setPasswordText("");
    setNameError("");
    setEmailError("");
    setPasswordError("");
  };

  // #endregion

  // #region OTHER

  // #endregion

  const login = () => {
    signInWithEmailAndPassword(auth, emailText, passwordText)
      .then(async (userCredential) => {
        // Signed in

        const user = userCredential.user;

        let data = await getUserFromDB(user.uid);

        localStorage.setItem("user", JSON.stringify(data));

        // navigate(`../search`, { replace: true});
        const userData = auth.currentUser;
        if (!iframe && userData !== null && userData.emailVerified) {
          // navigate(`../`, { replace: true});
        } else if(!iframe) {
          navigate(`../verify-email`, { replace: true });
        }
      })
      .catch((error) => {
        // An error happened.
        const errorMessage = error.message;
        const errorCode = error.code;
        console.log("ERROR", errorMessage, errorCode);

        if (errorCode === "auth/invalid-email") {
          setEmailError("Invalid email or username structure");
        }
        if (errorCode === "auth/user-not-found") {
          setEmailError("No account exists with this email or username");
        }
        if (errorCode === "auth/wrong-password") {
          setPasswordError("This is the incorrect password for this account");
        }
        if (errorCode === "auth/internal-error") {
          setPasswordError("An internal error occured");
        }
        // setErrorText("Error: "+ errorMessage)
      });
  };

  const register = () => {
    localStorage.setItem("currentlyRegistering", true);



    createUserWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {


        // Signed in
        const user = userCredential.user;

        let userJSON = {
          uid: user.uid,
          name: nameText,
          email: emailText,
          credits: 50,
          companiesExported: [],
          exports: [],
          plan: "free",
          savedFilters: [],
          stripeCustomerID: "",
          usedCredits: 0,
          isHarbourClub: !isBiz,
        };
        localStorage.setItem("user", JSON.stringify(userJSON));


        updateProfile(user, {
          displayName: nameText,
        });


        addUserToDB(user.uid);
        setUser(userJSON);
        if(!iframe){setCredits(25);}



        localStorage.setItem("newUser", true);

        const userData = auth.currentUser;
        if (!iframe && userData !== null && userData.emailVerified) {
          navigate(`../`, { replace: true });
        } else {
          // sendEmailVerification(user).then(function() {
          //     // Verification code sent successfully.
          //   }).catch(function(error) {
          //     // Handle errors here.
          //   });



          sendVerifyEmail(user.uid,user.email);

          if (!iframe) {
            navigate(`../verify-email`, { replace: true });
          }
        }
        // if(!iframe){navigate(`../search`, { replace: true});}
      })
      .catch((error) => {
        const errorMessage = error.message;
        const errorCode = error.code;
        console.log("ERROR", errorMessage, errorCode);

        if (errorCode === "auth/email-already-in-use") {
          setEmailError("This email is already being used by an existing user");
        }
        if (errorCode === "auth/invalid-password") {
          setPasswordError("Your password is invalid");
        }
        if (errorCode === "auth/internal-error") {
          setPasswordError("An internal error occured");
        }
      });
  };

  const submitClicked = () => {
    setEmailError("");
    setPasswordError("");
    setNameError("");

    if (!onLogin && nameText === "") {
      setNameError("Please enter your name");
    } else if (emailText === "") {
      setEmailError("Please enter an email");
    } else if (passwordText === "") {
      setPasswordError("Please enter a password");
    } else if (!validateEmail(emailText)) {
      setEmailError("Invalid email structure");
    } else if (passwordText.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      if (onLogin) {
        login();
      } else {
        register();
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const policyLinkClicked = (end) => {
    let url = "https://www.bizcrunch.co/policies/" + end;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const forgotPasswrod = () => {
    navigate(`../passwordReset`, { replace: true });
  };
  const handleIntroWatched = () => {
    setTimeout(() => {
      setWatchedIntro(true);
    }, 3000);
  };

  const handleDismissClick = () => {
    setWatchedIntro(true);
    setPlaying(false);
  };

  const handlePauseClick = () => {
    setPlaying(false);
    setVolume(0);
    setMuted(true);
  };

  const handlePlayClick = () => {
    setPlaying(true);
    setVolume(1);
    setMuted(false);
    setStartedWatching(true);
  };

  useEffect(() => {
    if (watchedIntro) {
      localStorage.setItem("watchedIntro", true);
    }
  }, [watchedIntro]);

  return (
    <div className={`loginScreen ${iframe ? "iframe" : ""}`}>
      {/* <img className='loginIcon' src='/assets/logoLetter.png'/> */}
      <div
        className={`cursor-pointer z-50 absolute justify-center items-center ${
          watchedIntro || isBiz ? "hidden" : ""
        }`}
      >
        {/* <div className={`flex justify-center items-center w-full h-full absolute ${playing?"hidden":""}`} >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div> */}
        <ReactPlayer
          className="overflow-hidden rounded-xl border-8"
          playing={playing}
          url="https://www.youtube.com/watch?v=OZPI9S0-oNE"//"https://vimeo.com/video/953994608?h=546257fcd8"
          width="640px"
          height="368px"
          title={"BizCrunch intro video"}
          autoplay
          controls={true}
          muted={muted}
          volume={volume}
          onEnded={handleIntroWatched}
          onPlay={handlePlayClick}
          onPause={handlePauseClick}
        />
        {startedWatching && (
          <div
            className="absolute top-0 left-0 w-10 h-10 p-2 bg-gray-200 rounded-full"
            onClick={handleDismissClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>

      <div
        className={`${
          watchedIntro || isBiz ? "" : "blur-lg pointer-events-none"
        }`}
      >
        

        <div className="loginTitles">

          <div className="row ai-centre gap12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z"
                fill="black"
              />
              <path
                d="M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z"
                fill="black"
              />
            </svg>

            {!isBiz && <span className='display-xs bold gray-900'>X</span>}

            {!isBiz && <img className='width48px'src='/assets/HC_Logo.png' alt='harborclubLogo'/>}

            

          </div>



          <span className="display-xs semibold gray-900">
            {onLogin ? "Login to Bizcrunch" : "Supercharge your search"}{" "}
          </span>
          <span className="text-md regular gray-600">
            {onLogin ? "No account? " : "Already registered? "}
            <span className="underline" onClick={changeScreen}>
              {onLogin ? "Register" : "Sign in"}
            </span>
          </span>
        </div>

        <div className="loginInputs ">
          {!onLogin && (
            <div className="loginInput">
              <span className="text-sm medium gray-700">Full name</span>
              <input
                className={`textInput ${nameError !== "" ? "error" : ""}`}
                type="text"
                placeholder="John Doe"
                value={nameText}
                onChange={(e) => setNameText(e.target.value)}
              />
              {nameError !== "" && (
                <span className="text-sm regular error-500">{nameError}</span>
              )}
            </div>
          )}

          <div className="loginInput">
            <span className="text-sm medium gray-700">Email address</span>
            <input
              className={`textInput ${emailError !== "" ? "error" : ""}`}
              type="email"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder="e.g. john@example.com"
              value={emailText}
              onChange={(e) => setEmailText(e.target.value)}
            />
            {emailError !== "" && (
              <span className="text-sm regular error-500">{emailError}</span>
            )}
          </div>

          <div className="loginInput">
            <span className="text-sm medium gray-700">Password</span>
            <input
              className={`textInput ${passwordError !== "" ? "error" : ""}`}
              type="password"
              placeholder="xxxxxxxx"
              value={passwordText}
              onChange={(e) => setPasswordText(e.target.value)}
            />
            {passwordError !== "" && (
              <span className="text-sm regular error-500">{passwordError}</span>
            )}
            {!iframe && (
              <div className="loginForgotRow">
                <span
                  className="underline text-sm regular gray-600"
                  onClick={forgotPasswrod}
                >
                  Forgot your password?
                </span>
              </div>
            )}
          </div>

          <button className="loginBtn primaryButton" onClick={submitClicked}>
            {onLogin ? "Sign in" : "Register"}
          </button>
        </div>

        {!onLogin && (
          <div className="loginTerms text-xs regular gray-600">
            <span>By registering you accept our</span>
            <div className="loginTermsRow">
              <span
                className="underline"
                onClick={() => policyLinkClicked("terms")}
              >
                {"Terms of Use"}
              </span>
              ,
              <span
                className="underline"
                onClick={() => policyLinkClicked("data")}
              >
                {"Data Policy"}
              </span>{" "}
              and{" "}
              <span
                className="underline"
                onClick={() => policyLinkClicked("privacy")}
              >
                {" Privacy Policy."}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
