export const SIC_MAPPING = [
  {
    "": "",
    "Section": "A",
    "SIC Code": 11,
    "SIC_Name": "Crop and animal production, hunting and related service activities",
    "Mapping 1": "Agriculture",
    "Final": "Agriculture"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 12,
    "SIC_Name": "Growing of perennial crops",
    "Mapping 1": "Perennial Crop Farming",
    "Final": "Perennial Crop Farming"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 13,
    "SIC_Name": "Plant propagation",
    "Mapping 1": "Horticulture",
    "Final": "Horticulture"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 14,
    "SIC_Name": "Animal production",
    "Mapping 1": "Animal Farming",
    "Final": "Animal Farming"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 15,
    "SIC_Name": "Mixed farming",
    "Mapping 1": "Mixed Farming",
    "Final": "Mixed Farming"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 16,
    "SIC_Name": "Support activities to agriculture and post-harvest crop activities",
    "Mapping 1": "Agricultural Support",
    "Final": "Agricultural Support"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 17,
    "SIC_Name": "Hunting, trapping and related service activities",
    "Mapping 1": "Hunting and Trapping",
    "Final": "Hunting and Trapping"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 21,
    "SIC_Name": "Silviculture and other forestry activities",
    "Mapping 1": "Forestry",
    "Final": "Forestry"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 22,
    "SIC_Name": "Logging",
    "Mapping 1": "Logging",
    "Final": "Logging"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 23,
    "SIC_Name": "Gathering of wild growing non-wood products",
    "Mapping 1": "Non-Wood Product Gathering",
    "Final": "Non-Wood Product Gathering"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 24,
    "SIC_Name": "Support services to forestry",
    "Mapping 1": "Forestry Support",
    "Final": "Forestry Support"
  },
  {
    "": "",
    "Section": "A",
    "SIC Code": 31,
    "SIC_Name": "Fishing and aquaculture",
    "Mapping 1": "Fishing and Aquaculture",
    "Final": "Fishing and Aquaculture"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 51,
    "SIC_Name": "Mining of hard coal",
    "Mapping 1": "Coal Mining",
    "Final": "Coal Mining"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 52,
    "SIC_Name": "Mining of lignite",
    "Mapping 1": "Lignite Mining",
    "Final": "Lignite Mining"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 61,
    "SIC_Name": "Extraction of crude petroleum",
    "Mapping 1": "Oil Extraction",
    "Final": "Oil Extraction"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 62,
    "SIC_Name": "Extraction of natural gas",
    "Mapping 1": "Gas Extraction",
    "Final": "Gas Extraction"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 71,
    "SIC_Name": "Mining of iron ores",
    "Mapping 1": "Iron Ore Mining",
    "Final": "Iron Ore Mining"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 72,
    "SIC_Name": "Mining of non-ferrous metal ores",
    "Mapping 1": "Non-Ferrous Metal Mining",
    "Final": "Non-Ferrous Metal Mining"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 81,
    "SIC_Name": "Quarrying of stone, sand and clay",
    "Mapping 1": "Stone and Sand Quarrying",
    "Final": "Stone and Sand Quarrying"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 89,
    "SIC_Name": "Mining and quarrying n.e.c.",
    "Mapping 1": "Miscellaneous Mining",
    "Final": "Miscellaneous Mining"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 91,
    "SIC_Name": "Support activities for petroleum and natural gas extraction",
    "Mapping 1": "Oil and Gas Support Services",
    "Final": "Oil and Gas Support Services"
  },
  {
    "": "",
    "Section": "B",
    "SIC Code": 99,
    "SIC_Name": "Support activities for other mining and quarrying",
    "Mapping 1": "Mining Support Services",
    "Final": "Mining Support Services"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 101,
    "SIC_Name": "Processing and preserving of meat and production of meat products",
    "Mapping 1": "Meat Processing",
    "Final": "Meat Processing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 102,
    "SIC_Name": "Processing and preserving of fish, crustaceans and molluscs",
    "Mapping 1": "Fish Processing",
    "Final": "Fish Processing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 103,
    "SIC_Name": "Processing and preserving of fruit and vegetables",
    "Mapping 1": "Fruit and Vegetable Processing",
    "Final": "Fruit and Vegetable Processing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 104,
    "SIC_Name": "Manufacture of vegetable and animal oils and fats",
    "Mapping 1": "Oil and Fat Manufacturing",
    "Final": "Oil and Fat Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 105,
    "SIC_Name": "Manufacture of dairy products",
    "Mapping 1": "Dairy Product Manufacturing",
    "Final": "Dairy Product Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 106,
    "SIC_Name": "Manufacture of grain mill products, starches and starch products",
    "Mapping 1": "Grain Milling and Starch Production",
    "Final": "Grain Milling and Starch Production"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 107,
    "SIC_Name": "Manufacture of other food products",
    "Mapping 1": "Miscellaneous Food Manufacturing",
    "Final": "Miscellaneous Food Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 108,
    "SIC_Name": "Manufacture of prepared animal feeds",
    "Mapping 1": "Animal Feed Manufacturing",
    "Final": "Animal Feed Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 109,
    "SIC_Name": "Manufacture of prepared pet foods",
    "Mapping 1": "Pet Food Manufacturing",
    "Final": "Pet Food Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 110,
    "SIC_Name": "Manufacture of beverages",
    "Mapping 1": "Beverage Manufacturing",
    "Final": "Beverage Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 120,
    "SIC_Name": "Manufacture of tobacco products",
    "Mapping 1": "Tobacco Product Manufacturing",
    "Final": "Tobacco Product Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 131,
    "SIC_Name": "Preparation and spinning of textile fibres",
    "Mapping 1": "Textile Preparation and Spinning",
    "Final": "Textile Preparation and Spinning"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 132,
    "SIC_Name": "Weaving of textiles",
    "Mapping 1": "Textile Weaving",
    "Final": "Textile Weaving"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 133,
    "SIC_Name": "Finishing of textiles",
    "Mapping 1": "Textile Finishing",
    "Final": "Textile Finishing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 139,
    "SIC_Name": "Manufacture of other textiles",
    "Mapping 1": "Miscellaneous Textile Manufacturing",
    "Final": "Miscellaneous Textile Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 141,
    "SIC_Name": "Manufacture of wearing apparel, except fur apparel",
    "Mapping 1": "Apparel Manufacturing",
    "Final": "Apparel Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 142,
    "SIC_Name": "Manufacture of articles of fur",
    "Mapping 1": "Fur Product Manufacturing",
    "Final": "Fur Product Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 143,
    "SIC_Name": "Manufacture of knitted and crocheted apparel",
    "Mapping 1": "Knitted and Crocheted Apparel Manufacturing",
    "Final": "Knitwear Production"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 151,
    "SIC_Name": "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness",
    "Mapping 1": "Leather Manufacturing",
    "Final": "Leather Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 152,
    "SIC_Name": "Manufacture of footwear",
    "Mapping 1": "Footwear Manufacturing",
    "Final": "Footwear Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 161,
    "SIC_Name": "Sawmilling and planing of wood",
    "Mapping 1": "Wood Processing",
    "Final": "Wood Processing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 162,
    "SIC_Name": "Manufacture of products of wood, cork, straw and plaiting materials",
    "Mapping 1": "Wood and Cork Products Manufacturing",
    "Final": "Wood and Cork Products"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 171,
    "SIC_Name": "Manufacture of paper and paper products",
    "Mapping 1": "Paper Manufacturing",
    "Final": "Paper Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 172,
    "SIC_Name": "Manufacture of articles of paper and paperboard",
    "Mapping 1": "Paper Products Manufacturing",
    "Final": "Paper Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 181,
    "SIC_Name": "Printing and service activities related to printing",
    "Mapping 1": "Printing Services",
    "Final": "Printing Services"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 182,
    "SIC_Name": "Reproduction of recorded media",
    "Mapping 1": "Media Reproduction",
    "Final": "Media Reproduction"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 191,
    "SIC_Name": "Manufacture of coke and refined petroleum products",
    "Mapping 1": "Coke and Petroleum Refining",
    "Final": "Coke and Petroleum Refining"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 192,
    "SIC_Name": "Manufacture of chemicals and chemical products",
    "Mapping 1": "Chemical Manufacturing",
    "Final": "Chemical Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 201,
    "SIC_Name": "Manufacture of basic pharmaceutical products and pharmaceutical preparations",
    "Mapping 1": "Pharmaceutical Manufacturing",
    "Final": "Pharmaceutical Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 202,
    "SIC_Name": "Manufacture of rubber and plastic products",
    "Mapping 1": "Rubber and Plastic Manufacturing",
    "Final": "Rubber and Plastic Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 203,
    "SIC_Name": "Manufacture of other non-metallic mineral products",
    "Mapping 1": "Non-Metallic Mineral Manufacturing",
    "Final": "Non-Metallic Mineral Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 204,
    "SIC_Name": "Manufacture of basic metals",
    "Mapping 1": "Basic Metal Manufacturing",
    "Final": "Basic Metal Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 205,
    "SIC_Name": "Manufacture of fabricated metal products, except machinery and equipment",
    "Mapping 1": "Metal Fabrication",
    "Final": "Metal Fabrication"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 206,
    "SIC_Name": "Manufacture of computer, electronic and optical products",
    "Mapping 1": "Electronic Products Manufacturing",
    "Final": "Electronic Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 207,
    "SIC_Name": "Manufacture of electrical equipment",
    "Mapping 1": "Electrical Equipment Manufacturing",
    "Final": "Electrical Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 208,
    "SIC_Name": "Manufacture of machinery and equipment n.e.c.",
    "Mapping 1": "Machinery Manufacturing",
    "Final": "Machinery Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 209,
    "SIC_Name": "Manufacture of motor vehicles, trailers and semi-trailers",
    "Mapping 1": "Vehicle Manufacturing",
    "Final": "Vehicle Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 210,
    "SIC_Name": "Manufacture of other transport equipment",
    "Mapping 1": "Transport Equipment Manufacturing",
    "Final": "Transport Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 221,
    "SIC_Name": "Manufacture of furniture",
    "Mapping 1": "Furniture Manufacturing",
    "Final": "Furniture Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 222,
    "SIC_Name": "Manufacture of jewelry, bijouterie and related articles",
    "Mapping 1": "Jewelry Manufacturing",
    "Final": "Jewelry Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 231,
    "SIC_Name": "Manufacture of musical instruments",
    "Mapping 1": "Musical Instrument Manufacturing",
    "Final": "Musical Instrument Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 232,
    "SIC_Name": "Manufacture of sports goods",
    "Mapping 1": "Sports Goods Manufacturing",
    "Final": "Sports Goods Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 233,
    "SIC_Name": "Manufacture of games and toys",
    "Mapping 1": "Toy Manufacturing",
    "Final": "Toy Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 234,
    "SIC_Name": "Manufacture of medical and dental instruments and supplies",
    "Mapping 1": "Medical Equipment Manufacturing",
    "Final": "Medical Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 235,
    "SIC_Name": "Manufacture of ophthalmic goods",
    "Mapping 1": "Ophthalmic Goods Manufacturing",
    "Final": "Ophthalmic Goods Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 236,
    "SIC_Name": "Manufacture of other goods n.e.c.",
    "Mapping 1": "Miscellaneous Goods Manufacturing",
    "Final": "Miscellaneous Goods Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 241,
    "SIC_Name": "Manufacture of basic pharmaceutical products",
    "Mapping 1": "Basic Pharmaceutical Products Manufacturing",
    "Final": "Basic Pharmaceuticals"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 242,
    "SIC_Name": "Manufacture of pharmaceutical preparations",
    "Mapping 1": "Pharmaceutical Preparations Manufacturing",
    "Final": "Pharmaceutical Preparations"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 243,
    "SIC_Name": "Manufacture of soap and detergents, cleaning and polishing preparations",
    "Mapping 1": "Cleaning Products Manufacturing",
    "Final": "Cleaning Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 244,
    "SIC_Name": "Manufacture of perfumes and toilet preparations",
    "Mapping 1": "Perfume and Cosmetics Manufacturing",
    "Final": "Perfume and Cosmetics Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 245,
    "SIC_Name": "Manufacture of other chemical products",
    "Mapping 1": "Miscellaneous Chemical Products Manufacturing",
    "Final": "Chemical Products"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 251,
    "SIC_Name": "Manufacture of rubber products",
    "Mapping 1": "Rubber Products Manufacturing",
    "Final": "Rubber Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 252,
    "SIC_Name": "Manufacture of plastic products",
    "Mapping 1": "Plastic Products Manufacturing",
    "Final": "Plastic Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 261,
    "SIC_Name": "Manufacture of glass and glass products",
    "Mapping 1": "Glass Manufacturing",
    "Final": "Glass Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 262,
    "SIC_Name": "Manufacture of refractory products",
    "Mapping 1": "Refractory Products Manufacturing",
    "Final": "Refractory Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 263,
    "SIC_Name": "Manufacture of clay building materials",
    "Mapping 1": "Clay Building Materials Manufacturing",
    "Final": "Clay Building Materials"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 264,
    "SIC_Name": "Manufacture of other porcelain and ceramic products",
    "Mapping 1": "Porcelain and Ceramic Products Manufacturing",
    "Final": "Porcelain and Ceramics"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 265,
    "SIC_Name": "Manufacture of cement, lime and plaster",
    "Mapping 1": "Cement and Lime Manufacturing",
    "Final": "Cement and Lime Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 266,
    "SIC_Name": "Manufacture of articles of concrete, cement and plaster",
    "Mapping 1": "Concrete and Cement Products Manufacturing",
    "Final": "Concrete and Cement Goods"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 267,
    "SIC_Name": "Cutting, shaping and finishing of stone",
    "Mapping 1": "Stone Processing",
    "Final": "Stone Processing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 268,
    "SIC_Name": "Manufacture of other non-metallic mineral products n.e.c.",
    "Mapping 1": "Miscellaneous Non-Metallic Mineral Products Manufacturing",
    "Final": "Non-Metallic Minerals"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 271,
    "SIC_Name": "Manufacture of basic iron and steel and of ferro-alloys",
    "Mapping 1": "Iron and Steel Manufacturing",
    "Final": "Iron and Steel Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 272,
    "SIC_Name": "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
    "Mapping 1": "Steel Tubes and Pipes Manufacturing",
    "Final": "Steel Tubes and Pipes Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 273,
    "SIC_Name": "Manufacture of other products of first processing of steel",
    "Mapping 1": "Steel Products Manufacturing",
    "Final": "Steel Products Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 274,
    "SIC_Name": "Manufacture of basic precious and other non-ferrous metals",
    "Mapping 1": "Non-Ferrous Metals Manufacturing",
    "Final": "Non-Ferrous Metals Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 275,
    "SIC_Name": "Casting of metals",
    "Mapping 1": "Metal Casting",
    "Final": "Metal Casting"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 279,
    "SIC_Name": "Manufacture of other fabricated metal products n.e.c.",
    "Mapping 1": "Miscellaneous Metal Products Manufacturing",
    "Final": "Metal Products"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 281,
    "SIC_Name": "Manufacture of general-purpose machinery",
    "Mapping 1": "General-Purpose Machinery Manufacturing",
    "Final": "General-Purpose Machinery"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 282,
    "SIC_Name": "Manufacture of agricultural and forestry machinery",
    "Mapping 1": "Agricultural Machinery Manufacturing",
    "Final": "Agricultural Machinery"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 283,
    "SIC_Name": "Manufacture of metal-forming machinery and machine tools",
    "Mapping 1": "Metal-Forming Machinery Manufacturing",
    "Final": "Metal-Forming Machinery"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 284,
    "SIC_Name": "Manufacture of other special-purpose machinery",
    "Mapping 1": "Special-Purpose Machinery Manufacturing",
    "Final": "Specialized Machinery"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 291,
    "SIC_Name": "Manufacture of motor vehicles",
    "Mapping 1": "Motor Vehicle Manufacturing",
    "Final": "Motor Vehicle Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 292,
    "SIC_Name": "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
    "Mapping 1": "Vehicle Body and Trailer Manufacturing",
    "Final": "Vehicle Body Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 293,
    "SIC_Name": "Manufacture of parts and accessories for motor vehicles",
    "Mapping 1": "Vehicle Parts Manufacturing",
    "Final": "Vehicle Parts Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 301,
    "SIC_Name": "Building of ships and boats",
    "Mapping 1": "Shipbuilding",
    "Final": "Shipbuilding"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 302,
    "SIC_Name": "Manufacture of railway locomotives and rolling stock",
    "Mapping 1": "Railway Equipment Manufacturing",
    "Final": "Railway Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 303,
    "SIC_Name": "Manufacture of air and spacecraft and related machinery",
    "Mapping 1": "Aerospace Manufacturing",
    "Final": "Aerospace Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 304,
    "SIC_Name": "Manufacture of military fighting vehicles",
    "Mapping 1": "Military Vehicle Manufacturing",
    "Final": "Military Vehicle Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 309,
    "SIC_Name": "Manufacture of transport equipment n.e.c.",
    "Mapping 1": "Miscellaneous Transport Equipment Manufacturing",
    "Final": "Transport Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 310,
    "SIC_Name": "Manufacture of furniture",
    "Mapping 1": "Furniture Manufacturing",
    "Final": "Furniture Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 321,
    "SIC_Name": "Manufacture of jewelry, bijouterie and related articles",
    "Mapping 1": "Jewelry Manufacturing",
    "Final": "Jewelry Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 322,
    "SIC_Name": "Manufacture of musical instruments",
    "Mapping 1": "Musical Instrument Manufacturing",
    "Final": "Musical Instrument Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 323,
    "SIC_Name": "Manufacture of sports goods",
    "Mapping 1": "Sports Goods Manufacturing",
    "Final": "Sports Goods Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 324,
    "SIC_Name": "Manufacture of games and toys",
    "Mapping 1": "Toy Manufacturing",
    "Final": "Toy Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 325,
    "SIC_Name": "Manufacture of medical and dental instruments and supplies",
    "Mapping 1": "Medical Equipment Manufacturing",
    "Final": "Medical Equipment Manufacturing"
  },
  {
    "": "",
    "Section": "C",
    "SIC Code": 329,
    "SIC_Name": "Other manufacturing n.e.c.",
    "Mapping 1": "Miscellaneous Manufacturing",
    "Final": "Miscellaneous Manufacturing"
  },
  {
    "": "",
    "Section": "D",
    "SIC Code": 351,
    "SIC_Name": "Electric power generation, transmission and distribution",
    "Mapping 1": "Electricity Supply",
    "Final": "Electricity Supply"
  },
  {
    "": "",
    "Section": "D",
    "SIC Code": 352,
    "SIC_Name": "Manufacture of gas; distribution of gaseous fuels through mains",
    "Mapping 1": "Gas Manufacture and Distribution",
    "Final": "Gas Manufacture and Distribution"
  },
  {
    "": "",
    "Section": "D",
    "SIC Code": 353,
    "SIC_Name": "Steam and air conditioning supply",
    "Mapping 1": "Steam and Air Conditioning Supply",
    "Final": "Steam and Air Conditioning Supply"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 360,
    "SIC_Name": "Water collection, treatment and supply",
    "Mapping 1": "Water Supply",
    "Final": "Water Supply"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 370,
    "SIC_Name": "Sewerage",
    "Mapping 1": "Sewerage Services",
    "Final": "Sewerage Services"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 381,
    "SIC_Name": "Waste collection",
    "Mapping 1": "Waste Collection",
    "Final": "Waste Collection"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 382,
    "SIC_Name": "Waste treatment and disposal",
    "Mapping 1": "Waste Treatment and Disposal",
    "Final": "Waste Treatment and Disposal"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 383,
    "SIC_Name": "Materials recovery",
    "Mapping 1": "Materials Recovery",
    "Final": "Materials Recovery"
  },
  {
    "": "",
    "Section": "E",
    "SIC Code": 390,
    "SIC_Name": "Remediation activities and other waste management services",
    "Mapping 1": "Waste Management Services",
    "Final": "Waste Management Services"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 411,
    "SIC_Name": "Development of building projects",
    "Mapping 1": "Building Development",
    "Final": "Building Development"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 412,
    "SIC_Name": "Construction of residential and non-residential buildings",
    "Mapping 1": "Building Construction",
    "Final": "Building Construction"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 421,
    "SIC_Name": "Construction of roads and railways",
    "Mapping 1": "Road and Railway Construction",
    "Final": "Road and Railway Construction"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 422,
    "SIC_Name": "Construction of utility projects",
    "Mapping 1": "Utility Project Construction",
    "Final": "Utility Project Construction"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 429,
    "SIC_Name": "Construction of other civil engineering projects",
    "Mapping 1": "Civil Engineering",
    "Final": "Civil Engineering"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 431,
    "SIC_Name": "Demolition and site preparation",
    "Mapping 1": "Demolition and Site Preparation",
    "Final": "Demolition and Site Preparation"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 432,
    "SIC_Name": "Electrical, plumbing and other construction installation activities",
    "Mapping 1": "Construction Installation",
    "Final": "Construction Installation"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 433,
    "SIC_Name": "Building completion and finishing",
    "Mapping 1": "Building Finishing",
    "Final": "Building Finishing"
  },
  {
    "": "",
    "Section": "F",
    "SIC Code": 439,
    "SIC_Name": "Other specialised construction activities",
    "Mapping 1": "Specialised Construction",
    "Final": "Specialised Construction"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 451,
    "SIC_Name": "Sale of motor vehicles",
    "Mapping 1": "Motor Vehicle Sales",
    "Final": "Motor Vehicle Sales"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 452,
    "SIC_Name": "Maintenance and repair of motor vehicles",
    "Mapping 1": "Vehicle Maintenance and Repair",
    "Final": "Vehicle Maintenance and Repair"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 453,
    "SIC_Name": "Sale of motor vehicle parts and accessories",
    "Mapping 1": "Vehicle Parts and Accessories Sales",
    "Final": "Vehicle Parts and Accessories Sales"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 454,
    "SIC_Name": "Sale, maintenance and repair of motorcycles and related parts and accessories",
    "Mapping 1": "Motorcycle Sales and Service",
    "Final": "Motorcycle Sales and Service"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 461,
    "SIC_Name": "Wholesale on a fee or contract basis",
    "Mapping 1": "Wholesale Intermediaries",
    "Final": "Wholesale Intermediaries"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 462,
    "SIC_Name": "Wholesale of agricultural raw materials and live animals",
    "Mapping 1": "Agricultural Wholesale",
    "Final": "Agricultural Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 463,
    "SIC_Name": "Wholesale of food, beverages and tobacco",
    "Mapping 1": "Food and Beverage Wholesale",
    "Final": "Food and Beverage Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 464,
    "SIC_Name": "Wholesale of household goods",
    "Mapping 1": "Household Goods Wholesale",
    "Final": "Household Goods Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 465,
    "SIC_Name": "Wholesale of information and communication equipment",
    "Mapping 1": "ICT Equipment Wholesale",
    "Final": "ICT Equipment Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 466,
    "SIC_Name": "Wholesale of other machinery, equipment and supplies",
    "Mapping 1": "Machinery and Equipment Wholesale",
    "Final": "Machinery and Equipment Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 467,
    "SIC_Name": "Other specialised wholesale",
    "Mapping 1": "Specialised Wholesale",
    "Final": "Specialised Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 469,
    "SIC_Name": "Non-specialised wholesale trade",
    "Mapping 1": "General Wholesale",
    "Final": "General Wholesale"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 471,
    "SIC_Name": "Retail sale in non-specialised stores",
    "Mapping 1": "General Retail",
    "Final": "General Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 472,
    "SIC_Name": "Retail sale of food, beverages and tobacco in specialised stores",
    "Mapping 1": "Food and Beverage Retail",
    "Final": "Food and Beverage Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 473,
    "SIC_Name": "Retail sale of automotive fuel in specialised stores",
    "Mapping 1": "Fuel Retail",
    "Final": "Fuel Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 474,
    "SIC_Name": "Retail sale of information and communication equipment in specialised stores",
    "Mapping 1": "ICT Equipment Retail",
    "Final": "ICT Equipment Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 475,
    "SIC_Name": "Retail sale of other household equipment in specialised stores",
    "Mapping 1": "Household Equipment Retail",
    "Final": "Household Equipment Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 476,
    "SIC_Name": "Retail sale of cultural and recreation goods in specialised stores",
    "Mapping 1": "Cultural and Recreation Goods Retail",
    "Final": "Cultural and Recreation Goods Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 477,
    "SIC_Name": "Retail sale of other goods in specialised stores",
    "Mapping 1": "Specialised Retail",
    "Final": "Specialised Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 478,
    "SIC_Name": "Retail sale via stalls and markets",
    "Mapping 1": "Market Retail",
    "Final": "Market Retail"
  },
  {
    "": "",
    "Section": "G",
    "SIC Code": 479,
    "SIC_Name": "Retail trade not in stores, stalls or markets",
    "Mapping 1": "Non-Store Retail",
    "Final": "Non-Store Retail"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 491,
    "SIC_Name": "Passenger rail transport, interurban",
    "Mapping 1": "Passenger Rail Transport",
    "Final": "Passenger Rail Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 492,
    "SIC_Name": "Freight rail transport",
    "Mapping 1": "Freight Rail Transport",
    "Final": "Freight Rail Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 493,
    "SIC_Name": "Other passenger land transport",
    "Mapping 1": "Other Passenger Land Transport",
    "Final": "Other Passenger Land Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 494,
    "SIC_Name": "Freight transport by road and removal services",
    "Mapping 1": "Road Freight Transport",
    "Final": "Road Freight Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 495,
    "SIC_Name": "Transport via pipeline",
    "Mapping 1": "Pipeline Transport",
    "Final": "Pipeline Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 501,
    "SIC_Name": "Sea and coastal passenger water transport",
    "Mapping 1": "Coastal Passenger Water Transport",
    "Final": "Coastal Passenger Water Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 502,
    "SIC_Name": "Sea and coastal freight water transport",
    "Mapping 1": "Coastal Freight Water Transport",
    "Final": "Coastal Freight Water Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 503,
    "SIC_Name": "Inland passenger water transport",
    "Mapping 1": "Inland Passenger Water Transport",
    "Final": "Inland Passenger Water Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 504,
    "SIC_Name": "Inland freight water transport",
    "Mapping 1": "Inland Freight Water Transport",
    "Final": "Inland Freight Water Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 511,
    "SIC_Name": "Passenger air transport",
    "Mapping 1": "Passenger Air Transport",
    "Final": "Passenger Air Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 512,
    "SIC_Name": "Freight air transport and space transport",
    "Mapping 1": "Freight Air and Space Transport",
    "Final": "Freight Air and Space Transport"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 521,
    "SIC_Name": "Warehousing and storage",
    "Mapping 1": "Warehousing and Storage",
    "Final": "Warehousing and Storage"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 522,
    "SIC_Name": "Support activities for transportation",
    "Mapping 1": "Transportation Support Activities",
    "Final": "Transportation Support Activities"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 531,
    "SIC_Name": "Postal activities",
    "Mapping 1": "Postal Services",
    "Final": "Postal Services"
  },
  {
    "": "",
    "Section": "H",
    "SIC Code": 532,
    "SIC_Name": "Courier activities",
    "Mapping 1": "Courier Services",
    "Final": "Courier Services"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 551,
    "SIC_Name": "Hotels and similar accommodation",
    "Mapping 1": "Hotel Services",
    "Final": "Hotel Services"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 552,
    "SIC_Name": "Holiday and other short-stay accommodation",
    "Mapping 1": "Short-stay Accommodation",
    "Final": "Short-stay Accommodation"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 553,
    "SIC_Name": "Camping grounds, recreational vehicle parks and trailer parks",
    "Mapping 1": "Camping and RV Parks",
    "Final": "Camping and RV Parks"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 559,
    "SIC_Name": "Other accommodation",
    "Mapping 1": "Other Accommodation",
    "Final": "Other Accommodation"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 561,
    "SIC_Name": "Restaurants and mobile food service activities",
    "Mapping 1": "Restaurants and Mobile Food",
    "Final": "Restaurants and Mobile Food"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 562,
    "SIC_Name": "Event catering and other food service activities",
    "Mapping 1": "Event Catering and Food Services",
    "Final": "Event Catering and Food Services"
  },
  {
    "": "",
    "Section": "I",
    "SIC Code": 563,
    "SIC_Name": "Beverage serving activities",
    "Mapping 1": "Beverage Services",
    "Final": "Beverage Services"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 581,
    "SIC_Name": "Publishing activities",
    "Mapping 1": "Publishing",
    "Final": "Publishing"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 582,
    "SIC_Name": "Software publishing",
    "Mapping 1": "Software Publishing",
    "Final": "Software Publishing"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 591,
    "SIC_Name": "Motion picture, video and television programme production, sound recording and music publishing activities",
    "Mapping 1": "Media Production and Publishing",
    "Final": "Media Production and Publishing"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 592,
    "SIC_Name": "Sound recording and music publishing activities",
    "Mapping 1": "Music Recording and Publishing",
    "Final": "Music Recording and Publishing"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 601,
    "SIC_Name": "Radio broadcasting",
    "Mapping 1": "Radio Broadcasting",
    "Final": "Radio Broadcasting"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 602,
    "SIC_Name": "Television programming and broadcasting activities",
    "Mapping 1": "TV Broadcasting",
    "Final": "TV Broadcasting"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 611,
    "SIC_Name": "Wired telecommunications activities",
    "Mapping 1": "Wired Telecommunications",
    "Final": "Wired Telecommunications"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 612,
    "SIC_Name": "Wireless telecommunications activities",
    "Mapping 1": "Wireless Telecommunications",
    "Final": "Wireless Telecommunications"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 613,
    "SIC_Name": "Satellite telecommunications activities",
    "Mapping 1": "Satellite Telecommunications",
    "Final": "Satellite Telecommunications"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 619,
    "SIC_Name": "Other telecommunications activities",
    "Mapping 1": "Other Telecommunications",
    "Final": "Other Telecommunications"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 620,
    "SIC_Name": "Computer programming, consultancy and related activities",
    "Mapping 1": "IT Services and Consultancy",
    "Final": "IT Services and Consultancy"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 631,
    "SIC_Name": "Data processing, hosting and related activities; web portals",
    "Mapping 1": "Data Processing and Hosting",
    "Final": "Data Processing and Hosting"
  },
  {
    "": "",
    "Section": "J",
    "SIC Code": 639,
    "SIC_Name": "Other information service activities",
    "Mapping 1": "Other Information Services",
    "Final": "Other Information Services"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 641,
    "SIC_Name": "Monetary intermediation",
    "Mapping 1": "Banking",
    "Final": "Banking"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 642,
    "SIC_Name": "Activities of holding companies",
    "Mapping 1": "Holding Company Activities",
    "Final": "Holding Company Activities"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 643,
    "SIC_Name": "Trusts, funds and similar financial entities",
    "Mapping 1": "Trusts and Funds Management",
    "Final": "Trusts and Funds Management"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 649,
    "SIC_Name": "Other financial service activities, except insurance and pension funding",
    "Mapping 1": "Other Financial Services",
    "Final": "Other Financial Services"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 651,
    "SIC_Name": "Insurance",
    "Mapping 1": "Insurance Services",
    "Final": "Insurance Services"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 652,
    "SIC_Name": "Reinsurance",
    "Mapping 1": "Reinsurance Services",
    "Final": "Reinsurance Services"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 653,
    "SIC_Name": "Pension funding",
    "Mapping 1": "Pension Funding Services",
    "Final": "Pension Funding Services"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 661,
    "SIC_Name": "Activities auxiliary to financial services, except insurance and pension funding",
    "Mapping 1": "Financial Services Support",
    "Final": "Financial Services Support"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 662,
    "SIC_Name": "Activities auxiliary to insurance and pension funding",
    "Mapping 1": "Insurance and Pension Support",
    "Final": "Insurance and Pension Support"
  },
  {
    "": "",
    "Section": "K",
    "SIC Code": 663,
    "SIC_Name": "Fund management activities",
    "Mapping 1": "Fund Management",
    "Final": "Fund Management"
  },
  {
    "": "",
    "Section": "L",
    "SIC Code": 681,
    "SIC_Name": "Buying and selling of own real estate",
    "Mapping 1": "Real Estate Trading",
    "Final": "Real Estate Trading"
  },
  {
    "": "",
    "Section": "L",
    "SIC Code": 682,
    "SIC_Name": "Renting and operating of own or leased real estate",
    "Mapping 1": "Real Estate Rental",
    "Final": "Real Estate Rental"
  },
  {
    "": "",
    "Section": "L",
    "SIC Code": 683,
    "SIC_Name": "Real estate activities on a fee or contract basis",
    "Mapping 1": "Real Estate Services",
    "Final": "Real Estate Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 691,
    "SIC_Name": "Legal activities",
    "Mapping 1": "Legal Services",
    "Final": "Legal Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 692,
    "SIC_Name": "Accounting, bookkeeping and auditing activities; tax consultancy",
    "Mapping 1": "Accounting and Tax Consultancy",
    "Final": "Accounting and Tax Consultancy"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 693,
    "SIC_Name": "Management consultancy activities",
    "Mapping 1": "Management Consultancy",
    "Final": "Management Consultancy"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 701,
    "SIC_Name": "Activities of head offices",
    "Mapping 1": "Corporate Head Office Services",
    "Final": "Corporate Head Office Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 702,
    "SIC_Name": "Management consultancy activities",
    "Mapping 1": "Business and Management Consultancy",
    "Final": "Business and Management Consultancy"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 711,
    "SIC_Name": "Architectural and engineering activities; technical testing and analysis",
    "Mapping 1": "Architectural and Engineering Services",
    "Final": "Architectural and Engineering Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 712,
    "SIC_Name": "Technical testing and analysis",
    "Mapping 1": "Technical Testing and Analysis",
    "Final": "Technical Testing and Analysis"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 721,
    "SIC_Name": "Research and experimental development on natural sciences and engineering",
    "Mapping 1": "Scientific Research and Development",
    "Final": "Scientific Research and Development"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 722,
    "SIC_Name": "Research and experimental development on social sciences and humanities",
    "Mapping 1": "Social Sciences and Humanities Research",
    "Final": "Social Sciences Research"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 731,
    "SIC_Name": "Advertising",
    "Mapping 1": "Advertising Services",
    "Final": "Advertising Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 732,
    "SIC_Name": "Market research and public opinion polling",
    "Mapping 1": "Market Research",
    "Final": "Market Research"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 741,
    "SIC_Name": "Specialised design activities",
    "Mapping 1": "Design Services",
    "Final": "Design Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 742,
    "SIC_Name": "Photographic activities",
    "Mapping 1": "Photographic Services",
    "Final": "Photographic Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 743,
    "SIC_Name": "Translation and interpretation activities",
    "Mapping 1": "Translation and Interpretation",
    "Final": "Translation and Interpretation"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 749,
    "SIC_Name": "Other professional, scientific and technical activities n.e.c.",
    "Mapping 1": "Other Professional Services",
    "Final": "Other Professional Services"
  },
  {
    "": "",
    "Section": "M",
    "SIC Code": 750,
    "SIC_Name": "Veterinary activities",
    "Mapping 1": "Veterinary Services",
    "Final": "Veterinary Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 771,
    "SIC_Name": "Rental and leasing activities",
    "Mapping 1": "Rental and Leasing Services",
    "Final": "Rental and Leasing Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 772,
    "SIC_Name": "Rental of personal and household goods",
    "Mapping 1": "Personal and Household Goods Rental",
    "Final": "Personal and Household Goods Rental"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 773,
    "SIC_Name": "Renting and leasing of other machinery, equipment and tangible goods",
    "Mapping 1": "Machinery and Equipment Rental",
    "Final": "Machinery and Equipment Rental"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 774,
    "SIC_Name": "Leasing of intellectual property and similar products",
    "Mapping 1": "Intellectual Property Leasing",
    "Final": "Intellectual Property Leasing"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 781,
    "SIC_Name": "Activities of employment placement agencies",
    "Mapping 1": "Employment Placement Agencies",
    "Final": "Employment Placement Agencies"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 782,
    "SIC_Name": "Temporary employment agency activities",
    "Mapping 1": "Temporary Employment Services",
    "Final": "Temporary Employment Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 783,
    "SIC_Name": "Other human resources provision",
    "Mapping 1": "Human Resources Services",
    "Final": "Human Resources Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 791,
    "SIC_Name": "Travel agency and tour operator activities",
    "Mapping 1": "Travel Agencies and Tour Operators",
    "Final": "Travel Agencies and Tour Operators"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 792,
    "SIC_Name": "Activities of travel agents and tour operators",
    "Mapping 1": "Travel and Tour Services",
    "Final": "Travel and Tour Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 799,
    "SIC_Name": "Other reservation service and related activities",
    "Mapping 1": "Reservation Services",
    "Final": "Reservation Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 801,
    "SIC_Name": "Private security activities",
    "Mapping 1": "Private Security Services",
    "Final": "Private Security Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 802,
    "SIC_Name": "Security systems service activities",
    "Mapping 1": "Security Systems Services",
    "Final": "Security Systems Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 803,
    "SIC_Name": "Investigation activities",
    "Mapping 1": "Investigation Services",
    "Final": "Investigation Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 811,
    "SIC_Name": "Combined facilities support activities",
    "Mapping 1": "Facilities Support Services",
    "Final": "Facilities Support Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 812,
    "SIC_Name": "Cleaning activities",
    "Mapping 1": "Cleaning Services",
    "Final": "Cleaning Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 813,
    "SIC_Name": "Landscape service activities",
    "Mapping 1": "Landscape Services",
    "Final": "Landscape Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 821,
    "SIC_Name": "Office administrative and support activities",
    "Mapping 1": "Office Support Services",
    "Final": "Office Support Services"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 822,
    "SIC_Name": "Activities of call centres",
    "Mapping 1": "Call Centre Operations",
    "Final": "Call Centre Operations"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 823,
    "SIC_Name": "Organisation of conventions and trade shows",
    "Mapping 1": "Event Organization",
    "Final": "Event Organization"
  },
  {
    "": "",
    "Section": "N",
    "SIC Code": 829,
    "SIC_Name": "Business support service activities n.e.c.",
    "Mapping 1": "Business Support Services",
    "Final": "Business Support Services"
  },
  {
    "": "",
    "Section": "O",
    "SIC Code": 841,
    "SIC_Name": "Public administration and defence; compulsory social security",
    "Mapping 1": "Public Administration",
    "Final": "Public Administration"
  },
  {
    "": "",
    "Section": "O",
    "SIC Code": 842,
    "SIC_Name": "Provision of services to the community as a whole",
    "Mapping 1": "Community Services",
    "Final": "Community Services"
  },
  {
    "": "",
    "Section": "O",
    "SIC Code": 843,
    "SIC_Name": "Compulsory social security activities",
    "Mapping 1": "Social Security Administration",
    "Final": "Social Security Administration"
  },
  {
    "": "",
    "Section": "P",
    "SIC Code": 851,
    "SIC_Name": "Pre-primary and primary education",
    "Mapping 1": "Primary Education",
    "Final": "Primary Education"
  },
  {
    "": "",
    "Section": "P",
    "SIC Code": 852,
    "SIC_Name": "Secondary education",
    "Mapping 1": "Secondary Education",
    "Final": "Secondary Education"
  },
  {
    "": "",
    "Section": "P",
    "SIC Code": 853,
    "SIC_Name": "Higher education",
    "Mapping 1": "Higher Education",
    "Final": "Higher Education"
  },
  {
    "": "",
    "Section": "P",
    "SIC Code": 854,
    "SIC_Name": "Other education",
    "Mapping 1": "Other Education Services",
    "Final": "Other Education Services"
  },
  {
    "": "",
    "Section": "P",
    "SIC Code": 855,
    "SIC_Name": "Educational support services",
    "Mapping 1": "Educational Support Services",
    "Final": "Educational Support Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 861,
    "SIC_Name": "Hospital activities",
    "Mapping 1": "Hospital Services",
    "Final": "Hospital Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 862,
    "SIC_Name": "Medical and dental practice activities",
    "Mapping 1": "Medical and Dental Services",
    "Final": "Medical and Dental Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 869,
    "SIC_Name": "Other human health activities",
    "Mapping 1": "Other Health Services",
    "Final": "Other Health Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 871,
    "SIC_Name": "Residential nursing care facilities",
    "Mapping 1": "Nursing Care Facilities",
    "Final": "Nursing Care Facilities"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 872,
    "SIC_Name": "Residential care activities for mental retardation, mental health and substance abuse",
    "Mapping 1": "Mental Health and Substance Abuse Services",
    "Final": "Mental Health Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 873,
    "SIC_Name": "Residential care activities for the elderly and disabled",
    "Mapping 1": "Elderly and Disabled Care Services",
    "Final": "Elderly and Disabled Care Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 879,
    "SIC_Name": "Other residential care activities",
    "Mapping 1": "Other Residential Care Services",
    "Final": "Other Residential Care Services"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 881,
    "SIC_Name": "Social work activities without accommodation for the elderly and disabled",
    "Mapping 1": "Social Work Services for Elderly and Disabled",
    "Final": "Social Work for Elderly and Disabled"
  },
  {
    "": "",
    "Section": "Q",
    "SIC Code": 889,
    "SIC_Name": "Other social work activities without accommodation",
    "Mapping 1": "Other Social Work Services",
    "Final": "Other Social Work Services"
  },
  {
    "": "",
    "Section": "R",
    "SIC Code": 900,
    "SIC_Name": "Creative, arts and entertainment activities",
    "Mapping 1": "Arts and Entertainment",
    "Final": "Arts and Entertainment"
  },
  {
    "": "",
    "Section": "R",
    "SIC Code": 910,
    "SIC_Name": "Libraries, archives, museums and other cultural activities",
    "Mapping 1": "Cultural Services",
    "Final": "Cultural Services"
  },
  {
    "": "",
    "Section": "R",
    "SIC Code": 920,
    "SIC_Name": "Gambling and betting activities",
    "Mapping 1": "Gambling and Betting",
    "Final": "Gambling and Betting"
  },
  {
    "": "",
    "Section": "R",
    "SIC Code": 931,
    "SIC_Name": "Sports activities",
    "Mapping 1": "Sports Services",
    "Final": "Sports Services"
  },
  {
    "": "",
    "Section": "R",
    "SIC Code": 932,
    "SIC_Name": "Amusement and recreation activities",
    "Mapping 1": "Recreation Services",
    "Final": "Recreation Services"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 941,
    "SIC_Name": "Activities of business, employers and professional membership organisations",
    "Mapping 1": "Membership Organisations",
    "Final": "Membership Organisations"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 942,
    "SIC_Name": "Activities of trade unions",
    "Mapping 1": "Trade Union Services",
    "Final": "Trade Union Services"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 949,
    "SIC_Name": "Activities of other membership organisations",
    "Mapping 1": "Other Membership Services",
    "Final": "Other Membership Services"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 951,
    "SIC_Name": "Repair of computers and personal and household goods",
    "Mapping 1": "Computer and Goods Repair",
    "Final": "Computer and Goods Repair"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 952,
    "SIC_Name": "Repair of personal and household goods",
    "Mapping 1": "Household Goods Repair",
    "Final": "Household Goods Repair"
  },
  {
    "": "",
    "Section": "S",
    "SIC Code": 960,
    "SIC_Name": "Other personal service activities",
    "Mapping 1": "Personal Services",
    "Final": "Personal Services"
  },
  {
    "": "",
    "Section": "T",
    "SIC Code": 970,
    "SIC_Name": "Activities of households as employers of domestic personnel",
    "Mapping 1": "Domestic Employers",
    "Final": "Domestic Employers"
  },
  {
    "": "",
    "Section": "T",
    "SIC Code": 981,
    "SIC_Name": "Undifferentiated goods-producing activities of private households for own use",
    "Mapping 1": "Household Goods Production",
    "Final": "Household Goods Production"
  },
  {
    "": "",
    "Section": "T",
    "SIC Code": 982,
    "SIC_Name": "Undifferentiated service-producing activities of private households for own use",
    "Mapping 1": "Household Services",
    "Final": "Household Services"
  },
  {
    "": "",
    "Section": "U",
    "SIC Code": 990,
    "SIC_Name": "Activities of extraterritorial organisations and bodies",
    "Mapping 1": "Extraterritorial Organisations",
    "Final": "Extraterritorial Organisations"
  }
]