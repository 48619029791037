import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

import { auth } from '../..';

export default function ConfirmPlanPopUp({close,plan,paymentMethod,dismissNewPlan,editPayment,currentSubscription,complete,iframe}) {

   
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [couponInput, setCouponInput] = useState("")
    const [coupon, setCoupon] = useState()

    const baseURL2 = process.env.REACT_APP_BASEURL2;
    // const localURL2 = process.env.REACT_APP_LOCALURL2;

    const rate  = plan.Frequency === "monthly"?plan.Rate:plan.Rate*12

    const provider = paymentMethod?paymentMethod.card.networks.available[0].charAt(0).toUpperCase() + paymentMethod.card.networks.available[0].slice(1):"";
    const providerImage = paymentMethod?provider==='Visa'?'/assets/visa.png':'/assets/mastercard.png':""

    useEffect(() => {
        
    },[])
    

    const cancelClicked = () => {
        dismissNewPlan()
        close()
    }

    const confirmClicked = () => {
        if(!currentSubscription)
        {
            createSubscription()
        }
        else{
            updateSubscription()
        }
    }

    const applyCoupon = () => {
        
        setLoading(true)
        setError(false)
        axios.get(baseURL2+'searchForCoupon',{
            params:{
                coupon:couponInput,
                plan:plan.id
            }
            
        }).then( (response) => {
        
            setLoading(false)
            if(response.data.error){setError(true)}
            else{
                setCoupon(response.data.coupon)
            }
        });
    }


    const createSubscription = () => {

        setLoading(true)

        axios.post(baseURL2+'createSubscription',{
            uid:auth.currentUser.uid,
            customerID:paymentMethod.customer,
            stripePlan:plan.StripePlan,
            planID:plan.id,
            plan:plan,
            coupon:coupon?coupon.id:null
            
        }).then( (response) => {
            setLoading(false)
            complete(response.data.subscription)

            //Rewardful code
            // let user = JSON.parse(localStorage.getItem('user'))
            // window.rewardful('convert', { email: user.email });
        
        });
    }


    const updateSubscription = () => {

        setLoading(true)
        axios.post(baseURL2+'updateSubscription',{
            uid:auth.currentUser.uid,
            subscriptionID:currentSubscription.id,
            // initialTime:currentSubscription.startDate,
            // newTime:currentSubscription.endDate,
            // oldPlanID:currentSubscription.price,
            newPlanID:plan.StripePlan,
            plan:plan,
            coupon:coupon?coupon.id:null
        }).then( (response) => {
        
            setLoading(false)
            complete(response.data.subscription)
        
        });
    }

    

    const calculateDiscount = () => {

        var rate

        if(coupon.percent_off !== null)
        {
            rate  = plan.Frequency === "monthly"?plan.Rate:plan.Rate*12
            rate = rate*(coupon.percent_off/100)
    
            return rate
        }

        else if(coupon.amount_off !== null)
        {
            rate = coupon.amount_off
            return rate
        }
        else{
            return 0
        }

        
    }

    const calculateVAT = () => {
        var rate  = plan.Frequency === "monthly"?plan.Rate:plan.Rate*12
        if(coupon)
        {
            rate = rate - calculateDiscount()
        }
        
        return (rate*.2).toFixed(2)
    }

    const calculateTotal = () => {
        var rate  = plan.Frequency === "monthly"?plan.Rate:plan.Rate*12
        if(coupon)
        {
            rate = rate - calculateDiscount()
        }
        console.log("RATE",(rate + (rate*.2)))
        rate = (rate + (rate*.2)).toFixed(2)

        return rate
    }
    

    return(

        <div>
            {!iframe && <div className='tint'>
                <div className='popUp confirmPlan'>
                    <div className='row jc-between width100 ai-centre'>
                        <span className='text-lg semibold gray-900'>New plan details</span>
                        <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                    </div>

                    <div className='sfpuMain inner'>
                        <span className='text-sm medium gray-700'>Discount Code</span>
                        <div className='row gap12 ai-centre width100'>
                            <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter discount code"} value={couponInput} onChange={(e) => setCouponInput(e.target.value)} disabled={coupon}/>
                            <button className='primaryButton' disabled={coupon || couponInput===""} onClick={applyCoupon}>{coupon?"Applied":"Apply"}</button>
                        </div>
                        {coupon && <span className='text-xs primary-700'>Discount Applied!</span>}
                        {error && <span className='text-xs error-500'>Invalid discount code</span>}
                    </div>                

                    <div className='column width100 gap12'>
                        <div className='row jc-between width100'>
                            <span className='text-md semibold gray-600'>{plan.Name}</span>
                            <span className='text-md regular gray-600'>{`£${rate}`}</span>
                        </div>

                        {coupon && <div className='row jc-between width100'>
                            <span className='text-md regular gray-600'>Discount</span>
                            <span className='text-md regular gray-600'>{`- £${calculateDiscount()}`}</span>
                        </div>}

                        <div className='row jc-between width100'>
                            <span className='text-md regular gray-600'>VAT</span>
                            <span className='text-md regular gray-600'>{`£${calculateVAT()}`}</span>
                        </div>

                        <div className='greyLine'/>

                        <div className='row jc-between width100'>
                            <span className='text-md semibold gray-600'>Due Now</span>
                            <span className='text-md semibold gray-600'>{`£${calculateTotal()}`}</span>
                        </div>

                        <div className='greyLine'/>

                    </div>

                    <div className='confirmPayment'>
                        <div className='cptCardProviderImg'>
                            {paymentMethod && <img src={providerImage} alt='cardProvider'/>}
                        </div>
                        <div className='cptCardDetails'>
                            {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
                            {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
                            {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
                            <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
                        </div>
                    </div>

                    {loading && <div className='loader'>
                        <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                        </div>
                    }

                    {!loading && <div className='row width100 jc-centre gap20'>
                        <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
                        <button className='primaryButton width50' onClick={confirmClicked}>Confirm</button>
                    </div>}
                    
                </div>            
            </div>}
            {iframe && <div className='column gap12 ai-centre'>
                <div className='row jc-between width100 ai-centre'>
                    <span className='text-lg semibold gray-900'>New plan details</span>
                </div>

                <div className='sfpuMain inner'>
                    <span className='text-sm medium gray-700'>Discount Code</span>
                    <div className='row gap12 ai-centre width100'>
                        <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter discount code"} value={couponInput} onChange={(e) => setCouponInput(e.target.value)} disabled={coupon}/>
                        <button className='primaryButton' disabled={coupon || couponInput===""} onClick={applyCoupon}>{coupon?"Applied":"Apply"}</button>
                    </div>
                    {coupon && <span className='text-xs primary-700'>Discount Applied!</span>}
                    {error && <span className='text-xs error-500'>Invalid discount code</span>}
                </div>                

                <div className='column width100 gap12'>
                    <div className='row jc-between width100'>
                        <span className='text-md semibold gray-600'>{plan.Name}</span>
                        <span className='text-md regular gray-600'>{`£${rate}`}</span>
                    </div>

                    {coupon && <div className='row jc-between width100'>
                        <span className='text-md regular gray-600'>Discount</span>
                        <span className='text-md regular gray-600'>{`- £${calculateDiscount()}`}</span>
                    </div>}

                    <div className='row jc-between width100'>
                        <span className='text-md regular gray-600'>VAT</span>
                        <span className='text-md regular gray-600'>{`£${calculateVAT()}`}</span>
                    </div>

                    <div className='greyLine'/>

                    <div className='row jc-between width100'>
                        <span className='text-md semibold gray-600'>Due Now</span>
                        <span className='text-md semibold gray-600'>{`£${calculateTotal()}`}</span>
                    </div>

                    <div className='greyLine'/>

                </div>

                <div className='confirmPayment'>
                    <div className='cptCardProviderImg'>
                    {paymentMethod && <img src={providerImage} alt='cardProvider'/>}
                    </div>
                    <div className='cptCardDetails'>
                        {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
                        {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
                        {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
                        <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
                    </div>
                </div>

                {loading && <div className='loader'>
                    <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    </div>
                }

                {!loading && <div className='row width100 jc-centre gap20'>
                    <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
                    <button className='primaryButton width50' onClick={confirmClicked} disabled={!paymentMethod}>Confirm</button>
                </div>}
                
            </div>}
        </div>

    )

}