
import '../../styling/saved.css'
import React, { useEffect } from 'react';


export default function ClearFiltersPopUp({close,confirm}) {


        
    useEffect(() => {
        
    },[])


    
    const yesClicked = () => {
        confirm()
        close()
    }


    

    return(

        <div className='tint'>
            <div className='popUp cancelPlan'>
                <div className='row jc-end width100'>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='sfpuMain inner3'>
                    <span className='text-lg semibold gray-900 alignLeft'>Are you sure want to clear current filters?</span>
                </div>

                <div className='row width100 jc-between gap12'>
                    <button className='secondaryButton width50' onClick={close}>No</button>
                    <button className='primaryButton width50' onClick={yesClicked} >Yes</button>  
                </div>

                
            </div>            
        </div>

    )

}