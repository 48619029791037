import '../../../../styling/filters.css'
import React, { useEffect, useState, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';

export default function DropdownFilter({filter,addedFilters,apply}) {


// #region CONSTANTS & STATE VARIABLES

  const [value, setValue] = useState("")
  const isBiz = useContext(ParentContext);
  const options = [
    {value:1,title:'January'},
    {value:2,title:'February'},
    {value:3,title:'March'},
    {value:4,title:'April'},
    {value:5,title:'May'},
    {value:6,title:'June'},
    {value:7,title:'July'},
    {value:8,title:'August'},
    {value:9,title:'September'},
    {value:10,title:'October'},
    {value:11,title:'November'},
    {value:12,title:'December'},
  ]

// #endregion
useEffect(() => {

  setValue("")
    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setValue(element.value.value)
        }
    });
  },[addedFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps




// #region SHOW COMPONENTS

const addOptions = () => {

    let compArray = []
    options.forEach(element => {
        compArray.push(<option value={element.value} title={element.title} key={element.title}>{element.title}</option>)
    });
    return compArray
}


const isDisabled = () => {
  return value === ""
}
    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const applyFilter = (value) => {
    var title = ""
    options.forEach(element => {
        if(element.value=== +value){title=element.title}
    });
    
    apply(filter,{title:title,value:value})
  }
// #endregion


// #region OTHER

// #endregion
    
    return(
        <div className="textFilter padding3">

        <select className='textInput text-md regular gray-900 pointer' value={value} onChange={(e) => setValue(e.target.value)}>
            <option value="" disabled hidden>Choose month</option>
            {addOptions()}
        </select>

        <button className='secondaryButton applyFilterBtn' onClick={() => applyFilter(value)}>
              <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
              <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
          </button>

        </div>

    )

}