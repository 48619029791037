import '../styling/login.css';
import '../styling/constants/button.css';
import '../styling/constants/inputs.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from "axios";


export default function VerifyEmail({ showChoosePlan }) {
    
    // #region CONSTANTS & STATE VARIABLES
    const baseURL2 = process.env.REACT_APP_BASEURL2;


    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    const [userEmail, setUserEmail]= useState("sample@sample.com");

    const [resendTimer, setResendTimer] = useState(
        localStorage.getItem('resendTimer') === null || localStorage.getItem('resendTimer') === 0
            ? 0
            : Number(localStorage.getItem('resendTimer'))
    );
    
    // #endregion


    const sendVerifyEmail = (uid,email) => {
        
        return new Promise(async (resolve, reject) => {
            
            axios.get(baseURL2+'sendVerifyEmail',{
                    params:{
                        uid: uid,
                        email:email
                    }
            }).then( (response) => {
            
                const data = response.data;
                resolve(data)
        
            });

        })
    
    } 

    // #region SHOW COMPONENTS

    // #endregion

    // #region

    // #endregion

    // #region BUTTONS CLICKED

    //TODO: add email resend handling
    const handleResendEmail = () => {

        let authL = getAuth();
        let userL = authL.currentUser;
        // sendEmailVerification(userL).then(function() {
        //     // Verification code sent successfully.
        //   }).catch(function(error) {
        //     // Handle errors here.
        //   });

        sendVerifyEmail(userL.uid,userL.email)


        setResendTimer(30);
    };

    // const handleReloadPage = () => {
    //     navigate(0);
    // };

    
    // #region OTHER

    useEffect(() => {
        if (resendTimer > 0) {
            setTimeout(() => {
                localStorage.setItem('resendTimer', resendTimer);
                setResendTimer(resendTimer - 1);
            }, 1000);
        } else {
            localStorage.setItem('resendTimer', 0);
        }
    }, [resendTimer]);

    useEffect(() => {
        
        if ((user !== null && user.emailVerified) || (user !== null && window.location !== window.parent.location)) {
            // showChoosePlan(true)
            navigate(`../search`, { replace: true});
        } else if (user !== null) {
            setUserEmail(user.email);
        }
    },[user,navigate]);


    // #endregion

    return (
        <div className='loginScreen'>
            {/* <img className='loginIcon' src='/assets/logoLetter.png'/> */}
            <div className='flex flex-col justify-center items-center gap-6 rounded-2xl p-8 shadow-lg shadow-gray-400 z200 '>
                <div className='flex flex-col items-center gap-2'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z'
                            fill='black'
                        />
                        <path
                            d='M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z'
                            fill='black'
                        />
                    </svg>

                    <div className='loginTitles'>
                        <span className='display-xs semibold gray-900'>Please verify your email</span>
                    </div>
                </div>

                <div className='width500px flex flex-col text-center gap-1'>
                    <span className='text-md medium gray-700'>
                        We sent an email to
                        <span className='text-md bold gray-900'> {userEmail} </span>
                    </span>
                    <br />
                    <span className='text-md medium gray-700'>
                        Click on the link in the email to verify your account and then reload this page.
                    </span>
                    <br />
                    <span className='text-md medium gray-700'>
                        If you don't see the email make sure to check your spam folder.
                    </span>
                </div>

                <div className='loginInputs gap-5 !text-center justify-center'>
                    <span className='w-full text-md medium gray-700'>{"Still can't see it? "}
                        <a className="underline-red" onClick={handleResendEmail} href="/verify-email">
                            {"Send again"}
                        </a>
                        .
                    </span>
                    {/* <button className='loginBtn primaryButton' onClick={handleResendEmail} disabled={resendTimer}>
                        {!resendTimer ? 'Resend Verification Email' : `Send another email in ${resendTimer} seconds`}
                    </button> */}
                   
                   
                   
                </div>
            </div>
        </div>
    );
}
