import { auth } from '..';
import '../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import SavedFilterRow from '../components/SavedFilterRow';
// import LoadingPopUp from '../components/popUps/LoadingPopUp';

export default function SavedFilters({isExports,updateCredits,creditsP}) {


// #region CONSTANTS & STATE VARIABLES
const baseURL = process.env.REACT_APP_BASEURL;
// const baseURL2 = process.env.REACT_APP_BASEURL2;

const [savedFilters, setSavedFilters] = useState([])
const [updateVar, setUpdateVar] = useState(0)


// const [loading, setLoading] = useState(false)

// #endregion

// #region SHOW COMPONENTS

const showSaved = () => {
    let compArray = []
    
    savedFilters.forEach(element => {
        compArray.push(
            <SavedFilterRow savedFilterP={element} deleteFilter={deleteFilter} isExports={isExports} updateCredits={updateCredits} creditsP={creditsP}/>
        )
    });

    return compArray
}

// #endregion


// #region WEB REQUESTS


const getAccountDetails = () => {


    axios.get(baseURL+'getAccountDetails',{
        params:{
            uid: auth.currentUser.uid,
        }
    }).then( (response) => {
    
        const data = response.data;
        
        setSavedFilters(data.email)
        
    });

}

const deleteFilter = (id) => {

    console.log("SAVED FILTERS DELETE",id,savedFilters,savedFilters.filter(x => x.id!==id))

    setSavedFilters(savedFilters.filter(x => x.id!==id))
    setUpdateVar(updateVar+1)
}



// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion


useEffect(() => {
    
    let user = JSON.parse(localStorage.getItem("user"))

    if(user === null || user === undefined)
    {
        getAccountDetails()
    }
    else{

        console.log("IS EXPORT",isExports)

        if(isExports){

            
            if(user.exports){
                
                setSavedFilters(user.exports)

                // getExportUpdates(user.exports)
            }else{
                setSavedFilters([])
            }

        }else{
            setSavedFilters(user.savedFilters||[])
        }
        
    }

    
    
  }, [isExports]); // eslint-disable-line react-hooks/exhaustive-deps


//   const getExportUpdates = (exports) => {

//     var exportFilterArray = []

//     exports.forEach(exportVar => {
        
//         let financialFilters = getFinancialFilters(exportVar.filters)
//         let bcFinanceFilters = getBCFinanceFilters(exportVar.filters)
//         let bizCrunchFilters = getBizCrunchFilters(exportVar.filters)

//         exportVar.financialFilters = financialFilters
//         exportVar.bcFinanceFilters = bcFinanceFilters
//         exportVar.bizCrunchFilters = bizCrunchFilters

//         exportVar.newCompanies = []
//         exportVar.updatedCompanies = []

//         exportFilterArray.push(exportVar)
//     });

//     setLoading(true)

//     axios.post(baseURL2+'checkUpdatesForSavedExports',{
//         exports:exportFilterArray,
//         uid:auth.currentUser.uid
        
//     }).then( (response) => {
    
//         setLoading(false)
//         const data = response.data;

//         let savedArray = []

//         exports.forEach(exportVar => {
            
//             let findFilter = data.results.filter(x => x.id === exportVar.id)
//             exportVar.newCompanies = findFilter[0].newCompanies
//             exportVar.updatedCompanies = findFilter[0].updatedCompanies
//             exportVar.oldCompanies = findFilter[0].oldCompanies
//             savedArray.push(exportVar)
//         });

//         setSavedFilters(savedArray)
//         setUpdateVar(updateVar+1)

//     });

//   }
    

    return(
        <div className="fullScreen">
            <div className='container'>
                <div className='savedFiltersScreen'>
                    <div className='sfTitles'>
                        <span className='display-sm semibold gray-900'>{isExports?"Exports":"Saved Filters"}</span>
                        <span className='text-md regular gray-600'>{isExports?"Past exported companies":"Track companies or perform new searches based on your saved filter criterias."}</span>
                    </div>
                    
                
                    {showSaved()}
                </div>
            </div>
            {/* {loading && <LoadingPopUp />} */}
        </div>
    )

}