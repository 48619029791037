import '../../../../styling/filters.css'
import React, { useEffect, useState, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';

export default function TextFilter({filter,addedFilters,apply}) {


// #region CONSTANTS & STATE VARIABLES

  const [text, setText] = useState("")
  const isBiz = useContext(ParentContext);
// #endregion
useEffect(() => {

    setText("")

    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setText(element.value)
        }
    });
  },[filter.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = () => {
    if(text === "")
    {
        return true
    }
    else{
        return false
    }
  }




// #region SHOW COMPONENTS

    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const applyFilter = () => {
    apply(filter,text)
  }
// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className="textFilter padding3">

        <input className='textInput search text-md regular gray-900' type="text" placeholder={filter.placeholder} value={text} onChange={(e) => setText(e.target.value)}/>
        <button className='secondaryButton applyFilterBtn' onClick={applyFilter}>
            <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
            <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
        </button>

        </div>
    )

}