import '../../../../styling/filters.css'
import React, { useEffect, useState, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';

export default function DateRangeFilter({filter,addedFilters,apply}) {


// #region CONSTANTS & STATE VARIABLES
  const isBiz = useContext(ParentContext);

  const [min, setMin] = useState("")
  const [max, setMax] = useState("")

  const options = [
    {value:1800,title:'Pre 1980'},
    {value:1980,title:'1980'},
    {value:1981,title:'1981'},
    {value:1982,title:'1982'},
    {value:1983,title:'1983'},
    {value:1984,title:'1984'},
    {value:1985,title:'1985'},
    {value:1986,title:'1986'},
    {value:1987,title:'1987'},
    {value:1988,title:'1988'},
    {value:1989,title:'1989'},
    {value:1990,title:'1990'},
    {value:1991,title:'1991'},
    {value:1992,title:'1992'},
    {value:1993,title:'1993'},
    {value:1994,title:'1994'},
    {value:1995,title:'1995'},
    {value:1996,title:'1996'},
    {value:1997,title:'1997'},
    {value:1998,title:'1998'},
    {value:1999,title:'1999'},
    {value:2000,title:'2000'},
    {value:2001,title:'2001'},
    {value:2002,title:'2002'},
    {value:2003,title:'2003'},
    {value:2004,title:'2004'},
    {value:2005,title:'2005'},
    {value:2006,title:'2006'},
    {value:2007,title:'2007'},
    {value:2008,title:'2008'},
    {value:2009,title:'2009'},
    {value:2010,title:'2010'},
    {value:2011,title:'2011'},
    {value:2012,title:'2012'},
    {value:2013,title:'2013'},
    {value:2014,title:'2014'},
    {value:2015,title:'2015'},
    {value:2016,title:'2016'},
    {value:2017,title:'2017'},
    {value:2018,title:'2018'},
    {value:2019,title:'2019'},
    {value:2020,title:'2020'},
  ]

// #endregion

useEffect(() => {

    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setMin(element.value.min)
            setMax(element.value.max)
        }
    });
    
},[]) // eslint-disable-line react-hooks/exhaustive-deps

const isDisabled = () => {
  if((min===null || min==="") && (max===null || max === ""))
  {
      return true
  }
  else if(((min!==null && min!=="") && (max!==null && max !== "")) && +max<+min){return true}
  else{
      return false
  }
}


// #region SHOW COMPONENTS

const addOptions = (type) => {

    

  let compArray = []
  options.forEach(element => {
      compArray.push(<option value={element.value} title={element.title}  disabled={isOptionDisabled(type,element.value)} key={element.value}>{element.title} </option>)
  });
  return compArray
}
  

const isOptionDisabled = (type,value) =>
{


  if(type==="max" && min!=="" && value<=+min)
  {
      console.log("TRUE")
      return true
  }
  else if(type==="min" && max!=="" && value>=+max)
  {
      return true
  }
  else{
      return false
  }
}

    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const applyFilter = () => {
    let value = {min:min,max:max}
    apply(filter,value)
  }

  

// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className="textFilter padding3">

            <div className='revenueInputRow'>
              <select className='textInput date text-md regular gray-900 pointer' value={min} onChange={(e) => setMin(e.target.value)}>
                  <option value="" disabled hidden>Earliest Year</option>
                  {addOptions('min')}
              </select>
                  {/* <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${min===""?"regular":"medium"} gray-900`} type="number" placeholder={"Min"} value={min} onChange={(e) => minChanged(e.target.value)} min={0} max={filter.max?filter.max:null}/> */}
                  <span className='text-sm regular gray-500'>to</span>
                  {/* <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${max===""?"regular":"medium"} gray-900`} type="number" placeholder={"Max"} value={max} onChange={(e) => maxChanged(e.target.value)} min={min?min:0} max={filter.max?filter.max:null}/> */}
              <select className='textInput date text-md regular gray-900 pointer' value={max} onChange={(e) => setMax(e.target.value)}>
                  <option value="" disabled hidden>Latest Year</option>
                  {addOptions('max')}
              </select>
            </div>
            <button className='secondaryButton applyFilterBtn' onClick={applyFilter}>
                <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
                <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
            </button>


        </div>
    )

}