import '../../../styling/search.css'
import React, {} from 'react';

import MultipleFilter from './FilterTypes/MultipleFilter';
import TextFilter from './FilterTypes/TextFilter';
import DateRangeFilter from './FilterTypes/DateRangeFilter';
import RadioFilter from './FilterTypes/RadioFilter';
import SliderFilter from './FilterTypes/SliderFilter';
import RevenueFilter from './FilterTypes/RevenueFilter';
import RangeFilterDropdown from './FilterTypes/RangeFilterDropdown';
import Tooltip from '../../constants/Tooltip';

export default function CollapsibleFilter({filter,addedFilters,open,openClicked,apply,approx,setApprox,plan}) {


// #region CONSTANTS & STATE VARIABLES
  const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');
// #endregion


// #region SHOW COMPONENTS
const getCorrectFilterType = () => {

    if(filter.type === "range")
    {
        if(filter.isRevenue)
        {
            return <RevenueFilter filter={filter} addedFilters={addedFilters} apply={apply}  approx={approx} setApprox={setApprox}/>
        }
        else{
            return <RangeFilterDropdown filter={filter} addedFilters={addedFilters} apply={apply} plan={plan}/>
        }
    }

    if(filter.type === "slider")
    {
        return <SliderFilter filter={filter} addedFilters={addedFilters} apply={apply} plan={plan}/>
    }

    if(filter.type === "multiple")
    {
        return <MultipleFilter filter={filter} addedFilters={addedFilters} apply={apply} collapsible={true} plan={plan}/>
    }
    
    if(filter.type === "text")
    {
        return <TextFilter filter={filter} addedFilters={addedFilters} apply={apply}/>
    }
   
    if(filter.type === "dateRange")
    {
        return <DateRangeFilter filter={filter} addedFilters={addedFilters} apply={apply}/>
    }
   
    if(filter.type === "radio")
    {
        return <RadioFilter filter={filter} addedFilters={addedFilters} apply={apply} plan={plan}/>
    }
   

}

const sideTooltip = (filter) => {
    if(filter.id ==='4B')
    {
        return true
    }
    else{
        return false
    }
    
}
    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const titleClicked = () => {
    
        openClicked(filter.id,!open)

  }
// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className={`collapsibleFilter ${open?"open":"closed"}`}>

            
            <div className='cfTitle' onClick={titleClicked}>
                <div className='row ai-centre'>
                    <span className='text-sm medium gray-700'>{filter.title}</span>
                    {filter.tooltip && <Tooltip icon={"normal"} text={filter.tooltip} side={sideTooltip(filter)}/>}
                </div>
                {/* <img className='cfArrow' src={open?'/assets/redArrowUp.png':'/assets/redArrowDown.png'}/> */}
                {!open && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M4 6L8 10L12 6" stroke={primary600} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
                {open && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M12 10L8 6L4 10" stroke="#D70471" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
            </div>

            {(open) && <div className='cfFilter'>
                {getCorrectFilterType()}                
            </div>}    

        </div>
    )

}