import '../../styling/saved.css'
import React, { useEffect} from 'react';

export default function EditPlanPopUp({close,cancelPlan,choosePlan,plan}) {

   
    
    //const baseURL2 = process.env.REACT_APP_BASEURL2;

    

        
    useEffect(() => {
        
    },[])
    

    
    

    

    return(

        <div className='tint'>
            <div className='popUp editPlan'>
                <div className='sfpuTopRow'>
                    <span className='text-lg semibold gray-900'>Edit plan</span>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='width100 column gap20'>
                    
                    <div className='row jc-between ai-centre pointer paddingSides16' onClick={choosePlan}>
                        <div className='column ai-start'>
                            <span className='text-sm medium gray-700'>Change plan</span>
                            <span className='text-sm regular gray-600'>Upgrade or downgrade your plan</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M7.5 15.5L12.5 10.5L7.5 5.5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>

                    <div className='greyLine'/>

                    
                    {/* <div className='row jc-between ai-centre pointer paddingSides16' onClick={choosePlan}>
                        <div className='column ai-start'>
                            <span className='text-sm medium gray-700'>Switch to annual plan</span>
                            <span className='text-sm regular gray-600'>Simplify your billing from monthly to annual</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M7.5 15.5L12.5 10.5L7.5 5.5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>

                    <div className='greyLine'/> */}

                    
                    {plan.id!=="free" &&<div className="row jc-between ai-centre pointer paddingSides16" onClick={cancelPlan}>
                        <div className='column ai-start'>
                            <span className='text-sm medium gray-700'>Cancel plan</span>
                            <span className='text-sm regular gray-600'>Cancel your subscription</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M7.5 15.5L12.5 10.5L7.5 5.5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>}

                    {plan.id==="free" && <div className="tooltip">
                        <div className={`row jc-between ai-centre pointer paddingSides16 disabledCancelPlan`}>
                            <div className='column ai-start'>
                                <span className='text-sm medium gray-500'>Cancel plan</span>
                                <span className='text-sm regular gray-400'>Cancel your subscription</span>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M7.5 15.5L12.5 10.5L7.5 5.5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="tooltiptext button text-sm regular">You have no current subscription</span>
                        </div>
                    </div>}

                    
                    
                </div>

                
            </div>            
        </div>

    )

}