import { auth } from '..';
import '../styling/home.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import PaymentComponent from '../components/PaymentComponent';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { onAuthStateChanged } from 'firebase/auth';


export default function Payments() {

const live = window.location.hostname.includes('app.bizcrunch.co')
console.log("LIVE",live)

// #region CONSTANTS & STATE VARIABLES
const baseURL = process.env.REACT_APP_BASEURL;
const localURL2 = process.env.REACT_APP_LOCALURL;

const stripeTest = loadStripe(process.env.REACT_APP_STRIPE_TEST);
const stripeLive = loadStripe(process.env.REACT_APP_STRIPE_LIVE);

const [options, setOptions] = useState(null)
const [cusID, setCusID] = useState("")

const [intentID, setIntentID] = useState("")
const [subscription, setSubcription] = useState(false)
const [submitCount, setSubmitCount] = useState(0)


const appearance = {
    // labels: 'floating',
    variables:{
        // colorPrimary: '#E4D0A6',
        // colorBackground: '#242424',
        // colorTextPlaceholder: '#A0A0A0',
        // colorText: '#fff',
        // spacingUnit: '20px',
        // spacingGridRow: '5px',
        // spacingGridColumn: '5px',c
        // borderRadius: '25px',
        // fontLineHeight:'15px',
        // fontSizeBase:'16px'
    }
    
};

// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

const createStripeCustomer = () => {

    return new Promise(async (resolve, reject) => {


        axios.get(baseURL+'getCustomersStripeID',{
            params:{
                authID:auth.currentUser.uid
            }
        }).then( (response) => {
            let cust = response.data.customerID
            setCusID(cust)
        }) 

    })

}


const createSetupIntent = () => {

    setSubcription(true)


    axios.get(baseURL+'createSetUpIntent',{
        params:{
            customer: cusID,
            live:live,
        }
    }).then( (response) => {
    
        const data = response.data;
        
        let setUpID = data.setupIntent.client_secret
        console.log("Setup ID",setUpID,live)

        setOptions({clientSecret:setUpID,appearance:appearance})
        
        setIntentID("")
        setIntentID(setUpID)
    
    });

}


const createPaymentIntent = () => {

    setSubcription(false)

    axios.get(baseURL+'createPaymentIntent',{
        params:{
            customer: cusID,
            amount:50
        }
    }).then( (response) => {
    
        const data = response.data;
        
        let setUpID = data.paymentIntent.client_secret
        console.log("Setup ID",setUpID)

        setOptions({clientSecret:setUpID,appearance:appearance})
        
        setIntentID("")
        setIntentID(setUpID)
    
    });

}


const createSubscription = () => {

    setSubcription(false)

    axios.post(localURL2+'createSubscription',{
        
        customerID: cusID,
        uid:auth.currentUser.uid,
        billCycle:'monthly',
        subscriptionOption:'Medium'
        
    }).then( (response) => {
    
        const data = response.data;
        console.log("Subscription",data)

        // rewardful('convert', { email: 'customer@example.com' });
    
    });

}

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
const stripeResponse = (response) => {

    console.log("STRIPE RESPONSE",response)

    if(response.success && subscription){createSubscription()}
}
// #endregion



useEffect(() => {
        
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            createStripeCustomer()
        } 
      })
      return () => unsubscribe()
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


    

    

    return(
        <div className="homeScreen">
            <h2>Payments</h2>
            
            {cusID!=="" && <div>
                <button className='testBtn' onClick={createPaymentIntent}>Make Payment</button>
                <button className='testBtn' onClick={createSetupIntent}>Create Subscription</button>
            </div>}

            {(intentID) && 
                <Elements stripe={live?stripeLive:stripeTest} options={options} >
                    <PaymentComponent stripeResponse={stripeResponse} submitCount={submitCount} payment={!subscription}/>
                </Elements>
            }

            <button className='testBtn' onClick={()=>setSubmitCount(submitCount+1)} disabled={!intentID}>Submit</button>

            
        </div>
    )

}