import { auth } from '..';
import '../styling/home.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

export default function Account() {


// #region CONSTANTS & STATE VARIABLES
const baseURL = process.env.REACT_APP_BASEURL;
const [emailText, setEmailText] = useState("")
const [nameText, setNameText] = useState("")
// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS


const getAccountDetails = () => {


    axios.get(baseURL+'getAccountDetails',{
        params:{
            uid: auth.currentUser.uid,
        }
    }).then( (response) => {
    
        const data = response.data;
        
        console.log("USER DATA",data)

        setEmailText(data.email)
        setNameText(data.name)
    });

}

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion


useEffect(() => {
    
    getAccountDetails()
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


    

    return(
        <div className="homeScreen">
            <h2>Account</h2>
            
            <span><strong>Name: </strong>{nameText}</span>
            <span><strong>Email: </strong>{emailText}</span>

        </div>
    )

}