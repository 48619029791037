import { auth } from '../..';
import '../../styling/saved.css'
import React, { useState } from 'react';
import axios from "axios";
import AppliedFilter from '../Search/Advanced/AppliedFilters';

export default function EditFiltersPopUp({savedFilter,close,isExports}) {

    const [appliedFilters, setAppliedFilters] = useState(savedFilter.filters)
    const [error, setError] = useState("")
    const [updateVar, setUpdateVar] = useState(0)

    const showAppliedFilters = () => {

        let compArray = []
    
        
        appliedFilters.forEach(filter => {
            compArray.push(<AppliedFilter filter={filter} remove={removeAppliedFilter} isExports={isExports}/>)
        });
    
        return compArray
        
    }

    const removeAppliedFilter = (filter,value) => {

        console.log("Remove",filter)
        let filterArray = [...appliedFilters]


        var index = 0
        filterArray.forEach(element => {
            if(element.id === filter.id)
            {
                if(element.subType === "location" && element.value.locations.length>1)
                {
                    element.value.locations.splice(element.value.locations.indexOf(value), 1);

                }
                if(element.subType === "companyName" && element.value.names.length>1)
                {
                    element.value.locations.splice(element.value.names.indexOf(value), 1);

                }
                else if(element.type === "multiple" && element.value.length>1)
                {
                    element.value.splice(element.value.indexOf(value), 1);
                }
                else{
                    filterArray.splice(index, 1);
                }
                
            }
            index++
        });
        setAppliedFilters(filterArray)
        setUpdateVar(updateVar+1)
    
      }

      const baseURL2 = process.env.REACT_APP_BASEURL2;

    
    const saveChanges = () => {

        return new Promise(async (resolve, reject) => {


            axios.post(baseURL2+'editSavedFiltersInAccount',{

                uid:auth.currentUser.uid,
                filters:appliedFilters,
                id:savedFilter.id

            }).then( (response) => {
            
                const data = response.data;
                console.log("SDATA",data)
                updateLocally()
                close()
            });
        })
    }


    const updateLocally = () => {

            savedFilter.filters = appliedFilters

            let user = JSON.parse(localStorage.getItem("user"))

            user.savedFilters.forEach(element => {
                if(element.id === savedFilter.id)
                {
                    element.filters = appliedFilters
                }
            });
            
            localStorage.setItem("user",JSON.stringify(user))
    }

    const checkDisabled = () => {
        if(appliedFilters.length === 0)
        {
            if(error!=="You must have at least one filter"){setError("You must have at least one filter")}
            return true
        }
        else{
            if(error!==""){setError("")}
            return false
        }
        


    }


    return(

        <div className='tint'>
            <div className='popUp editFilter'>
                <div className='sfpuTopRow'>
                    <span className='text-lg semibold gray-900'>{`Filters applied for ${savedFilter.name}`}</span>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='efpuGrid'>
                    {showAppliedFilters()}
                </div>

                
                {!isExports &&<div className='sfpuButtons'>
                    <button className='secondaryButton' onClick={close}>Cancel</button>
                    <div className="tooltip">
                    <button className='primaryButton' onClick={saveChanges} disabled={checkDisabled()}>Save Changes</button>
                        {error!=="" && <span className="tooltiptext button text-sm regular">{error}</span>}
                    </div>
                    
                </div>}
            </div>            
        </div>

    )

}