import '../../../styling/search.css'
import React, { } from 'react';


import { pascalCase } from './FilterTypes/SICFilter';

export default function AppliedFilter({filter,remove,isExports}) {


// #region CONSTANTS & STATE VARIABLES
  
// #endregion


// #region SHOW COMPONENTS
const formatValue = () => {

    if(filter.type === "range")
    {
        let str = ""

        if(filter.value.max==="")
        {
            if(filter.subType === "financial"){str+="£"}
            str += filter.value.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            str+='<'
        }
        else if(filter.value.min==="")
        {
            str+='<'
            if(filter.subType === "financial"){str+="£"}
            str += filter.value.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            
        }
        else{
            if(filter.subType === "financial"){str+="£"}
            str += filter.value.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            str += ' - '
            if(filter.subType === "financial"){str+="£"}
            str += filter.value.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }


        if(filter.value.approx === true || filter.value.estimated === true){
            str+= " - (Inc. Estimated)"
        }

        return str
    }
    else if(filter.type === "slider")
    {
        let min = filter.value.min
        let max = filter.value.max

        return min + " - " + max+"%"
    }
    else if(filter.type === "dateRange")
    {
        let min = filter.value.min
        let max = filter.value.max

        return min + " - " + max
    }
    else if(filter.type === "add")
    {
        return "Yes"
    }
    else if(filter.type === "text" || filter.type === "radio")
    {
        return filter.value
    }
    
    
    else if(filter.type === "dropdown")
    {
        return filter.value.title
    }

    else if(filter.type === "dropdown")
    {
        return filter.value.title
    }
   

}

const multipleValues = () => {

    

    var compArray = []

    if(filter.subType === "location")
    {
        filter.value.locations.forEach(element => {
            var address = ""

            switch(filter.value.addressChoice)
            {
                case 'all': address = ' - (Registered or Trading Address)';break;
                case 'registered': address = ' - (Registered Address)';break;
                case 'trading': address = ' - (Trading Address)';break;
                default: address = ' - (Registered or Trading Address)';break;
            }
            compArray.push(<div className='appliedFilterValue' key={element.id || element}>
                <span className='text-sm medium gray-700'>{element + address}{filter.value.option === 2?" - within "+filter.value.radius + (+filter.value.radius===1?" mile":" miles"):""}</span>
                {!isExports && <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(filter,element)} alt='close'/>}
            </div>)

        });
    }
    else if(filter.subType === "companyName")
    {
        filter.value.names.forEach(element => {
            compArray.push(<div className='appliedFilterValue' key={element.id || element}>
                <span className='text-sm medium gray-700'>{`${filter.value.containChoice ==="contain"?"Containing":"Not containing"} ${element}`}</span>
                {!isExports && <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(filter,element)} alt='close'/>}
            </div>)

        });
    }
    else{

        filter.value.forEach(element => {
            compArray.push(<div className='appliedFilterValue' key={element.id || element}>
                <span className='text-sm medium gray-700'>{pascalCase(element.title?element.title:element)}</span>
                {!isExports && <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(filter,element)} alt='close'/>}
            </div>)
        });
    }

    return compArray    
}
    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className='appliedFilter right'>
            <strong>{filter.title}</strong>
            {filter.type!=="multiple" && 
                <div className='appliedFilterValue'>
                    <span className='text-sm medium gray-700 alignLeft'>{formatValue()}</span>
                    {!isExports && <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(filter)} alt='close'/>}
                </div>
            }

            {filter.type==="multiple" && 
                <div className='appliedFilterMultipleValue'>
                    {multipleValues()}
                </div>
            }



        </div>
    )

}