
import '../../styling/search.css'
import React, { useEffect, useState } from 'react';
import { SIC_CODES } from './Advanced/FilterTypes/siccodes';
import SearchResultBubble from './SearchResultBubble';
import { pascalCase } from './Advanced/FilterTypes/SICFilter';
import TutorialBubble from './Basic/Search Bubbles/TutorialBubble';
import { SIC_MAPPING } from './Advanced/FilterTypes/sicMapping';

export default function SearchResultRow({ element, PSCs, freeMode, plan, checked, onCheck, showTutorial, closeTutorial, nextTutorial }) {


    // #region CONSTANTS & STATE VARIABLES
    const [user] = useState(JSON.parse(localStorage.getItem("user")))
    const [hover, setHover] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    const primary700 = getComputedStyle(document.body).getPropertyValue('--primary-700');
    // #endregion


    useEffect(() => {

        if (!hover) {
            setShowPopUp(false)
        }

    }, [hover]);


    // #region SHOW COMPONENTS
    const formatPercentage = (percentage) => {
        var newPercentage = +percentage
        if (Math.abs(newPercentage) >= 1) {
            newPercentage = newPercentage.toFixed(1)
        }
        else {
            newPercentage = newPercentage.toFixed(2)
        }
        return newPercentage
    }
    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER

    // #endregion



    // const getAddressStr = (address) => {

    //         if(address)
    //         {
    //         let addressArray = []

    //         let split = address.split(",")

    //         var index = 0
    //         split.forEach(element => {

    //             if(index === split.length-1 && element.split(" ").length>1) ///POST CODE
    //             {
    //                 var formatted = element
    //                 if(element[0] !== " "){formatted=" "+formatted}
    //                 addressArray.push(formatted+"")
    //             }
    //             else{
    //                 var formatted = element.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
    //                 if(index!==0 && formatted[0] !== " "){formatted=" "+formatted}
    //                 addressArray.push(formatted)
    //             }

    //             index++
    //         });
    //         // element.registeredAddress.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})

    //         return addressArray.join(",")

    //     }else{
    //         return ""
    //     }

    // }

    const getAddressStr2 = (company) => {
        let addressArray = []
        if (company.postTown !== "" && company.postTown !== null) { addressArray.push(pascalCase(company.postTown)) }
        if (company.county !== "" && company.county !== null) { addressArray.push(pascalCase(company.county)) }
        if (company.country !== "" && company.country !== null) { addressArray.push(pascalCase(company.country)) }

        return addressArray.join(", ")
    }

    const getFinancialStr = (number, estimated, hideTilde) => {

        var str = ""




        if (number !== null) {
            if ((number + "").includes(' - ')) {
                let split = (number + "").split(' - ')
                str += getFinancialStr(split[0], false, true) + " - " + getFinancialStr(split[1], false, true)
            }
            else
                if (number >= 1000000 || number <= -1000000) {
                    str += "£"
                    var millions = (number / 1000000).toFixed(2)
                    if (number % 1000000 === 0) { millions = number / 1000000 }
                    else if (number % 100000 === 0 || estimated) { millions = (number / 1000000).toFixed(1) }
                    str += millions + "M"
                }
                else if (number >= 1000 || number <= -1000) {
                    str += "£"
                    var thousands = (number / 1000)
                    if (thousands < 10 && number % 1000 !== 0) { thousands = thousands.toFixed(1) }
                    else { thousands = Math.floor(thousands) }
                    str += thousands + "K"
                }
                else {
                    str += "£"
                    str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }

            if (estimated && !hideTilde) {
                str = "~" + str
            }

            return str
        }
        else {
            return str
        }
    }


    const getSICTitle = (code, sicJSON) => {

        var foundSIC = false

        for (const element of sicJSON) {
            if (element.sicCode === code) {
                foundSIC = true
                return { found: true, title: element.title }
            }
            else {
                if (element.subValues.length > 0) {
                    let res = getSICTitle(code, element.subValues)
                    if (res.found) {
                        foundSIC = true
                        return { found: true, title: res.title }
                    }
                }
            }

        };

        if (!foundSIC) {
            return { found: false }
        }

    }

    const getSICMap = (code, sicJSON, sicMapping) => {

        var foundSIC = false

        for (const element of sicJSON) {
            if (element.sicCode === code) {
                foundSIC = true

                let res = searchSICMap(element.title, sicMapping)

                return { found: true, nameFound: res.found, title: res.name }
            }
            else {
                if (element.subValues.length > 0) {
                    let res = getSICMap(code, element.subValues, sicMapping)
                    if (res.found) {
                        foundSIC = true
                        if (res.nameFound) {
                            return { found: true, nameFound: res.nameFound, title: res.title }
                        }
                        else {
                            let res = searchSICMap(element.title, sicMapping)
                            return { found: true, nameFound: res.found, title: res.name }
                        }

                    }
                }
            }

        };

        if (!foundSIC) {
            return { found: false }
        }

    }

    const searchSICMap = (title, map) => {
        var found = false
        var name = ""


        for (const element of map) {
            if (!found) {
                if (element.SIC_Name === title) {
                    found = true;
                    name = element.Final
                }
            }
        }
        return { found: found, name: name }
    }



    const getPSCStr = (number) => {
        let people = []
        PSCs.forEach(element => {
            if (element.number === number) {
                people.push(element)
            }
        });

        if (people.length === 0) { return "" }
        else if (people.length === 1) {
            return people[0].firstName + " " + people[0].surname[0]
        }
        else {
            return people[0].firstName + " " + people[0].surname[0] + " + " + (people.length - 1) + " more"
        }

    }


    const getContactDetails = (number) => {

        let peopleCount = 0
        let emailCount = 0
        let validEmailCount = 0

        PSCs.forEach(element => {
            if (element.number === number) {
                peopleCount++
                if (element.email !== "" && element.email !== undefined && element.email !== "undefined" && element.email !== "null") { 
                    emailCount++ 
                    if(element.emailStatus !== "medium"){validEmailCount++}
                }
            }
        });

        return (
            <div className='row width100 jc-end'>
                <div className='contactDataInnerRow'>
                    {/* <img className='cdIcon' src={emailCount===peopleCount?'/assets/mail.png':'/assets/mail-disable.png'}/> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M1.33398 5.22461L6.77726 9.0349C7.21804 9.34345 7.43844 9.49772 7.67816 9.55748C7.88991 9.61026 8.11139 9.61026 8.32314 9.55748C8.56287 9.49772 8.78326 9.34345 9.22404 9.03491L14.6673 5.22461M4.53398 13.8913H11.4673C12.5874 13.8913 13.1475 13.8913 13.5753 13.6733C13.9516 13.4815 14.2576 13.1756 14.4493 12.7993C14.6673 12.3714 14.6673 11.8114 14.6673 10.6913V6.42461C14.6673 5.3045 14.6673 4.74445 14.4493 4.31663C14.2576 3.9403 13.9516 3.63434 13.5753 3.4426C13.1475 3.22461 12.5874 3.22461 11.4673 3.22461H4.53398C3.41388 3.22461 2.85383 3.22461 2.426 3.4426C2.04968 3.63434 1.74372 3.9403 1.55197 4.31663C1.33398 4.74445 1.33398 5.3045 1.33398 6.42461V10.6913C1.33398 11.8114 1.33398 12.3714 1.55197 12.7993C1.74372 13.1756 2.04968 13.4815 2.426 13.6733C2.85383 13.8913 3.41388 13.8913 4.53398 13.8913Z" stroke={emailCount === peopleCount ? "#475467" : "#98A2B3"} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> */}
                    {/* <span className={`${emailCount === peopleCount ? 'gray-600' : 'gray-400'}`}>{emailCount}</span> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M1.33398 5.22461L6.77726 9.0349C7.21804 9.34345 7.43844 9.49772 7.67816 9.55748C7.88991 9.61026 8.11139 9.61026 8.32314 9.55748C8.56287 9.49772 8.78326 9.34345 9.22404 9.03491L14.6673 5.22461M4.53398 13.8913H11.4673C12.5874 13.8913 13.1475 13.8913 13.5753 13.6733C13.9516 13.4815 14.2576 13.1756 14.4493 12.7993C14.6673 12.3714 14.6673 11.8114 14.6673 10.6913V6.42461C14.6673 5.3045 14.6673 4.74445 14.4493 4.31663C14.2576 3.9403 13.9516 3.63434 13.5753 3.4426C13.1475 3.22461 12.5874 3.22461 11.4673 3.22461H4.53398C3.41388 3.22461 2.85383 3.22461 2.426 3.4426C2.04968 3.63434 1.74372 3.9403 1.55197 4.31663C1.33398 4.74445 1.33398 5.3045 1.33398 6.42461V10.6913C1.33398 11.8114 1.33398 12.3714 1.55197 12.7993C1.74372 13.1756 2.04968 13.4815 2.426 13.6733C2.85383 13.8913 3.41388 13.8913 4.53398 13.8913Z" stroke={emailCount > 0 ? (validEmailCount>0?"#067647":"#ffbf00") : "#98A2B3"} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className={`${emailCount === peopleCount ? (validEmailCount > 0 ? 'success-700' : 'amber') : 'gray-400'}`}>{emailCount}</span>


                </div>
                {/* <div className='contactDataInnerRow'>
                                <img className='cdIcon' src='/assets/linkedin_grey.png'/>
                                <span>1</span>
                            </div> */}
            </div>
        )

    }

    const getDummyName = (element) => {


        let res = getSICMap(element.sic, SIC_CODES, SIC_MAPPING)
        // console.log(element.name,element.sic,res)
        return res.title + " Company"
    }




    return (
        <tr className={`tableRow ${element.premium ? "premium" : ""} text-sm regular gray-600`} onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(false)}>



            <td>
                <input id={`checkbox_id${element.number}`} className='checkbox billing' type="checkbox" checked={checked.includes(element.number)} onChange={(e) => onCheck(element, e.target.checked)} disabled={(element.premium && user.exports.length===0 && user.plan === "free")}/>
                <label htmlFor={`checkbox_id${element.number}`} className='checkboxLabel' />
            </td>
            <td className='row ai-centre gap12'>
                {element.premium && <div className='premiumCircle'>
                    <img className="premiumIcon" src='/assets/premiumIcon.png' alt='premiumIcon'/>
                </div>}

                <div className={`${freeMode ? "blur" : ""} noselect maxWidth300 ${showTutorial ? "rowSelected" : ""}`}>
                    <span className='text-sm medium gray-900'>{element.premium ? getDummyName(element) : element.name}</span>
                    {!element.premium && <div>{getAddressStr2(element)}</div>}
                </div>
                {!element.premium && <div className='row ai-centre width100px pointer' onClick={() => setShowPopUp(true)}>
                    {hover && <span className='text-sm medium primary-700'>See more</span>}
                    {hover && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4.66602 11.3327L11.3327 4.66602M11.3327 4.66602H4.66602M11.3327 4.66602V11.3327" stroke={primary700} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>}
                </div>}
                {showTutorial && <TutorialBubble step={"resultRow"} close={closeTutorial} next={nextTutorial} />}

            </td>
            <td>
                {element.premium && <div className="premiumBar"/>}
                {(element.sic && !element.premium) && <span>
                    <span className='twoLine'> <strong>({element.sic})</strong> {getSICTitle(element.sic, SIC_CODES).title}</span>
                </span>}
                {(!element.sic && !element.premium) && <span>No SIC code</span>}
            </td>
            <td className='alignRight'>
                {getFinancialStr(element.turnover, element.turnoverStatus === "Estimated")}
            </td>
            <td className='alignRight'>{element.netProfit ? getFinancialStr(element.netProfit, element.netProfitEstimate === "Estimated") : "-"}</td>
            <td className='alignRight'>{element.netProfitPercentage ? formatPercentage(element.netProfitPercentage) + '%' : '-'}</td>

            <td className='alignRight'>{element.debtRatio ? `${(element.debtRatio / 100).toFixed(2)}` : ''}</td>
            <td className='shareholders'>
                <div className='width100 row jc-start gap8'>
                    {/* <img className='cdIcon' src='/assets/pscsGrey.png'/> */}
                    <div className='cdIcon'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 17" fill="none">
                        <path d="M14.6673 14.5586V13.2253C14.6673 11.9827 13.8175 10.9386 12.6673 10.6426M10.334 2.75243C11.3113 3.14802 12.0007 4.10613 12.0007 5.22526C12.0007 6.34439 11.3113 7.3025 10.334 7.69809M11.334 14.5586C11.334 13.3161 11.334 12.6948 11.131 12.2048C10.8603 11.5514 10.3412 11.0322 9.68781 10.7616C9.19775 10.5586 8.5765 10.5586 7.33398 10.5586H5.33398C4.09147 10.5586 3.47022 10.5586 2.98016 10.7616C2.32675 11.0322 1.80762 11.5514 1.53697 12.2048C1.33398 12.6948 1.33398 13.3161 1.33398 14.5586M9.00065 5.22526C9.00065 6.69802 7.80674 7.89193 6.33398 7.89193C4.86123 7.89193 3.66732 6.69802 3.66732 5.22526C3.66732 3.7525 4.86123 2.55859 6.33398 2.55859C7.80674 2.55859 9.00065 3.7525 9.00065 5.22526Z" stroke="#475467" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </div>
                    {element.premium && <div className="premiumBar"/>}
                    {!element.premium && <span className={`${freeMode ? "blur" : ""} noselect`}>{getPSCStr(element.number)}</span>}
                </div>
            </td>
            <td>
                {getContactDetails(element.number)}
            </td>
                    {/* <div className = 'relative'>
                        
                        <div className='row ai-centre width100px pointer' onClick={()=>setShowPopUp(true)}>
                        {hover && <span className='text-sm medium primary-700'>See more</span>}
                        {hover && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M4.66602 11.3327L11.3327 4.66602M11.3327 4.66602H4.66602M11.3327 4.66602V11.3327" stroke={primary700} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>}
                        </div>
                        {showPopUp && <SearchResultBubble company={element} PSCs={PSCs} freeMode={freeMode} plan={plan}/>}

                        {showTutorial && <TutorialBubble step={"resultRow"} close={closeTutorial} next={nextTutorial}/>}
                    </div> */}
                    

            {showPopUp && <SearchResultBubble company={element} PSCs={PSCs} freeMode={freeMode} plan={plan} />}
        
                
                

            </tr>
            
    )

}