import '../../../../styling/filters.css'
import React, { useEffect, useState, useRef, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';
import * as constants from '../../../../constants';

export default function LocationFilter({filter,addedFilters,apply,hideApply,clear}) {


// #region CONSTANTS & STATE VARIABLES

  const [text, setText] = useState("")
  const [chosenOptions, setChosenOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState("all")
  const [coords, setCoords] = useState([])

  const [radius, setRadius] = useState("5")
  const [locationOption, setLocationOption] = useState(1)


  const [focused, setFocused] = useState(false)
  const [updateVar, setUpdateVar] = useState(0)

  const gridRef = useRef()
  const isBiz = useContext(ParentContext);

  var options = constants.RADIUS_OPTIONS

// #endregion
useEffect(() => {

    setChosenOptions([])
    setCoords([])

      addedFilters.forEach(element => {
          if(element.id === filter.id)
          {
              console.log("HE",element)
              // setText(element.value.text)
              setChosenOptions(element.value.locations)
              setCoords(element.value.coords)
              setLocationOption(element.value.option)
              setRadius(element.value.radius)

              
              if(element.value.addressChoice!==undefined)
              {
                setSelectedValue(element.value.addressChoice)
              }
          }
      });


     
  },[addedFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {

    gridRef.current?.scrollIntoView({ behavior: 'smooth' });

     
  },[chosenOptions.length]);


// #region SHOW COMPONENTS
const showSelected = () => {

  let compArray = []

  chosenOptions.forEach(element => {
      compArray.push(
          <div className='appliedFilterValue spaced' key={element}>
          <span className='text-xs semibold gray-700 alignLeft oneLine'>{element}</span>
          <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(element)} alt='close'/>
      </div>
      )
  });

  return compArray

}


const addOptions = () => {


  let compArray = []
  options.forEach(element => {
      compArray.push(<option value={element.value} title={element.title} key={element.title}>{element.title} </option>)
  });
  return compArray
}

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED

const remove = (place) => {

  var index = 0
  chosenOptions.forEach(element => {
      if(element === place)
      {
          chosenOptions.splice(index, 1);
      }
      index++
  });
  setChosenOptions(chosenOptions)
  setUpdateVar(updateVar+1)

  if(chosenOptions.length===0){clear()}
  else{
      apply(filter,{locations:chosenOptions,addressChoice:selectedValue,coords:coords,option:locationOption,radius:radius})
  }

}




  const applyFilter = () => {
    
    apply(filter,{locations:chosenOptions,addressChoice:selectedValue,coords:coords,option:locationOption,radius:radius})
  }
// #endregion


// #region OTHER
   
// #endregion


useEffect( () => {

    const input = document.getElementById("discoverInput");

    const options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        // types: ['(regions)'],
        types: locationOption===1?['geocode']:['postal_code'],
        componentRestrictions: {country: "gb"}
      };


      if(window.google !== undefined)
      {

       try{window.google.maps.event.clearInstanceListeners(input);}
       catch(e){}

        const autocomplete = new window.google.maps.places.Autocomplete(input, options);
        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            console.log("Place",place)

            var placeName = place.name
            if(place.formatted_address.includes("Blacon, Chester")){placeName="Cheshire"}

            setText("")


            //Address Search
            if(locationOption === 1)
            {
              if(!chosenOptions.includes(placeName)){chosenOptions.push(placeName)}
            }
            else{
              chosenOptions.length = 0
              coords.length = 0
              chosenOptions.push(placeName)

              let lat = place.geometry.location.lat()
              let lng = place.geometry.location.lng()
              coords.push({lat:lat,lng:lng})
            }

            setChosenOptions(chosenOptions)
            setCoords(coords)

            

            if(hideApply){
              console.log("LOC OP",locationOption)
              console.log("RAD",radius)
              console.log("CHSO OPs",chosenOptions)
              apply(filter,{locations:chosenOptions,addressChoice:selectedValue,coords:coords,option:locationOption,radius:radius})
            }

        })
      }

}, [window.google,chosenOptions,locationOption,coords,radius]); // eslint-disable-line react-hooks/exhaustive-deps


const addressOptionSelected = (option) => {
  setSelectedValue(option)
  if(hideApply && chosenOptions.length!==0){
    apply(filter,{locations:chosenOptions,addressChoice:option,coords:coords,option:locationOption,radius:radius})
  }
}

const radiusSelected = (radius) => {
  setRadius(radius)
  if(hideApply && coords.length!==0){
    apply(filter,{locations:chosenOptions,addressChoice:selectedValue,coords:coords,option:locationOption,radius:radius})
  }
}

const locationOptionSelected = (option) => {
  setLocationOption(option)
  setChosenOptions([])
  setCoords([])
  // if(hideApply && chosenOptions.length!==0){
  //   apply(filter,{locations:chosenOptions,addressChoice:selectedValue,coords:coords,option:option,radius:radius})
  // }
}

const isDisabled = () => {
  if(chosenOptions.length === 0)
  {
      return true
  }
  else{
      return false
  }
}

    
    return(
      <div className="textFilter padding3">

        <div className='row gap20 ai-centre'>
            <div className='radioRow'>
                <input className='radio input-sm' type='radio' checked={locationOption===1} onChange={() => locationOptionSelected(1)}/>
                <span className='text-sm medium gray-700'>{"Location"}</span>
            </div>
            <div className='radioRow'>
                <input className='radio input-sm' type='radio' checked={locationOption===2} onChange={() => locationOptionSelected(2)}/>
                <span className='text-sm medium gray-700'>{"Radius"}</span>
            </div>
        </div>
        
        <div className="textFilter">

          <div className='row gap8 width100'>
          <div className={`textInput ${focused?"focus":""} search text-md regular gray-900`} >

            <div className='searchMultipleGrid'>
                
                {showSelected()}

                { !(locationOption===2 && coords.length>0) && 
                <input  ref={gridRef} id='discoverInput' className='textInput inner' type="text" placeholder={locationOption===1?"Enter a location":"Enter a postcode"} value={text}
                onFocus={()=>setFocused(true)} 
                onBlur={()=>setFocused(false)} 
                onChange={(e) => setText(e.target.value)}
                />}

            </div>

          </div>

          {locationOption===2 && <select className='textInput date text-md regular gray-900 pointer' value={radius} onChange={(e) => radiusSelected(e.target.value)}>
                <option value="" disabled hidden>Radius</option>
                {addOptions('max')}
            </select>}

            </div>


          {/* <div className="radioFilter location">
            <div className='radioRow'>
                <input className='radio input-sm' type='radio' checked={selectedValue==="all"} onChange={(e) => addressOptionSelected('all')}/>
                <span className='text-sm medium gray-700'>{"Show all"}</span>
            </div>
            <div className='radioRow'>
                <input className='radio input-sm' type='radio' checked={selectedValue==="registered"} onChange={(e) => addressOptionSelected('registered')}/>
                <span className='text-sm medium gray-700'>{"Registered address only"}</span>
            </div>
            <div className='radioRow'>
                <input className='radio input-sm' type='radio' checked={selectedValue==="trading"} onChange={(e) => addressOptionSelected('trading')}/>
                <span className='text-sm medium gray-700'>{"Trading address only"}</span>
            </div>
          </div> */}

          <div className='column ai-start gap4 width100'>
            <span className='text-sm medium'>Address type</span>
            <select className='textInput text-md regular gray-900 pointer' value={selectedValue} onChange={(e) => addressOptionSelected(e.target.value)}>
              <option value="all" title="">{"All (Trading & Registered addresses)"}</option>
              <option value="registered" title="">{"Only Registered addresses"}</option>
              <option value="trading" title="">{"Only Trading addresses"}</option>
            </select>
          </div>

          

          {!hideApply && <button className='secondaryButton applyFilterBtn' onClick={applyFilter}>
                <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
                <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
            </button>}

        </div>


        </div>
    )

}