import '../../../styling/search.css'
import '../../../styling/constants/typography.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import SmartAcquireSearch from './Search Bubbles/SmartAcquireSearch';
import RevenueSearch from './Search Bubbles/RevenueSearch';
import IndustrySearch from './Search Bubbles/IndustrySearch';
import LocationSearch from './Search Bubbles/LocationSearch';
import AdvancedSearch from '../Advanced/AdvancedSearch';
import { createExcel } from '../../../localFunctions/exportSheet';
import { auth } from '../../..';
import { SIC_CODES } from '../Advanced/FilterTypes/siccodes';
import SaveFiltersPopUp from '../../popUps/saveFilterPopUp';
import SaveExportsPopUp from '../../popUps/SaveExportPopUp';
import { MoreUpgradeBubble } from './Search Bubbles/UpgradePlanBubbles';
import { pascalCase } from '../Advanced/FilterTypes/SICFilter';
import TutorialBubble from './Search Bubbles/TutorialBubble';

export default function SearchBar({setSearching,setResults,exportClicked,clearClicked,closeClicked,saveFilterClicked,exportSuccessful,freeMode,plan,searched,saveToast,selectedCompanies,hideExported, setHideExported,setShowExportPopUpP}) {

    const baseURL2 = process.env.REACT_APP_BASEURL2;

    const [anySelected, setAnySelected] = useState(false)
    const [locationSelected, setLocationSelected] = useState(false)
    const [industrySelected, setIndustrySelected] = useState(false)
    const [revenueSelected, setRevenueSelected] = useState(false)
    const [smartAcquireSelected, setSmartAcquireSelected] = useState(false)
    const [moreSelected, setMoreSelected] = useState(false)


    const [addressChoice, setAddressChoice] = useState("all")


    const [appliedFilters, setAppliedFilter] = useState([])
    // const [hideExported, setHideExported] = useState(true)

    const [entryID, setEntryID] = useState(null)
    const [countData, setCountData] = useState(null)
    const [entryCount, setEntryCount] = useState(null)
    
    // const [loading, setLoading] = useState(false)

    const [tableList, setTableList] = useState([]);
    const [companyJSONList, setCompanyJSONList] = useState([]);
    const [pscJSONList, setPSCJSONList] = useState([]);
    const [contactJSONList, setContactJSONList] = useState([]);
    const [financialJSONList, setFinancialJSONList] = useState([]);

    const [updateVar, setUpdateVar] = useState(0)


    const [showSavePopUp, setShowSavePopUp] = useState(false)
    const [showExportPopUp, setShowExportPopUp] = useState(false)

    const [trialOver, setTrialOver] = useState(false)

    const [tutorialStep, setTutorialStep] = useState("")
    const primary700 = getComputedStyle(document.body).getPropertyValue('--primary-700')
    // const [freeMode,setFreeMode] = useState(true)

    useEffect(() => {
        hideExportedChanged(hideExported);
    }, [hideExported]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        
        if(exportClicked>0)
        {
            
            

            // exportFunction()
            setShowExportPopUp(true)
        }

    }, [exportClicked]);

    useEffect( () => {
        
        setShowExportPopUpP(showExportPopUp)

    }, [showExportPopUp]); // eslint-disable-line react-hooks/exhaustive-deps

    // const exportFunction = async () => {
    //     let finList = await getExtraFinancials()
    //     createExcel(companyJSONList,contactJSONList,pscJSONList,finList)
    // }

    useEffect( () => {


        if(clearClicked>0)
        {

            

            setAppliedFilter([])
        }

    }, [clearClicked]);

    useEffect( () => {

        if(closeClicked>0)
        {
            closeAll()
        }

    }, [closeClicked]); // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect( () => {

        if(saveFilterClicked>0)
        {
            // saveFilters()
            

            setShowSavePopUp(true)
        }

    }, [saveFilterClicked]);


    useEffect( () => {


        let newUser = localStorage.getItem('newUser')
        if(newUser !== "null" && newUser !== null)
        // if(url.includes('/search/newUser'))
        {
            // console.log("NEW USER",newUser)
            setTutorialStep("location")
            localStorage.setItem("newUser",null)

            let url = window.location.href
            gtag_report_conversion(url)


        }

        let savedFilterLS = localStorage.getItem('savedFilter')
        if(savedFilterLS !== "null" && savedFilterLS !== null && appliedFilters.length===0)
        {
            setAppliedFilter(JSON.parse(savedFilterLS).filters)
            getEstimatedCount(JSON.parse(savedFilterLS).filters,hideExported)
            localStorage.setItem("savedFilter",null)
        }

        

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {

        let user = JSON.parse(localStorage.getItem("user"))


        if(auth.currentUser && user!==null)
        {
            let creationTime = new Date(auth.currentUser.metadata.creationTime).getTime()

            if(creationTime<1718613013000){creationTime=1718613013000}

            let currentTime = new Date().getTime()

            if( ((currentTime-creationTime)>(7*86400000)) && user.plan === "free")
            {
                setTrialOver(true)
            }else{
                setTrialOver(false)
            }
        }

    }, [auth.currentUser,JSON.parse(localStorage.getItem("user"))]); // eslint-disable-line react-hooks/exhaustive-deps

// #region CONSTANTS & STATE VARIABLES

// #endregion

const gtag_report_conversion = (url) => {
    var callback = function () {
    //   if (typeof(url) != 'undefined') {
    //     window.location = url;
    //   }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-11464568239/TYLsCJT_xoUZEK_b3doq',
        'event_callback': callback
    });
    return false;
  }


const hideExportedClicked = () => {
    setHideExported(!hideExported)
    hideExportedChanged(!hideExported)
}

// #region SHOW COMPONENTS

const getLocationSubtext = () => {
    let subtext = 'Enter location'
    appliedFilters.forEach(element => {
        if(element.id === "2H")
        {
            if(element.value.option===2)
            {
                subtext = element.value.locations[0] + " - " + element.value.radius + (+element.value.radius===1?" mile":" miles")
            }
            else{
                let array = []
                element.value.locations.forEach(value => {
                    array.push(value)
                });
                subtext = array.join(", ")
            }
            
        }
    });
    return subtext
}

const getIndustrySubtext = () => {
    let subtext = 'Enter industry'
    appliedFilters.forEach(element => {
        if(element.id === "2C")
        {
            
            let array = []
            element.value.forEach(value => {
                array.push(pascalCase(value.title))
            });
            subtext = array.join(", ")
        }
    });
    return subtext
}

const getRevenueSubtext = () => {
    let subtext = 'Enter revenue'
    appliedFilters.forEach(element => {
        if(element.id === "3C3")
        {
            subtext = "£"+element.value.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            + " - £" + element.value.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    });
    return subtext
}

const getSAFSubtext = () => {
    let subtext = 'Select acquire filter'
    if(!plan.includes("plus")){subtext = 'Upgrade to view'}
    appliedFilters.forEach(element => {
        if(element.id === "1A")
        {
            subtext = element.value.join(", ")
        }
    });
    return subtext
}




// #endregion


// #region WEB REQUESTS




// const deleteFilter = () => {

//     return new Promise(async (resolve, reject) => {


//         axios.post(baseURL2+'deleteSavedFiltersFromAccount',{

//             uid:auth.currentUser.uid,
//             id:'d256aa99854c8'

//         }).then( (response) => {
        
//             const data = response.data;
//             console.log("SDATA",data)

//         });
//     })
// }

// #endregion


// #region BUTTONS CLICKED

const locationApply = (filter,value) => {
    applyFilter(filter,value)
    // setLocationSelected(false)
    // setIndustrySelected(true)
}


const locationDone = () => {
    setLocationSelected(false)
    setIndustrySelected(true)
}

const industryApply = (filter,value) => {
    applyFilter(filter,value)
    
}


const industryDone = () => {
    setIndustrySelected(false)
    setRevenueSelected(true)
}

const revenueApply = (filter,value) => {
    applyFilter(filter,value)   
}
const revenueDone = () => {
    setRevenueSelected(false)
    setSmartAcquireSelected(true)
}



const safClickThrough = () => {
    setSmartAcquireSelected(false)
    setMoreSelected(true)
}

const moreClickThrough = () => {
    setMoreSelected(false)
}

const hideExportedChanged = (val) => {
    setHideExported(val)
    getEstimatedCount(appliedFilters,val)
}

const applySavedFilters = (filters) => {

    console.log("THIS FILTERS")

    setAppliedFilter(filters)
    getEstimatedCount(filters,hideExported)
    setUpdateVar(updateVar+1)
}

const applyFilter = (filter,value) => {

    console.log("VAL",filter.id,value)
    if(value.length===0){removeAppliedFilter(filter,value)}
    else{
        let afArrayOG = appliedFilters

        let afArray = afArrayOG

        filter.value = value
        
        var exists = false
        afArray.forEach(element => {
            if(element.id === filter.id)
            {
                console.log("ADD",element.id,filter.id)
                element.value = value
                exists = true
            }
        });

        if(!exists){afArray.push(filter)}
        setAppliedFilter(afArray)

        localStorage.setItem("enteredAppliedFilters",JSON.stringify(afArray))

        
        console.log("Applied 2",afArray)
        getEstimatedCount(afArray,hideExported)
        setUpdateVar(updateVar+1)
    }

  }

  
  
const removeAppliedFilter = (filter,value) => {

    console.log("Remove",filter,value)

    var index = 0
    appliedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            if(element.subType === "location" && element.value.locations.length>1  && value!==undefined)
            {
                element.value.locations.splice(element.value.locations.indexOf(value), 1);

            }
            if(element.subType === "companyName" && element.value.names.length>1  && value!==undefined)
            {
                element.value.names.splice(element.value.names.indexOf(value), 1);

            }
            else if(element.type === "multiple" && element.value.length>1 && value!==undefined)
            {
                element.value.splice(element.value.indexOf(value), 1);
            }
            else{
                appliedFilters.splice(index, 1);
            }
            
        }
        index++
    });

    localStorage.setItem("enteredAppliedFilters",JSON.stringify(appliedFilters))

    setAppliedFilter(appliedFilters)
    getEstimatedCount(appliedFilters,hideExported)
    setUpdateVar(updateVar+1)

  }
// #endregion


// #region OTHER
   
// #endregion

const bfClicked = (filter,e) => {


    if(tutorialStep==="" && auth.currentUser !== null && !trialOver)
    {

        var open 

        if(filter==="location"){open = locationSelected}
        if(filter==="industry"){open = industrySelected}
        if(filter==="revenue"){open = revenueSelected}
        if(filter==="smartacquire"){open = smartAcquireSelected}
        if(filter==="more"){open = moreSelected}


        setAnySelected(!open)
        setLocationSelected(false)
        setIndustrySelected(false)
        setRevenueSelected(false)
        setSmartAcquireSelected(false)
        setMoreSelected(false)

        
        if(filter==="location"){setLocationSelected(!open)}
        if(filter==="industry"){setIndustrySelected(!open)}
        if(filter==="revenue"){setRevenueSelected(!open)}
        if(filter==="smartacquire"){setSmartAcquireSelected(!open)}
        if(filter==="more"){setMoreSelected(!open)}
    }
}

const closeAll = () => {

    console.log("CLOSE ALL",moreSelected)

    setAnySelected(false)
    setLocationSelected(false)
    setIndustrySelected(false)
    setRevenueSelected(false)
    setSmartAcquireSelected(false)
    setMoreSelected(false)

}




const search = () => {
    console.log("Search")
    // getScribeData()
    getCompaniesData()
}






const getCompaniesData = () => {

    // console.log(lowIncorporation,highIncorporation)

    let financialFilters = getFinancialFilters(appliedFilters)
    let bcFinanceFilters = getBCFinanceFilters(appliedFilters)
    let bizCrunchFilters = getBizCrunchFilters(appliedFilters)

    var exportedList = []
    if(hideExported)
    {
        let user = JSON.parse(localStorage.getItem("user"))
        if(user && user.exports)
        {
            user.companiesExported.forEach(element => {
                if(!exportedList.includes(element.CompanyNumber))
                {
                    exportedList.push(element.CompanyNumber)
                }
            });
            
        }
    }

    setSearching()
    closeAll()

    axios.post(baseURL2+'searchForBusinessesBC',{
        "uid":auth.currentUser.uid,
        "bcFinanceFilters":bcFinanceFilters,
        "filters":bizCrunchFilters,
        "financialFilters":financialFilters,
        "hideExport":hideExported,
        "exported": exportedList,
        "plan":"plus"//plan
        
    }).then( (response) => {
    
        const data = response.data;
        console.log("DATA",data)

        // setJsonList(data.resVar.combined)
        convertResponse2(data.companies,data.pCount)
    });

}




const getEstimatedCount = async (array,hideExport) => {

    let id = Math.random().toString(16).slice(2)
    setEntryID(id)

    let financialFilters = getFinancialFilters(array)
    // let scribeFilters = getScribeFilters(array)
    let bcFinanceFilters = getBCFinanceFilters(array)
    let bizCrunchFilters = getBizCrunchFilters(array)

    var exportedList = []
    if(hideExport)
    {
        let user = JSON.parse(localStorage.getItem("user"))
        if(user && user.exports)
        {
            user.companiesExported.forEach(element => {
                if(!exportedList.includes(element.CompanyNumber))
                {
                    exportedList.push(element.CompanyNumber)
                }
            });
        }
    }
   
    if(array.length>0)
    {

        setEntryCount(null)
        // setLoading(true)

        
        let response = await axios.post(baseURL2+'getEntryEstimateBC',{
            "id": id,
            "offset":0,
            "limit":1,
            "amount":10,
            "bcFinanceFilters":bcFinanceFilters,
            "filters":bizCrunchFilters,
            "financialFilters":financialFilters,
            "hideExport":hideExport,
            "exported": exportedList,
            "plan":"plus"//plan
            // "scribeFilters":scribeFilters,
            
        })
        // .then( (response) => {


            const data = response.data;
            data.id = id
            setCountData(data)
            
        // });
    }

}

//MAKING SURE TO JUST SET ESTIMATE COUNT FOR MOST RECENT SEARCH
useEffect(() => {

    if(countData!==null && entryID!==null && entryID===countData.id)
    {
        // setLoading(false)
        setEntryCount(countData.results.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    }

}, [entryID,countData]);

    


    const getBCTags = () => {

        let tags = []

        appliedFilters.forEach(filter => {
    
            if(filter.id==="1A"){
                tags = filter.value.join(',')    
            }
        })

        return tags

    }

    


    const convertResponse2 = (list,pCount) => {

        var tableList = []
        var companyList = []
        var PSCTableList = []

        var PSCList = []
        var contactList = []
        var financialsList = []

        var compNum = ""
        var coregList = []

        var index = 0
        
        var bcTags = getBCTags()

        list.forEach(item => {

            if(index<5000)
            {
                if(item.CompanyNumber!==null){

                    if(!coregList.includes(item.CompanyNumber))
                    {                
                        

                        compNum=item.CompanyNumber
                        coregList.push(compNum)
                        // let financials = item.financialmodel

                        if(item.ShareholderType === "none"){item.ShareholderType = "N/A"}

                        // Estimated Revenue
                        if(!item.Turnover)
                        {
                            var tradeDebtors = item.TradeDebtors
                            if(!item.tradeDebtors)
                            {
                                tradeDebtors = item.CurrentAssets*.75
                                item.TradeDebtors = tradeDebtors
                            }

                            let lowRange = Math.floor(tradeDebtors*6/100000)*100000
                            let highRange = Math.floor(tradeDebtors*10/100000)*100000

                            item.Turnover = lowRange+" - "+highRange
                            item.Estimated = true
                        }

                        // if(!item.NetProfit)
                        // {
                        //     item.NetProfit = item.NetAssets
                        // }

                        if(!item.NetProfitPercentage && item.NetProfit)
                        {
                            item.NetProfitPercentage = ((item.NetProfit/item.Turnover)*100).toFixed(2)
                            if((item.Turnover+"").includes(" - "))
                            {
                                let split = item.Turnover.split(" - ")
                                let middle = (+split[0] + +split[1])/2
                                item.NetProfitPercentage = ((item.NetProfit/middle)*100).toFixed(2)
                            }
                        }

                        

                        if(item.NetProfitPercentage>80)
                        {
                            item.NetProfitPercentage = null
                            item.NetProfit = null
                            item.NetProfitEstimate = false
                        }

                        if(item.Email === "undefined" || item.Email === "null")
                        {
                            
                            item.Email = ""
                        }


                        tableList.push({
                            name:item.CompanyName.replace("LIMITED","LTD"),
                            number:item.CompanyNumber||item.CompNum2,
                            sic:item.SIC,
                            sicName:getSICTitle(item.SIC,SIC_CODES).title,
                            registeredAddress:item.RegisteredAddress,
                            tradingAddress:item.TradingAddress,
                            postTown:item.RegAdd_PostTown,
                            county:item.RegAdd_County,
                            country:item.RegAdd_Country,
                            website:item.Website,
                            incorporation:item.IncorporationDate,                    
                            filingDate:item.FilingDate || "",
                            year:item.FinancialYear  || "",
                            type:item.CompanyType,
                            status:item.CompanyStatus,
                            amountPSCs:item.AmountPSCs,
                            debtRatio:item.DebtRatio,
                            netProfitPercentage:item.NetProfitPercentage,
                            fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                            turnover:item.Turnover,
                            turnoverStatus:item.Estimated?"Estimated":"Reported",
                            netProfit:item.NetProfit,
                            netProfitEstimate:item.NetProfitEstimate?"Estimated":"Reported",
                            currentAssets:item.CurrentAssets,
                            fixedAssets:item.FixedAssets,
                            totalAssets:item.TotalAssets,
                            currentLiabilities:item.CurrentLiabilities,
                            longTermLiabilities:item.LongTermLiabilities,
                            netAssets:item.NetAssets,
                            updated:item.CompanyUpdated,
                            emailCount:0,
                            validEmailCount:0,
                            numEmployees:item.NumEmployees,
                            premium:item.Premium === 1
                        })

                        
                            companyList.push({
                                name:item.CompanyName,
                                number:item.CompanyNumber||item.CompNum2,
                                sic:item.SIC,
                                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                                registeredAddress:item.RegisteredAddress,
                                tradingAddress:item.TradingAddress,
                                website:item.Website,
                                incorporation:formatDate(item.IncorporationDate),                    
                                type:item.CompanyType,
                                status:item.CompanyStatus,
                                amountPSCs:item.AmountPSCs,
                                debtRatio:(item.DebtRatio/100).toFixed(2),
                                netProfitPercentage:item.NetProfitPercentage,
                                fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                                turnover:item.Turnover?getFinancialStr(item.Turnover):null,
                                turnoverStatus:item.Estimated?"Estimated":"Reported",
                                netProfit:item.NetProfit?getFinancialStr(item.NetProfit):item.NetAssets?getFinancialStr(item.NetAssets):null,
                                netProfitEstimate:item.NetProfitEstimate?"Estimated":"Reported",
                                currentAssets:item.CurrentAssets?getFinancialStr(item.CurrentAssets):null,
                                fixedAssets:item.FixedAssets?getFinancialStr(item.FixedAssets):null,
                                totalAssets:item.TotalAssets?getFinancialStr(item.TotalAssets):null,
                                currentLiabilities:item.CurrentLiabilities?getFinancialStr(item.CurrentLiabilities):null,
                                longTermLiabilities:item.LongTermLiabilities?getFinancialStr(item.LongTermLiabilities):null,
                                netAssets:item.NetAssets?getFinancialStr(item.NetAssets):null,
                                bcTags:bcTags,
                                emailCount:0,
                                validEmailCount:0,
                                numEmployees:item.NumEmployees,
                                premium:item.Premium === 1
                            })
                        
                        



                        // if(financials!==undefined && index<10)
                       
                            // var indexF = 0
                            // financials.forEach(element => {

                                // let cff = item.cff[indexF]

                                financialsList.push({
                                    compNumber:item.CompanyNumber,
                                    compName:item.CompanyName,
                                    sic:item.SIC,
                                    sicName:getSICTitle(item.SIC,SIC_CODES).title,
                                    
                                    registeredAddress:item.RegisteredAddress,
                                    tradingAddress:item.TradingAddress,
                                    website:item.website,
                                    incorporation:item.IncorporationDate,                    
                                    type:item.CompanyType,
                                    status:item.CompanyStatus,
                                    filingDate:item.FilingDate,
                                    year:item.FinancialYear,
                                    debtRatio:item.DebtRatio,
                                    netProfitPercentage:item.NetProfitPercentage,
                                    fanpRatio:item.FTARevRatio,// || item.calculatedFinancials.fatr2,
                                    turnover:item.Turnover,
                                    turnoverStatus:item.Estimated?"Estimated":"Reported",
                                    netProfit:item.NetProfit?item.NetProfit:item.NetAssets?item.NetAssets:null,
                                    netProfitEstimate:item.NetProfitEstimate?"Estimated":"Reported",
                                    currentAssets:item.CurrentAssets,
                                    fixedAssets:item.FixedAssets,
                                    totalAssets:item.TotalAssets,
                                    currentLiabilities:item.CurrentLiabilities,
                                    longTermLiabilities:item.LongTermLiabilities,
                                    netAssets:item.NetAssets

                                })
                                // indexF++
                            // });
                        

                        index++

                    }
                    
                    if(item.Forename!==null)
                    {
                        

                        
                        if(item.Address_Line1 === "")
                        {
                            let split = item.Address.split(',')
                            if(split.length>1){item.Address_Line1 = split[0]}
                        }

                        var emailStatus = item.EmailStatus || "valid"
                        if(emailStatus === "invalid"||emailStatus === "abuse"){item.Email = ""}
                        let emailV = item.Email||""
                        if(emailV === ""){
                             emailStatus = ""
                        }else{
                            if(emailStatus !== "valid"){
                                emailStatus = "medium"
                            }else{
                                emailStatus = "high"
                            }
                        }

                        if(item.Email !== "" && item.Email !== undefined && item.Email !== "undefined" && item.Email !== "null"){

                            let index = tableList.findIndex(x => x.number ===item.CompanyNumber);
                            tableList[index].emailCount = tableList[index].emailCount+1
                            companyList[index].emailCount = companyList[index].emailCount+1

                            if(emailStatus!=="medium")
                            {
                                tableList[index].validEmailCount = tableList[index].validEmailCount+1
                                companyList[index].validEmailCount = companyList[index].validEmailCount+1
                            }
                        }

                        
                        PSCTableList.push({
                            name:item.CompanyName,
                            number:item.CompanyNumber,
                            sic:item.SIC,
                            sicName:getSICTitle(item.SIC,SIC_CODES).title,
                            title:item.Title,
                            firstName:item.Forename,
                            surname:item.Surname,
                            addressLine1:item.Address_Line1,
                            addressLine2:item.Address_Line2,
                            town:item.Address_PostTown,
                            county:item.Address_County,
                            postCode:item.PostCode,
                            country:item.Country,
                            shareholderType:item.ShareholderType,
                            shareholderControl:item.ShareholderPercentage,
                            age:2023-item.YearDOB,
                            email:item.Email,
                            emailStatus: emailStatus,
                            linkedIn:"",//item.LinkedIn,
                            monthDOB:item.MonthDOB,
                            yearDOB:item.YearDOB,
                        })

                        // if(index<11)
                        // {
                            contactList.push({
                                name:item.CompanyName,
                                number:item.CompanyNumber,
                                sic:item.SIC,
                                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                                title:item.Title,
                                firstName:item.Forename,
                                surname:item.Surname,
                                email:item.Email||"",
                                emailStatus: emailStatus,
                                linkedIn:"",//item.LinkedIn||"",
                                shareholderType:item.ShareholderType,
                                shareholderControl:item.ShareholderPercentage,
                                age:2023-item.YearDOB
                            })
        
                            PSCList.push({
                                name:item.CompanyName,
                                number:item.CompanyNumber,
                                sic:item.SIC,
                                sicName:getSICTitle(item.SIC,SIC_CODES).title,
                                title:item.Title,
                                firstName:item.Forename,
                                surname:item.Surname,
                                addressType:"Registered",//item.PostCode===item.RegAdd_PostCode?"Registered":"Other",
                                addressLine1:item.RegAdd_Line1,//item.Address_Line1,
                                addressLine2:item.RegAdd_Line2,//item.Address_PostTown?item.Address_Line2:item.Address_PostTown,
                                town:item.RegAdd_PostTown,//item.Address_PostTown?item.Address_PostTown:item.Address_Line2,
                                county:item.RegAdd_County,//item.Address_County,
                                postCode:item.RegAdd_PostCode,
                                country:getCountryGB(item.RegAdd_Country),//getCountryGB(item.Country),
                                // email:plan.includes("plus")||plan.includes("Contact")?item.Email||"":"",
                                shareholderType:item.ShareholderType,
                                shareholderControl:item.ShareholderPercentage,
                                age:2023-item.YearDOB,
                                email:item.Email,
                                emailStatus: emailStatus,
                            }) 
                        // }

                        


                        

                        
                    }

                    

                }

            }

            

        });


        tableList = tableList.sort((a, b) => b.emailCount-a.emailCount);
        companyList = companyList.sort((a, b) => b.emailCount-a.emailCount);

        // tableList = tableList.sort((a, b) => b.premium-a.premium);
        // companyList = companyList.sort((a, b) => b.premium-a.premium);

        console.log("LISTS",tableList,PSCList,financialsList)
        setCompanyJSONList(companyList)
        setTableList(tableList)
        setPSCJSONList(PSCList)
        setContactJSONList(contactList)
        setFinancialJSONList(financialsList)

        let tagList = getTagList()

        setResults(tableList,PSCTableList,tagList,pCount)
    }


    const createExcelExport = () => {

        createExcel(companyJSONList,contactJSONList,pscJSONList,financialJSONList)
    }



function getCountryGB(country)
{
    if(country === "" || country === null || country === undefined
    || country === "England" || country === "Scotland" || country === "Wales" 
    || country === "Northern Ireland" || country === "United Kingdom")
    {
        return "GB"
    }
    else{
        return country
    }
}

    
function getTagList()
{

    let list = []
    appliedFilters.forEach(element => {
        if(element.id === "1A"){list = element.value}
    })
    return list
}

const searchBarClicked = (e) =>{
    e.stopPropagation();
}


const showReloadButton = () => {
    let previousFilters = JSON.parse(localStorage.getItem("enteredAppliedFilters")) || []
    if(companyJSONList.length===0 &&  appliedFilters.length===0 && previousFilters.length>0){return true}
    else{return false }
}

const reloadFilters = () => {
    let previousFilters = JSON.parse(localStorage.getItem("enteredAppliedFilters"))
    setAppliedFilter(previousFilters)
    getEstimatedCount(previousFilters,hideExported)
}

    

    return(
        <div id='searchBar' className={`searchbar ${searched?"floating":""} ${showExportPopUp?"showExport":""}  ${anySelected?"selected":""} relative` } onClick={searchBarClicked}>

            {tutorialStep!=="" && <div className='tutorialTint'/>}

            <div className='searchbarBasicFilters'>

            <div className="tooltip height100">
                <div className={`searchbarBF ${locationSelected||tutorialStep==="location"?"selected":""}`}>
                    <div className='searchbarBFInner' onClick={(e)=>bfClicked("location",e)}>
                        <span className='text-xs semibold gray-900'>Location</span>
                        <span className='sbfSubText text-sm regular gray-500'>{getLocationSubtext()}</span>
                    </div>
                    {tutorialStep === "location" && <TutorialBubble step={tutorialStep} close={()=>setTutorialStep("")} next={()=>setTutorialStep("industry")}/>}
                    {locationSelected && <LocationSearch appliedFilters={appliedFilters} applyFilter={locationApply} addressChoice={addressChoice} setAddressChoice={(val)=>setAddressChoice(val)} remove={removeAppliedFilter} done={locationDone}/>}
                </div>
            {(auth.currentUser===null) && <span className="tooltiptext button text-sm regular">You must login to use the search</span>}
            {trialOver && <span className="tooltiptext button text-sm regular">Your trial is over, you must upgrade to continue using the search</span>}

            </div>

                <div className={`searchbarLine ${(locationSelected||industrySelected)?"hide":""}`}/>

                <div className="tooltip height100">
                <div className={`searchbarBF ${industrySelected||tutorialStep==="industry"?"selected":""}`}>
                    <div className='searchbarBFInner' onClick={(e)=>bfClicked("industry",e)}>
                        <span className='text-xs semibold gray-900'>Industry</span>
                        <span className='sbfSubText text-sm regular gray-500'>{getIndustrySubtext()}</span>
                    </div>
                    {tutorialStep === "industry" && <TutorialBubble step={tutorialStep} close={()=>setTutorialStep("")} next={()=>setTutorialStep("revenue")}/>}
                    {industrySelected &&<IndustrySearch appliedFilters={appliedFilters} applyFilter={industryApply} remove={removeAppliedFilter} done={industryDone}/>}
                </div>
                    {(auth.currentUser===null) && <span className="tooltiptext button text-sm regular">You must login to use the search</span>}
                    {trialOver && <span className="tooltiptext button text-sm regular">Your trial is over, you must upgrade to continue using the search</span>}
                </div>

                <div className={`searchbarLine ${(revenueSelected||industrySelected)?"hide":""}`}/>

                <div className="tooltip height100">
                <div className={`searchbarBF ${revenueSelected||tutorialStep==="revenue"?"selected":""}`} >
                    
                    <div className='searchbarBFInner' onClick={()=>bfClicked("revenue")}>
                        <span className='text-xs semibold gray-900'>Revenue</span>
                        <span className='sbfSubText text-sm regular gray-500'>{getRevenueSubtext()}</span>
                    </div>
                    {tutorialStep === "revenue" && <TutorialBubble step={tutorialStep} close={()=>setTutorialStep("")} next={()=>setTutorialStep("saf")}/>}
                    {revenueSelected && <RevenueSearch appliedFilters={appliedFilters} applyFilter={revenueApply} remove={removeAppliedFilter} done={revenueDone}/>}
                </div>
                    {(auth.currentUser===null) && <span className="tooltiptext button text-sm regular">You must login to use the search</span>}
                    {trialOver && <span className="tooltiptext button text-sm regular">Your trial is over, you must upgrade to continue using the search</span>}
                </div>
                    

                <div className={`searchbarLine ${(revenueSelected||smartAcquireSelected)?"hide":""}`}/>

                <div className="tooltip height100">
                <div className={`searchbarBF ${smartAcquireSelected||tutorialStep==="saf"?"selected":""}`} >
                    <div className='searchbarBFInner' onClick={()=>bfClicked("smartacquire")}>
                        <div className='searchbarSAFrow'>
                            {/* <img className='searchbarSAFicon' src={!plan.includes("plus")?'/assets/lock.png':'/assets/safIcon.png'} alt='smartAcquireFilters'/> */}
                            <img className='searchbarSAFicon' src={'/assets/safIcon.png'} alt='smartAcquireFilters'/>
                            <span className='text-xs semibold gray-900'>Smart Acquire Filter</span>
                        </div>
                        <span className='sbfSubText text-sm regular gray-500'>{getSAFSubtext()}</span>
                    </div>
                    
                    {tutorialStep === "saf" && <TutorialBubble step={tutorialStep} close={()=>setTutorialStep("")} next={()=>setTutorialStep("more")}/>}
                    {/* {(smartAcquireSelected && !plan.includes("plus")) && <SAFUpgradeBubble />} */}
                    {/* {(smartAcquireSelected && plan.includes("plus")) && <SmartAcquireSearch appliedFilters={appliedFilters} applyFilter={applyFilter} removeFilter={removeAppliedFilter} done={safClickThrough}/>} */}
                    {(smartAcquireSelected) && <SmartAcquireSearch appliedFilters={appliedFilters} applyFilter={applyFilter} removeFilter={removeAppliedFilter} done={safClickThrough}/>}
                </div>
                    {(auth.currentUser===null) && <span className="tooltiptext button text-sm regular">You must login to use the search</span>}
                    {trialOver && <span className="tooltiptext button text-sm regular">Your trial is over, you must upgrade to continue using the search</span>}
                </div>
                
            </div>

            <div className='searchbarRight'>
                <div className={`searchbarAdvanceFilters ${moreSelected||tutorialStep==="more"?"selected":""}`} >
                    <div className='searchbarAdvanceFiltersInner' onClick={()=>bfClicked("more")}>
                        <img className='searchbarFilterImg' src={freeMode?'/assets/lock.png':'/assets/filters.png'} alt='advancedFilters'/>
                        <span className='text-xs semibold gray-900'>Advanced</span>
                    </div>
                    {tutorialStep === "more" && <TutorialBubble step={tutorialStep} close={()=>setTutorialStep("")} next={()=>setTutorialStep("")}/>}
                    {(moreSelected && !freeMode)  && 
                    <AdvancedSearch 
                        appliedFilters={appliedFilters}
                        createExcel={createExcelExport}
                        search={moreClickThrough}
                        companyJSONList={companyJSONList}
                        removeAppliedFilter={removeAppliedFilter}
                        setAppliedFilter={applySavedFilters}
                        applyFilter = {applyFilter}
                        addressChoice={addressChoice} 
                        setAddressChoice={(val)=>setAddressChoice(val)}
                        removeAll={()=>setAppliedFilter([])}
                        hideExportedP={hideExported}
                        hideExportedChanged={hideExportedChanged}
                        plan={plan}
                    />}
                    {(moreSelected && freeMode)  && <MoreUpgradeBubble />}
                </div>

                <div className='searchBarRightRow'>
                {appliedFilters.length>1 &&
                <button disabled={appliedFilters.length===0} className={`clearAllBtn ${anySelected?"selected":""} text-xs semibold gray-900'`} onClick={()=>setAppliedFilter([])}>
                    Clear all
                </button>}

                <div className="tooltip">
                    <button className={`primaryButton search`} onClick={search} disabled={appliedFilters.length===0 || auth.currentUser===null || entryCount==="0" || trialOver}>
                        
                        <div className='searchBarBtnTopRow'>
                            <img className={`searchIcon ${appliedFilters.length>0?'small':''}`} src='/assets/magnify.png' alt='magnify'/>
                            {<span className='text-md bold'>Search</span>}
                        </div>

                        {(appliedFilters.length>0) && <div>
                            {entryCount!==null && <span className='text-xs regular'>{entryCount!=="0"?entryCount + " entries":"No matches found"}</span>}
                            {entryCount===null && <div className="dot-flashing"></div>}
                        </div>}


                    </button>
                    {(appliedFilters.length===0) && <span className="tooltiptext button text-sm regular">You must add filters to use the search</span>}
                    {(auth.currentUser===null) && <span className="tooltiptext button text-sm regular">You must login to use the search</span>}
                    {(entryCount==="0") && <span className="tooltiptext button text-sm regular">No businesses match your filters</span>}
                    {trialOver && <span className="tooltiptext button text-sm regular">Your trial is over, you must upgrade to continue using the search</span>}
                </div>



                </div>
            </div>


            {showSavePopUp && <SaveFiltersPopUp filters={appliedFilters} close={()=>setShowSavePopUp(false)} saveToast={saveToast}/>}
            {showExportPopUp && <SaveExportsPopUp 
            filters={appliedFilters} 
            close={()=>setShowExportPopUp(false)} 
            companies={tableList} 
            exportData={{companyJSONList:companyJSONList,contactJSONList:contactJSONList,pscJSONList:pscJSONList}} 
            exportSuccessful={exportSuccessful}
            selectedCompanies={selectedCompanies}
            />}

            {showReloadButton() &&  <span className='reloadButton semibold pointer row items-center gap6' onClick={reloadFilters}>
                <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 14C2 14 2.12132 14.8492 5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364C19.6092 17.1187 20.4133 15.5993 20.7762 14M2 14V20M2 14H8M22 10C22 10 21.8787 9.15076 18.364 5.63604C14.8492 2.12132 9.15076 2.12132 5.63604 5.63604C4.39076 6.88131 3.58669 8.40072 3.22383 10M22 10V4M22 10H16" stroke={primary700} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Reload last search
                </span>}
            {!searched && 
            <div className="row ai-centre showPrevious gap-2">
                <label className="switch">
                    <input type="checkbox" checked={hideExported} onChange={hideExportedClicked}/>
                    <span className="sliderSwitch round"></span>
                </label>
                <span className='text-xs medium gray-600'>Don’t show previously exported companies</span>
            </div>
            }
        </div>
        
    )

}


export const getSICTitle = (code,sicJSON) => {

    var foundSIC = false

    for(const element of sicJSON)
    {
        if(element.sicCode === code)
        {
            foundSIC = true
            return {found:true,title:element.title}
        }
        else{
            if(element.subValues.length>0)
            {
                let res = getSICTitle(code,element.subValues)
                if(res.found){
                    foundSIC = true
                    return {found:true,title:res.title}
                }
            }
        }
        
    };

    if(!foundSIC)
    {
        return {found:false}
    }
    
}

export const formatDate = (dateP) => {

    let date = new Date(dateP)

    return date.getDate() + "-" + (date.getMonth()+1)+ "-" + date.getFullYear()
}

export const getFinancialStr = (number) => {

    return number

    // var str = ""
    // str+="£"
    // str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
    // return str
}


export const getFinancialFilters = (array) => {

    var filters = []

    // var appliedFiltersVar = appliedFilters
    // if(array!==undefined){appliedFiltersVar = array}

    
    array.forEach(filter => {
    
            


    if(filter.id==="3C3"){


        filters.push({
            type:"turnover",
            field:"Financials.Turnover",
            low:+filter.value.min,
            high:filter.value.max,
            approx:filter.value.approx
        })    

    }
    if(filter.id==="3A1"){
        filters.push({
            type:"between",
            field:"Financials.CurrentAssets",
            low:filter.value.min,
            high:filter.value.max
        })    
    }

    if(filter.id==="3A5"){
        filters.push({
            type:"between",
            field:"Financials.CurrentLiabilities",
            low:filter.value.min,
            high:filter.value.max
        })    
    }

    if(filter.id==="3A4"){
        filters.push({
            type:"between",
            field:"Financials.TotalAssets",
            low:filter.value.min,
            high:filter.value.max
        })    
    }

    if(filter.id==="3A6"){
        filters.push({
            type:"between",
            field:"Financials.LongTermLiabilities",
            low:filter.value.min,
            high:filter.value.max
        })    
    }

    // if(filter.id==="3A3"){
    //     filters.push({
    //         "tag": "ncattang",
    //         "type": "bst",
    //         "number": {"between": [filter.value.min,filter.value.max]}
    //     })
    // }

    if(filter.id==="3C2"){
        
        filters.push({
            type:"netProfit",
            low:filter.value.min,
            high:filter.value.max,
            estimated:filter.value.estimated
        })    
        
        
    }


    if(filter.id==="3A2"){
        filters.push({
            type:"between",
            field:"Financials.FixedAssets",
            low:filter.value.min,
            high:filter.value.max
        })  
    }

    if(filter.id==="3A7"){
        filters.push({
            type:"between",
            field:"Financials.NetAssets",
            low:filter.value.min,
            high:filter.value.max
        }) 
    }
    
    if(filter.id==="3B1"){
        filters.push({
            type:"between",
            field:"Financials.DebtRatio",
            low:filter.value.min,
            high:filter.value.max
        }) 
    }
    
    if(filter.id==="3B2"){
        filters.push({
            type:"between",
            field:"Financials.FTARevRatio",
            low:filter.value.min,
            high:filter.value.max
        }) 
    }
    
    if(filter.id==="3C1"){
        filters.push({
            type:"netProfitPercentage",
            low:filter.value.min,
            high:filter.value.max,
            estimated:filter.value.estimated
        })    
    }


    })


     

    return filters

}


export const getBCFinanceFilters = (array) => {

    var checkedEstimatedRevenue = false
    var checkedLeverageable = false
    var checkedProfitable = false
    var checkedRetiringOwners = false
    var checkedSimpleOwnership = false
    var netAssets = null
    var fixedAssets = null
    var debtRatio = null
    var ftanpr = null
    var netProfitPercentage = null

    // var appliedFiltersVar = appliedFilters
    // if(array!==undefined){appliedFiltersVar = array}

    array.forEach(filter => {

        if(filter.id==="3A2"){
            fixedAssets = {low:filter.value.min,high:filter.value.max}
        }

        if(filter.id==="3A7"){
            netAssets = {low:filter.value.min,high:filter.value.max}
        }
        
        if(filter.id==="3B1"){
            debtRatio = {low:filter.value.min,high:filter.value.max}
        }
        
        if(filter.id==="3B2"){
            ftanpr = {low:filter.value.min,high:filter.value.max}
        }
        
        if(filter.id==="3C1"){
            netProfitPercentage = {low:filter.value.min,high:filter.value.max}
        }
        

        if(filter.id==="1A"){
            checkedLeverageable = filter.value.includes('Leverageable')
            checkedProfitable = filter.value.includes('Profitable')
            checkedRetiringOwners = filter.value.includes('Retiring Owner')
            checkedSimpleOwnership = filter.value.includes('Simple ownership')
            
        }

    })

    var filters = {
        revenueApprox:checkedEstimatedRevenue,
        netAssets:netAssets,
        fixedAssets:fixedAssets,
        leverageable:checkedLeverageable,
        profitable:checkedProfitable,
        retiringOwners:checkedRetiringOwners,
        simpleOwnership:checkedSimpleOwnership,
        debtRatio:debtRatio,
        ftanpr:ftanpr,
        netProfitPercentage:netProfitPercentage
    }

    return filters
}

export const getBizCrunchFilters = (array) => {
        
    var filters = []
    var amountPSCsChosen = false

    array.forEach(filter => {

        if(filter.id==="2D"){
            filters.push({
                type:"between",
                field:"Companies.IncorporationDate",
                low:filter.value.min?filter.value.min+"-01-01":"1000-01-01",
                high:filter.value.max?filter.value.max+"-01-01":"3000-01-01"
            })
        }

        if(filter.id==="2G"){
            filters.push({
                type:"like",
                field:"Companies.FilingDate",
                value:"%"+filter.value.value
            })
        }

        if(filter.id==="2E")
        {
            filters.push({
                type:"in",
                field:"Companies.CompanyType",
                value:filter.value
            })
        }

        if(filter.id==="2F")
        {
            filters.push({
                type:"in",
                field:"Companies.CompanyStatus",
                value:filter.value
            })
        }

        if(filter.id==="2B")
        {
            filters.push({
                type:"equals",
                field:"Companies.CompanyNumber",
                value:filter.value+''
            })    
        }

        if(filter.id==="2A")
        {
            filters.push({
                type:"companyName",
                field:"Companies.CompanyName",
                value:filter.value
            })    
        }

        if(filter.id==="2H"){ // && validLocation(filter.value)){

            filters.push({
                type:"address",
                value:filter.value
            })

        }

        if(filter.id==="2C"){

            filters.push({
                type:"in",
                field:"Companies.SIC",
                value:getSicArray(filter.value)
            })

            
        }


        if(filter.id==="4A1")
        {
            amountPSCsChosen = true
            filters.push({
                type:"between",
                field:"Companies.AmountPSCs",
                low:filter.value.min,
                high:filter.value.max
            })    
        }


        if(filter.id==="4A2")
        {
            filters.push({
                id:"4A2",
                type:"maxParents",
                // field:"PSCs.ShareholderType",
                value:filter.value
            })    
        }
        

        if(filter.id==="4A3")
        {
            filters.push({
                type:"shareholder",
                field:"PSCs.ShareholderType",
                value:filter.value
            })    
        }

        if(filter.id==="4A4")
        {
            filters.push({
                id:"4A4",
                type:"shareholderAge",
                // field:"PSCs.ShareholderType",
                low:filter.value.min,
                high:filter.value.max
            })    
        }

        if(filter.id==="4B")
        {
            filters.push({
                id:"4AB",
                type:"contact",
                value:filter.value
                
            })    
        }
        
        if(filter.id==="4C1")
        {
            filters.push({
                id:"4C1",
                type:"between",
                field:"NumEmployees",
                low:filter.value.min,
                high:filter.value.max
            })
        }

        if(filter.id==="1A"){
        
            if(filter.value.includes('Established'))
            {
                filters.push({
                    type:"between",
                    field:"Companies.IncorporationDate",
                    low:"1003-01-01",
                    high:"2008-01-01"
                })
            }
        }
        

    })

    if(!amountPSCsChosen)
    {
        filters.push({
            type:"between",
            field:"Companies.AmountPSCs",
            low:1,
            high:10
        })    
        
    }

    

    
    
    
    return filters
}


export const validLocation = (value) => {

    var valid = true
    if(value.locations === undefined || value.locations === null){return false}
    else{
        value.locations.forEach(location => {
            if(location==="United Kingdom" || location==="England" || location==="Scotland" || location==="Wales" || location==="Northern Ireland")
            {
                valid = false
            }    
        });
    }

    
    return valid

}

export function getSicArray(sicJSON)
{
    var sicArray = []
    sicJSON.forEach(element => {
        if(element.subValues.length=== 0)
        {
            sicArray.push(element.sicCode)
        }
        else{
            let subArray = getSicArray(element.subValues)
            sicArray.push(...subArray)
        }
    });

    return sicArray
}