
import '../../../../styling/search.css'
import React, {  } from 'react';

import LocationFilter from '../../Advanced/FilterTypes/LocationFilter';

export default function LocationSearch({appliedFilters,applyFilter,addressChoice,setAddressChoice,remove,done}) {


// #region CONSTANTS & STATE VARIABLES
  
// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion

    const clear = () => {
        setAddressChoice('all')
        remove(filter)
    }



    let filter = {id:"2H",title:"Location",type:'multiple',subType:'location'}

    

    return(
        <div className="searchBubble location">
            
            <div className='searchBubbleTitleRow'>
                <span className='text-sm medium gray-700'>Location</span>
                <span className='text-sm medium primary-700 pointer' onClick={clear}>Clear</span>
            </div>
            <LocationFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} addressChoice={addressChoice} setAddressChoice={setAddressChoice} hideApply={true} clear={clear}/>
            
            <div className='loginForgotRow margin24'><button onClick={done} className='text-sm medium gray-700'>Done</button></div>

        </div>
    )

}