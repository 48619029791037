import '../../../../styling/filters.css'
import React, { useEffect, useState, useContext } from 'react';
import { ParentContext } from '../../../constants/ParentContext';

export default function RangeFilter({filter,addedFilters,apply}) {


// #region CONSTANTS & STATE VARIABLES
  

  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [checkedEstimated, setCheckedEstimated] = useState(true)
  const isBiz = useContext(ParentContext);
// #endregion

useEffect(() => {

    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setMin(element.value.min)
            setMax(element.value.max)

            if(element.value.estimated){
              setCheckedEstimated(element.value.estimated)
          }
        }

        
    });  
    

    
},[]) // eslint-disable-line react-hooks/exhaustive-deps



// #region SHOW COMPONENTS

    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  const applyFilter = () => {
    let value = {min:min,max:max,estimated:filter.hasEstimate?checkedEstimated:false}
    apply(filter,value)
   

  }

  const minChanged = (amount) => {

    if(amount>=0 || amount==='')
    {
        if(filter.max && amount>filter.max){amount = filter.max}
        setMin(amount)
        
        // if(+max<+amount){
        //     console.log(amount,max)
        //     setMax(amount)
        // }
    }
  }


  const maxChanged = (amount) => {

    if(amount>=0 || amount==='')
    {
        if(filter.max && amount>filter.max){amount = filter.max}
        setMax(amount)
        
        // if(+min>+amount){
            
        //     setMin(amount)
        // }
    }
  }

  
  

  const isDisabled = () => {
    if((min===null || min==="") && (max===null || max === ""))
    {
        return true
    }
    else if(((min!==null && min!=="") && (max!==null && max !== "")) && +max<+min){return true}
    else{
        return false
    }
  }


// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className="rangeFilter">

            <div className='revenueInputRow'>
                <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${min===""?"regular":"medium"} gray-900`} type="number" placeholder={"Min"} value={min} onChange={(e) => minChanged(e.target.value)} min={0} max={filter.max?filter.max:null}/>
                <span className='text-sm regular gray-500'>to</span>
                <input className={`textInput ${filter.subType==="financial"?"currency":""} text-md ${max===""?"regular":"medium"} gray-900`} type="number" placeholder={"Max"} value={max} onChange={(e) => maxChanged(e.target.value)} min={min?min:0} max={filter.max?filter.max:null}/>
            </div>

            {filter.hasEstimate &&<div className='revenueInputRow'>
                <input id='checkbox_id'  className='input-md checkbox' type="checkbox" checked={checkedEstimated} onChange={()=>setCheckedEstimated(!checkedEstimated)}/>
                <label for="checkbox_id" className='checkboxLabel'/>
                {/* <img className='revEstIcon' src='/assets/profitable.png'/> */}
                <span className='text-sm medium gray-700'>~ Estimated Bizcrunch</span>
            </div>}
           
            <button className='secondaryButton applyFilterBtn' disabled={isDisabled()} onClick={applyFilter}>
                <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'} alt='plus'/>
                <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
            </button>

            

        </div>
    )

}