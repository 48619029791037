import '../../../../styling/filters.css'
import React, { useEffect, useState } from 'react';

export default function CompanyTypeFilter({filter,addedFilters,apply}) {


// #region CONSTANTS & STATE VARIABLES

const [chosenOptions, setChosenOptions] = useState([])




const privateOptions = ['Private (All)',"Private Unlimited with Share Capital","Private Unlimited without Share Capital"]
const publicOptions = ['Public (All)',"Public Limited with Share Capital","Old Public Limited"]

// #endregion

useEffect(() => {

    console.log("Added Filters", addedFilters)
    setChosenOptions([])

    addedFilters.forEach(element => {
        if(element.id === filter.id)
        {
            setChosenOptions(element.value)
        }
    });
    

},[addedFilters.length]) // eslint-disable-line react-hooks/exhaustive-deps





// #region SHOW COMPONENTS
const showOptions = (options) => {

    let compArray = []

    options.forEach(element => {
        
        compArray.push(<div className={`collapsibleFilter`}>

            <div className='cfTitle' onClick={()=>addOption(element)}>
                <span className='text-sm medium gray-700'>{element}</span>
                <img className='cfArrow' src={'/assets/plus.png'} alt='plus'/>
            </div>

        

        </div>)

    });

    return compArray

}
    

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
const addOption = (option) => {

    var cOptions = chosenOptions

    console.log(cOptions,option)

    if(!cOptions.includes(option) && !(option.includes("Private") && cOptions.includes("Private (All)")) && !(option.includes("Public") && cOptions.includes("Public (All)")))
    {
        if(option === "Private (All)"){cOptions=cOptions.filter(x => !x.includes("Private"))}
        if(option === "Public (All)"){cOptions=cOptions.filter(x => !x.includes("Public"))}
        cOptions.push(option)
        setChosenOptions(cOptions)
        apply(filter,cOptions)
    }
    
  }
// #endregion


// #region OTHER
   
// #endregion
    
    return(
        <div className="multipleFilter">

        <span className='text-sm semibold gray-700 compTypeTitle'>PRIVATE</span>
        {showOptions(privateOptions)}
        <span className='text-sm semibold gray-700 compTypeTitle'>PUBLIC</span>
        {showOptions(publicOptions)}


        </div>
    )

}