import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentComponent from '../PaymentComponent';

export default function UpdatePaymentPopUp({close,setCard,cancel}) {

    const live = window.location.hostname.includes('app.bizcrunch.co')

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const [intentID, setIntentID] = useState("")
    const [options, setOptions] = useState(null)
    const [submitCount, setSubmitCount] = useState(0)

    const [taxLoading, setTaxLoading] = useState(false);
    const [taxError, setTaxError] = useState(false);
    const [taxSuccess, setTaxSuccess] = useState(false);
    const [taxIDInput, setTaxIDInput] = useState("");
    // const [taxIDRegion, setTaxIDRegion] = useState("eu_vat");
    const taxIDRegion = "eu_vat"

    const baseURL = process.env.REACT_APP_BASEURL;
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    const stripeTest = loadStripe(process.env.REACT_APP_STRIPE_TEST);
    const stripeLive = loadStripe(process.env.REACT_APP_STRIPE_LIVE);


        
    const appearance = {
        // labels: 'floating',
        variables:{

        }
        
    };
        
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"))
        let cusID = user.stripeCustomerID
        createSetupIntent(cusID)
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    

    const createSetupIntent = (cusID) => {


        axios.get(baseURL+'createSetUpIntent',{
            params:{
                customer: cusID,
                live:live
            }
        }).then( (response) => {
        
            const data = response.data;
            
            let setUpID = data.setupIntent.client_secret
            console.log("Setup ID",setUpID)

            setOptions({clientSecret:setUpID,appearance:appearance})
            
            setIntentID("")
            setIntentID(setUpID)
        
        });

    }

    const stripeResponse = (response) => {

        console.log("STRIPRE RESPONSE",response)

        let success = response.success
        if(success)
        {
            let paymentMethodID = response.response.setupIntent.payment_method
            setPaymentMethod(paymentMethodID)
        }
        else{
            console.log("Error",response.error)
            setError(response.error)
        }
        
        
    }


    const setPaymentMethod = (paymentMethodID) => {

        let user = JSON.parse(localStorage.getItem("user"))
        let cusID = user.stripeCustomerID

        setLoading(true)

        axios.get(baseURL2+'savePaymentMethodAsStripeDefault',{
            params:{
                customer: cusID,
                paymentMethodID:paymentMethodID,
                live:live
            }
        }).then( (response) => {
        
            setLoading(false)
            const data = response.data;
            console.log("Response data",data)
            setCard(data)
            
        });

    }

    const cancelClicked = () => {
        cancel()
    }
    
    
  
    const searchForTaxID = () => {
        
        let user = JSON.parse(localStorage.getItem("user"))
        let cusID = user.stripeCustomerID

        if(taxIDInput!=="" && taxIDRegion !=="")
        {
            setTaxLoading(true)
            setTaxError(false)
            setTaxSuccess(false)

            axios.get(baseURL2+'saveTaxIDToStripeCustomer',{
            params:{
                customer: cusID,
                taxID:taxIDInput,
                region:taxIDRegion
            }
            }).then( (response) => {
                setTaxLoading(false)
                const data = response.data;
                

                if(data.error && data.message.raw.code === "resource_already_exists")
                {
                    data.error = false
                }

                setTaxError(data.error)
                setTaxSuccess(!data.error)

            });
        }else{
            setTaxSuccess(false)
        }
    }
    


    return(

        <div className='tint'>
            <div className='popUp updatePayment'>
                <div className='sfpuTopRow'>
                    
                    <svg className='sfpuIcon' xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                        <path d="M25.6666 12.1673H2.33325M2.33325 10.0673L2.33325 18.934C2.33325 20.2408 2.33325 20.8942 2.58757 21.3933C2.81127 21.8323 3.16823 22.1893 3.60727 22.413C4.1064 22.6673 4.7598 22.6673 6.06658 22.6673L21.9333 22.6673C23.24 22.6673 23.8934 22.6673 24.3926 22.413C24.8316 22.1893 25.1886 21.8323 25.4123 21.3933C25.6666 20.8942 25.6666 20.2408 25.6666 18.934V10.0673C25.6666 8.76053 25.6666 8.10714 25.4123 7.60801C25.1886 7.16896 24.8316 6.81201 24.3926 6.5883C23.8934 6.33399 23.24 6.33399 21.9333 6.33399L6.06659 6.33398C4.7598 6.33398 4.1064 6.33398 3.60727 6.5883C3.16823 6.81201 2.81127 7.16896 2.58757 7.60801C2.33325 8.10713 2.33325 8.76053 2.33325 10.0673Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='sfpuMain'>
                    <div className='sfpuMain inner'>
                        <span className='text-lg semibold gray-900'>Update payment method</span>
                        <span className='text-sm regular gray-600'>Update your card details.</span>
                    </div>

                    <div className='sfpuMain inner'>
                        <span className='text-sm regular gray-700'>Tax ID</span>
                        {/* <select className='textInput text-md regular gray-900 pointer' value={taxIDRegion} onBlur={searchForTaxID} onChange={(e) => setTaxIDRegion(e.target.value)}>
                            <option value="" selected disabled hidden>Choose region</option>
                            <option value={"gb_vat"}>United Kingdom</option>
                            <option value={"eu_vat"}>European Union</option>
                        </select> */}
                        <div className='row gap12 ai-centre width100'>
                            <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter your tax ID"}
                            onBlur={searchForTaxID} value={taxIDInput} onChange={(e) => setTaxIDInput(e.target.value)}/>
                        </div>
                        
                        {taxError && !taxLoading && <span className='text-xs error-500'>Invalid Tax ID</span>}
                        {taxSuccess && !taxLoading && <span className='text-xs success-700'>Tax ID added</span>}
                        {taxLoading && <span className='text-xs regular-500'>Validating Tax ID</span>}
                    </div>    

                    {(intentID && !loading) && 
                        <Elements stripe={live?stripeLive:stripeTest} options={options} >
                            <PaymentComponent stripeResponse={stripeResponse} submitCount={submitCount} payment={false}/>
                        </Elements>
                    }

                    {(!intentID || loading) &&
                        <lottie-player className="loader" src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="white" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    }
                    
                </div>



                


                <div className='row width100 jc-centre gap20'>
                    <button className='secondaryButton width50' onClick={cancelClicked} disabled={loading}>Cancel</button>
                    <button className='primaryButton width50' onClick={()=>setSubmitCount(submitCount+1)} disabled={loading || (!taxSuccess &&taxIDInput!=="") }>Save</button>
                </div>

                <span className='text-sm regular primary-700 marginTop20'>{error}</span>
                
                
            </div>            
        </div>

    )

}