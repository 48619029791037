import '../../../../styling/filters.css'
import React, { useEffect, useState,useRef } from 'react';

export default function CompanyNameFilter({filter,addedFilters,apply,hideApply,clear}) {


// #region CONSTANTS & STATE VARIABLES

  const [text, setText] = useState("")
  const [chosenOptions, setChosenOptions] = useState([])
  const [containChoice, setContainChoice] = useState("")
  const [focused, setFocused] = useState(false)
  const [updateVar, setUpdateVar] = useState(0)

  const gridRef = useRef()

// #endregion
useEffect(() => {

    setChosenOptions([])

      addedFilters.forEach(element => {
          if(element.id === filter.id)
          {
              console.log("HE",element)
              // setText(element.value.text)
              setChosenOptions(element.value.names)
              
              if(element.value.containChoice!==undefined)
              {
                setContainChoice(element.value.containChoice)
              }
          }
      }); 


     
  },[addedFilters.length]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {

    gridRef.current?.scrollIntoView({ behavior: 'smooth' });

     
  },[chosenOptions.length]);


// #region SHOW COMPONENTS
const showSelected = () => {

  let compArray = []

  chosenOptions.forEach(element => {
      compArray.push(
          <div className='appliedFilterValue spaced' key={element}>
          <span className='text-xs semibold gray-700 alignLeft oneLine'>{element}</span>
          <img className='appliedFilterClose' src='/assets/x-close.png' onClick={()=>remove(element)} alt='close'/>
      </div>
      )
  });

  return compArray

}

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED

const remove = (name) => {

  var index = 0
  chosenOptions.forEach(element => {
      if(element === name)
      {
          chosenOptions.splice(index, 1);
      }
      index++
  });
  setChosenOptions(chosenOptions)
  setUpdateVar(updateVar+1)

  if(chosenOptions.length===0){clear()}
  else{
      apply(filter,{names:chosenOptions,containChoice:containChoice})
  }

}


//   const applyFilter = () => {
//     apply(filter,{names:chosenOptions,containChoice:containChoice})
// }

// #endregion


// #region OTHER
   
// #endregion



// const isDisabled = () => {
//   if(chosenOptions.length === 0)
//   {
//       return true
//   }
//   else{
//       return false
//   }
// }

const addTag = () => {
    let tag = text

    if(!chosenOptions.includes(tag)){chosenOptions.push(tag)}
    setChosenOptions(chosenOptions)

    if(containChoice!==""){
        apply(filter,{names:chosenOptions,containChoice:containChoice})
    }
    setText("")

}

const getTagsStr = (mustContain) => {

    var str  = ""

    var index = 0
    chosenOptions.forEach(element => {
        str+=element
        index++
        if(index<chosenOptions.length)
        {
            str += mustContain?" OR ":" AND "
        }
    });
    return str
}

const updateContain = (choice) => {
    console.log("CHOICE",choice)
    setContainChoice(choice)
    apply(filter,{names:chosenOptions,containChoice:choice})

}

    
    return(
        <div className="textFilter padding3">

          <div className={`textInput ${focused?"focus":""} search text-md regular gray-900`} >

            <div className='searchMultipleGrid'>
                
                {showSelected()}

                <input  ref={gridRef} className='textInput inner' type="text" placeholder={filter.placeholder} value={text}
                onFocus={()=>setFocused(true)} 
                onBlur={()=>setFocused(false)} 
                onChange={(e) => setText(e.target.value)}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                      addTag()
                    }
                  }}
                />

            </div>

          </div>

         {chosenOptions.length>0  && <div className='column width100'>

                <div className={`collapsibleFilter ${containChoice==="contain"?"selected":""} `}>

                    <div className='cfTitle' onClick={()=>updateContain("contain")}>
                        <div className='safLeft'>
                            <span className={`text-sm medium alignLeft ${containChoice==="contain"?"gray-300":"gray-700"}`}>{"Company name must contain "+ getTagsStr(true)}</span>
                        </div>
                        {containChoice!=="contain" && <img className='cfArrow' src={'/assets/plus.png'} alt='plus'/>}

                    </div>

                </div>

                <div className={`collapsibleFilter ${containChoice==="notContain"?"selected":""} `}>

                    <div className='cfTitle gap20' onClick={()=>updateContain("notContain")}>
                        <div className='safLeft'>
                            <span className={`text-sm medium alignLeft ${containChoice==="notContain"?"gray-300":"gray-700"}`}>{"Company name must NOT contain "+ getTagsStr()}</span>
                        </div>
                        {containChoice!=="notContain" && <img className='cfArrow' src={'/assets/plus.png'} alt='plus'/>}

                    </div>

                </div>


            </div>}


          {/* {!hideApply && <button className='secondaryButton applyFilterBtn' onClick={applyFilter}>
                <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'}/>
                <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
            </button>} */}

        </div>
    )

}