import { auth } from '../..';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { convertResponseReExport } from '../SavedFilterRow.js';

export default function ExportUpdatesPopUp({close,savedExport,exportSuccessful,creditsP,updateCredits}) {

    const oldCompanies = savedExport.oldCompanies?savedExport.oldCompanies.length:0
    const updatedCompanies = savedExport.updatedCompanies?savedExport.updatedCompanies.length:0
    const newCompanies = savedExport.newCompanies?savedExport.newCompanies.length:0

    const [creditsCharged, setCreditsCharged] = useState(updatedCompanies+newCompanies)
    const [optionSelected, setOptionSelected] = useState(0)


    const baseURL2 = process.env.REACT_APP_BASEURL2;

        
    useEffect(() => {
        
    },[])
    

    const exportFunction = async () => {

        let exportIDs = []
        let time = new Date().getTime()

        console.log("POTION OD",optionSelected)

        if(optionSelected < 3){exportIDs.push(...savedExport.newCompanies)}
        if(optionSelected !== 2){exportIDs.push(...savedExport.updatedCompanies)}
        if(optionSelected === 0 || optionSelected === 4){exportIDs.push(...savedExport.oldCompanies)}

        axios.post(baseURL2+'exportNewBusinesses',{

            exportID:savedExport.id,
            uid:auth.currentUser.uid,
            companies:exportIDs,
            timestamp:33249653866000 //3023
    
        }).then( (response) => {
        
            convertResponseReExport(response.data.companies,savedExport.name)
            updateCreditsDB(creditsCharged)
            updateCredits(creditsP-creditsCharged)
            addLocally(savedExport.id,exportIDs,time)
            exportSuccessful(optionSelected)

        });


    }

    const addLocally = (id,compNums,time) => {
            let user = JSON.parse(localStorage.getItem("user"))
            user.exports.forEach(exportVar => {
                if(exportVar.id===id){
                    exportVar.lastUpdate = time
                    let companiesEV = exportVar.companies
                    compNums.forEach(company => {
                        if(!companiesEV.includes(company)){companiesEV.push(company)}
                    });
                    exportVar.companies = companiesEV
                }
            });
            // user.exports.push({name:name,filters:filters,id:id,companies:compNums,time:time})

            let companiesExported = user.companiesExported
            companiesExported.forEach(company => {
                if(compNums.includes(company.CompanyNumber))
                {
                    company.ExportTime = new Date()
                    compNums.splice(compNums.indexOf(company.CompanyNumber), 1);
                }
            });

            compNums.forEach(compNum => {
                companiesExported.push({
                    UserID:auth.currentUser.uid,
                    CompanyNumber: compNum,
                    ExportTime:new Date()
                })
            });

            user.companiesExported = companiesExported


            localStorage.setItem("user",JSON.stringify(user))
    }



    const updateCreditsDB = (used) => {
        axios.post(baseURL2+'updateCreditsDB',{
            uid: auth.currentUser.uid,
            used:used
        }).then( (response) => {
        
            const data = response.data;
            console.log("DATA",data)
    
        });
    }


    // const [error, setError] = useState("Please enter name")


    const getExportOptions = () => {
        let compArray = []

        compArray.push(getExportOption(0,'/assets/exportOption1.png',updatedCompanies+newCompanies,"All Companies",`${oldCompanies + updatedCompanies+newCompanies} Companies (${oldCompanies} existing records + ${newCompanies} new records + ${updatedCompanies} updates)`))
        if(updatedCompanies>0){compArray.push(getExportOption(4,'/assets/exportOption2.png',updatedCompanies,"Existing company records + updates",`${updatedCompanies+oldCompanies} Companies (${oldCompanies} existing records + ${updatedCompanies} updates)`))}
        if(newCompanies>0 && updatedCompanies>0){compArray.push(getExportOption(1,'/assets/exportOption2.png',updatedCompanies+newCompanies,"New company records + updates",`${updatedCompanies+newCompanies} Companies (${newCompanies} new records + ${updatedCompanies} updates)`))}
        if(newCompanies>0){compArray.push(getExportOption(2,'/assets/exportOption3.png',newCompanies,"Only new company records",`${newCompanies} Companies (${newCompanies} new records)`))}
        if(updatedCompanies>0){compArray.push(getExportOption(3,'/assets/exportOption4.png',updatedCompanies,"Only company updates",`${updatedCompanies} updates`))}

        return compArray
    }

    const getExportOption = (id,icon,credits,title,subtitle) => {
        return(
            
            <div className={`exportOptionTile ${optionSelected===id?"selected":""}`} onClick={()=>optionClicked(id,credits)}>
                
                <div className='eotIconDiv'>
                    <img className='eotIcon' src={icon} alt='icon'/>
                </div>
                <div className='eotText'>
                    <span className='text-sm medium primary-800'>{title}</span>
                    <span className='text-sm regular primary-700'>{subtitle}</span>
                </div>
                <div className={`eotCheck ${optionSelected===id?"selected":""}`}>
                    {optionSelected===id&&<img className='eotCheckIcon' src='/assets/white_check.png' alt='whiteCheck'/>}
                </div>

            </div>
        )
    }

    const optionClicked = (id,credits) => {
        setOptionSelected(id)
        setCreditsCharged(credits)
    }

    

    return(

        <div className='tint'>
            <div className='popUp exportAgain'>
                <div className='sfpuTopRow'>
                    <img className='sfpuIcon' src='/assets/downArrowCircle.png' alt='downArrow'/>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='sfpuMain'>
                    <div className='sfpuMain inner'>
                        <span className='text-lg semibold gray-900'>{`Export new version of ${savedExport.name}`}</span>
                        <span className='text-sm regular gray-600'>Choose which new data to export.</span>
                    </div>

                    <div className='sfpuMain inner2'>
                        {getExportOptions()}
                    </div>


                    


                </div>

                <div className='sepuButtons'>

                    <div className='sepuButtonsLeft'>
                        <img className='sfrItemIcon' src='/assets/coinsStacked.png' alt='coinsStacked'/>
                        <span className='text-xs regular gray-600'>{`${creditsCharged} credits will be used`}</span>
                    </div>

                    <div className='sfpuButtons'>
                        <button className='secondaryButton' onClick={close}>Cancel</button>
                        <div className="tooltip">
                        <button className='primaryButton' onClick={exportFunction}>Export CSV</button>
                            {/* {error!=="" && <span className="tooltiptext button text-sm regular">{error}</span>} */}
                        </div>
                        
                    </div>
                </div>
                
            </div>            
        </div>

    )

}